<template>
  <div>
    <div class="fieldTitle">
      <div>筛选条件:</div>
      <div class="fontStyle">
        <a-tooltip>
          <template #title>
            <div>筛选条件逻辑：</div>
            <div>1、并且：是需同时满足该条件</div>
            <div>2、或者：是满足一个条件都可以</div>
            <div>
              当同一筛选条件添加了多个时，同条件之间只能是或者的关系；否则将筛选不出联系人，您可手动调整筛选条件。
            </div>
          </template>
          <QuestionCircleOutlined class='mr-3'/>与其它筛选条件逻辑
        </a-tooltip>
      </div>

    </div>
    <div class="fieldValue">
      <!--     这里展示筛选条件 [{}, {}, {}]  contentList -->
      <div v-if="form.formList.length === 0" class="length0">
        未添加筛选条件
      </div>
      <div v-else>
        <a-timeline>
          <div
          v-for="(item, index) in form.formList"
          :key="item.id"
          class="fieldItem"
          @click="handleFieldItem()"
          :class="{ activeFieldItem: item.status }"
        >
          <!--        <a-form  :rules="rules" >-->
          <div class='timeLineLeft'>
            <a-form-item class="title">
              <a-button class="title-but">{{ item.label }}</a-button>
            </a-form-item>

            <!--  操作符  姓名\最后互动时间\添加时间\联系频次\  操作符为 等于     -->
            <a-form-item
              class="relation"
              v-if="
              item.label === '公司' ||
              item.label === '地域' ||
              item.label === '职位'
            "
            >
              <a-select placeholder="请选择" v-model:value="item.relation">
                <a-select-option
                  v-for="ol in operatorList"
                  :key="ol.value"
                  :value="ol.value"
                >
                  {{ ol.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item class="relation" v-else>
              <a-select placeholder="请选择" v-model:value="item.relation">
                <a-select-option
                  v-for="ol in operatorListOther"
                  :key="ol.value"
                  :value="ol.value"
                >
                  {{ ol.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <!--     筛选条件值    -->
            <a-form-item v-if="item.type === 'input'" class="value">
              <a-input
                v-if="
                (item.label === '地域' ||
                  item.label === '职位' ||
                  item.label === '公司') &&
                item.relation === 'isnull'
              "
                v-model:value="item.value"
                placeholder="请输入"
                disabled="disabled"
                :allowClear="true"
              />
              <a-input
                v-else
                v-model:value="item.value"
                placeholder="请输入"
                :allowClear="true"
              />
            </a-form-item>

            <a-form-item v-else>
              <a-select
                placeholder="请选择"
                v-model:value="item.value"
                v-if="item.label === '添加时间'"
              >
                <a-select-option
                  v-for="ol in createTime"
                  :key="ol.value"
                  :value="ol.value"
                >
                  {{ ol.label }}
                </a-select-option>
              </a-select>

              <a-select
                placeholder="请选择"
                v-model:value="item.value"
                v-if="item.label === '最后互动'"
              >
                <a-select-option
                  v-for="ol in lastTinteractionTime"
                  :key="ol.value"
                  :value="ol.value"
                >
                  {{ ol.label }}
                </a-select-option>
              </a-select>

              <a-select
                placeholder="请选择"
                v-model:value="item.value"
                v-if="item.label === '联系频次'"
                style="width: 114px"
              >
                <a-select-option
                  v-for="ol in frequenctList"
                  :key="ol.itemCode"
                  :value="ol.itemCode"
                >
                  {{ ol.itemNameEn }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <div class="delete" @click="handleDelete(index)">
              <img
                src="@/assets/images/box/delete.png"
                alt=""
                class="delete-img"
              />
            </div>
          </div>

          <div class='timeLineRight'>
            <!--      且和或的筛选     -->
<!--        筛选的第一个需要默认给一个 或    -->
            <a-timeline-item
              class="timelineItem"
              :key="item.id"
              v-if='index !== 0'
            >
              <div class='addOror'>
<!--                -->
<!--                <div class='hide'  v-if='index === 0'>-->
<!--                  隐藏白色处理-->
<!--                </div>-->

                <a-form-item>
                  <a-select
                    placeholder="请选择"
                    v-model:value="item.outRelation"
                  >
                    <a-select-option
                      v-for="ol in addOrorList"
                      :key="ol.value"
                      :value="ol.value"
                    >
                      {{ ol.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </a-timeline-item>
          </div>




        </div>
        </a-timeline>

      </div>
    </div>
    <!--          底部     -->
    <div class="borderBut">
      <div class="addSift">
        <!--        <a-dropdown>-->
        <!--          <a-button @click="handleSift" class="addSiftBut">-->
        <!--            <img-->
        <!--              src="@/assets/images/box/addSiftImg.png"-->
        <!--              alt=""-->
        <!--              class="sift-img"-->
        <!--            />-->
        <!--            添加筛选条件-->
        <!--          </a-button>-->
        <!--          <template #overlay>-->
        <!--            <a-menu @click="handleClickByMenu">-->
        <!--              <a-menu-item v-for="i in filterList" :key="i.field">-->
        <!--                &lt;!&ndash;                <component&ndash;&gt;-->
        <!--                &lt;!&ndash;                  class="addonBefore activeColor"&ndash;&gt;-->
        <!--                &lt;!&ndash;                  :is="icons[i.icon]"&ndash;&gt;-->
        <!--                &lt;!&ndash;                />&ndash;&gt;-->
        <!--                &lt;!&ndash;                <span class="zhiwei">&ndash;&gt;-->
        <!--                <img-->
        <!--                  src="@/assets/images/box/regional1.png"-->
        <!--                  alt=""-->
        <!--                  class="sift-img"-->
        <!--                  v-if="i.label === '地域'"-->
        <!--                />-->
        <!--                <img-->
        <!--                  src="@/assets/images/box/gongsi1.png"-->
        <!--                  alt=""-->
        <!--                  class="sift-img"-->
        <!--                  v-else-if="i.label === '公司'"-->
        <!--                />-->
        <!--                <img-->
        <!--                  src="@/assets/images/box/zhiwei1.png"-->
        <!--                  alt=""-->
        <!--                  class="sift-img zhiwei"-->
        <!--                  v-else-if="i.label === '职位'"-->
        <!--                />-->
        <!--                <img-->
        <!--                  src="@/assets/images/box/xingming1.png"-->
        <!--                  alt=""-->
        <!--                  class="sift-img"-->
        <!--                  v-else-if="i.label === '姓名'"-->
        <!--                />-->
        <!--                <img-->
        <!--                  src="@/assets/images/box/hudong1.png"-->
        <!--                  alt=""-->
        <!--                  class="sift-img"-->
        <!--                  v-else-if="i.label === '最后互动'"-->
        <!--                />-->
        <!--                <img-->
        <!--                  src="@/assets/images/box/shijian1.png"-->
        <!--                  alt=""-->
        <!--                  class="sift-img"-->
        <!--                  v-else-if="i.label === '添加时间'"-->
        <!--                />-->
        <!--                <img-->
        <!--                  src="@/assets/images/box/pinlv1.png"-->
        <!--                  alt=""-->
        <!--                  class="sift-img"-->
        <!--                  v-else-if="i.label === '联系频次'"-->
        <!--                />-->
        <!--                &lt;!&ndash;                </span>&ndash;&gt;-->

        <!--                {{ i.label }}-->
        <!--              </a-menu-item>-->
        <!--            </a-menu>-->
        <!--          </template>-->
        <!--        </a-dropdown>-->

        <div class="butSift cursor-p" @click="handleSift">
          <img
            src="@/assets/images/box/addSiftImg.png"
            alt=""
            class="sift-img"
          />
          添加筛选条件
        </div>

        <div v-if="isShowSift" class="ssift">
          <div
            class="newSift cursor-p"
            v-for="(i, index) in filterList"
            :key="i.field"
            :class="{ marB: filterList.length - 1 === index ? true : false }"
            @click="handleSiftItem(i)"
          >
            <div class="itemSift">
              <img
                src="@/assets/images/box/regional1.png"
                alt=""
                class="sift-img"
                v-if="i.label === '地域'"
              />
              <img
                src="@/assets/images/box/gongsi1.png"
                alt=""
                class="sift-img"
                v-else-if="i.label === '公司'"
              />
              <img
                src="@/assets/images/box/zhiwei1.png"
                alt=""
                class="sift-img zhiwei"
                v-else-if="i.label === '职位'"
              />
              <img
                src="@/assets/images/box/xingming1.png"
                alt=""
                class="sift-img"
                v-else-if="i.label === '姓名'"
              />
              <img
                src="@/assets/images/box/hudong1.png"
                alt=""
                class="sift-img"
                v-else-if="i.label === '最后互动'"
              />
              <img
                src="@/assets/images/box/shijian1.png"
                alt=""
                class="sift-img"
                v-else-if="i.label === '添加时间'"
              />
              <img
                src="@/assets/images/box/pinlv1.png"
                alt=""
                class="sift-img"
                v-else-if="i.label === '联系频次'"
              />
              <!--                </span>-->

              {{ i.label }}
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="addPulse" @click="handlePulse">-->
      <!--        <img-->
      <!--          src="@/assets/images/box/addPulseImg.png"-->
      <!--          alt=""-->
      <!--          class="sift-img"-->
      <!--        />-->
      <!--        另存为脉圈-->
      <!--      </div>-->
      <div class="reset" @click="handleReset">重置筛选</div>
    </div>
  </div>
  <iss-pulse
    v-model:visible="visiblePulse"
    :activeItem="activePulseItem"
    @fnOk="handlePulsePage"
  />
</template>

<script>
import { reactive, toRefs, watch, onMounted, onBeforeUnmount } from 'vue';
import issPulse from '@/components/pulse';
import { randoms } from '@/utils';
import * as icons from '@ant-design/icons-vue';
import { Select, Form, Timeline, Tooltip as ATooltip, } from 'ant-design-vue';
import contactAll from '@/api/contactAll';
import {QuestionCircleOutlined} from '@ant-design/icons-vue';

export default {
  components: {
    issPulse,
    // ADropdown: Dropdown,
    // AMenu: Menu,
    // AMenuItem: Menu.Item,
    // AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATimeline: Timeline,
    ATimelineItem: Timeline.Item,
    ATooltip,
    QuestionCircleOutlined,
  },
  props: {
    visible: Boolean,
    init: Object,
  },

  setup(props, context) {
    // const required = { required: true, message: '不能为空' };

    const state = reactive({
      visiblePulse: false,
      isShowSift: false,
      filterList: [
        //   image: require('@/assets/images/box/regional.png')
        //   todo 缺图片
        {
          label: '地域',
          id: '1',
          type: 'input',
          field: 'location',
          relation: 'contain',
          value: '',
          icon: 'EnvironmentOutlined',
          outRelation: 'or',
        },
        {
          label: '公司',
          id: '2',
          type: 'input',
          field: 'company',
          relation: 'contain',
          icon: 'GoldOutlined',
          value: '',
          outRelation: 'or',
        },
        {
          label: '职位',
          id: '3',
          type: 'input',
          field: 'position',
          relation: 'contain',
          icon: 'ExperimentOutlined',
          value: '',
          outRelation: 'or',
        },
        {
          label: '姓名',
          id: '4',
          type: 'input',
          field: 'name',
          relation: 'equals',
          icon: 'IdcardOutlined',
          value: '',
          outRelation: 'or',
        },
        {
          label: '最后互动',
          id: '5',
          type: 'select',
          field: 'last_interaction_time',
          relation: 'equals',
          icon: 'FieldTimeOutlined',
          value: 'week',
          outRelation: 'or',
        },
        {
          label: '添加时间',
          id: '6',
          type: 'select',
          field: 'create_time',
          relation: 'equals',
          icon: 'ScheduleOutlined',
          value: 'week',
          outRelation: 'or',
        },
        {
          label: '联系频次',
          id: '7',
          type: 'select',
          field: 'frequency',
          relation: 'equals',
          icon: 'TeamOutlined',
          value: '10',
          outRelation: 'or',
        },
      ],
      contentList: [],
      operatorList: [
        { label: '包含', value: 'contain' },
        { label: '不含', value: 'without' },
        { label: '等于', value: 'equals' },
        { label: '为空', value: 'isnull' },
      ],
      operatorListOther: [{ label: '等于', value: 'equals' }],

      frequenctList: [
        // { label: '无', value: 'NOT' },
        // { label: '每周', value: 'WEEK' },
        // { label: '每月', value: 'MONTH' },
        // { label: '每季度', value: 'QUARTERLY' },
        // { label: '每半年', value: 'SEMIYEARLY' },
        // { label: '每年', value: 'YEAR' },
        // { label: '不要保持联系', value: 'NEVER' },
      ],

      lastTinteractionTime: [
        { label: '一周内', value: 'week' },
        { label: '一个月内', value: 'month' },
        { label: '三个月内', value: 'threeMonths' },
        { label: '六个月内', value: 'sixMonths' },
        { label: '一年内', value: 'year' },
        { label: '一年之前', value: 'yearBefore' },
      ],

      addOrorList: [
        { label: '且', value: 'and' },
        { label: '或', value: 'or' },
      ],

      createTime: [
        { label: '一周内', value: 'week' },
        { label: '一个月内', value: 'month' },
        { label: '三个月内', value: 'threeMonths' },
        { label: '六个月内', value: 'sixMonths' },
        { label: '一年内', value: 'year' },
        { label: '一年之前', value: 'yearBefore' },
      ],
    });

    const form = reactive({
      operator: '',
      siftValue: '',
      formList: [],
    });

    watch(
      () => form.formList,
      value => {
        if (value.length > 0) {
          // console.log('value watch', value);

          // 筛选条件第一个值默认是 outRelation 是 'or'
          form.formList[0].outRelation = 'or'
        }
        // 监听到数组变化后，传值给父组件 grid, 然后刷新页面
        context.emit('fnSift', value);
      },
      { deep: true }
    );

    // const { validateInfos, validate, resetFields } = Form.useForm(form, {
    //
    // });

    contactAll.getTree('', { code: 'FREQUENCY' }).then(res => {
      Object.assign(state.frequenctList, res);
    });

    const colseSift = () => {
      if (state.isShowSift) {
        state.isShowSift = false;
      }
    };
    // 全局创建一个点击事件:bodyCloseMenus
    onMounted(() => {
      document.addEventListener('click', colseSift);
    });
    // 在页面注销前，将点击事件给移除
    onBeforeUnmount(() => {
      document.removeEventListener('click', colseSift);
    });

    return {
      ...toRefs(state),
      form,
      icons,
      // rules: {
      //   value: [
      //     {
      //       pattern: /^[a-zA-Z]+$/u,
      //       message: '请输入文字',
      //     }
      //   ]
      //
      // },
      // 脉圈保存方法
      handlePulsePage() {
        // 传值给 contactGrid 筛选组件方法 fnSift
        context.emit('fnSift', false);
        state.visiblePulse = false;
      },
      handlePulse: () => {
        state.visiblePulse = true;
      },
      handleSift() {
        state.isShowSift = !state.isShowSift;
      },
      handleSiftItem(i) {
        let keys = i.field;
        state.filterList.map(item => {
          if (item.field === keys) {
            // 判断下是否包含，包含就更改 id值，不包含就直接push
            let newContain = 'equals';
            if (
              keys === 'location' ||
              keys === 'company' ||
              keys === 'position'
            ) {
              newContain = 'contain';
            }

            if (form.formList.includes(item)) {
              let newObj = {
                label: item.label,
                id: '',
                type: item.type,
                field: item.field,
                relation: newContain,
                value: null,
                outRelation: 'or',
              };
              newObj.id = randoms(2);
              form.formList.push(newObj);
            } else {
              let obj = {
                ...item,
                value: null,
              };
              form.formList.push(obj);
            }
          }
        });
        state.isShowSift = false;
      },

      handleClickByMenu({ key }) {
        console.log('key添加筛选条件', key);
        // let contentList = state.filterList.filter((item)=> key === item.value);
        state.filterList.map(item => {
          if (item.field === key) {
            // 判断下是否包含，包含就更改 id值，不包含就直接push
            let newContain = 'equals';
            if (key === 'location' || key === 'company' || key === 'position') {
              newContain = 'contain';
            }

            if (form.formList.includes(item)) {
              let newObj = {
                label: item.label,
                id: '',
                type: item.type,
                field: item.field,
                relation: newContain,
                value: null,
                outRelation: 'or',

              };
              newObj.id = randoms(2);
              form.formList.push(newObj);
            } else {
              let obj = {
                ...item,
                value: null,
              };
              form.formList.push(obj);
            }
          }
        });
      },
      handleDelete(index) {
        // console.log('form.formList', index, form.formList);
        form.formList.splice(index, 1);
        // console.log('删除后的数组', form.formList);
      },
      handleFieldItem() {},
      handleReset() {
        // form.formList数组变化后，传值给父组件 grid, 然后刷新页面
        form.formList = [];
        state.isShowSift = false;
        context.emit('fnSift', false);
      },
    };
  },
};
</script>

<style scoped lang="less">
/* 筛选条件*/

.fieldTitle {
  color: #ff7b00;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.fieldValue {
  position: relative;
  top: 0;
  right: 0;
  //display: flex;
  //flex-direction: row;
  //flex-wrap: wrap;
  background: #ffffff;
  //padding: 4px 16px;
  //max-height: 300px;
  //height: 240px;
  height: 124px;
  overflow: auto;
  .fieldItem {
    background: #fffdfb;
    //border: 1px solid rgba(252, 120, 25, 0.2);
    //padding: 2px 12px;
    text-align: center;
    border-radius: 4px;
    margin: 8px 0px;
    cursor: pointer;
    //background: #fffdfb;
    //color: #ff7b00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 8px;

    .timeLineLeft {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .timeLineRight {
      display: flex;
      justify-content: flex-start;
      align-items: center;

    }

  }

  .activeFieldItem {
    color: #999999;
    background: rgba(153, 153, 153, 0.08);
    border: 1px solid rgba(153, 153, 153, 0.2);
  }
}
.borderBut {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-top: 20px;
  margin-top: 4px;
  //padding: 0px 10px;
  .addSift {
    color: #ff7b00;
    width: 42%;
    cursor: pointer;
    position: relative;
  }
  .addSiftBut {
    border: none;
  }
  .addPulse {
    color: #ff7b00;
    width: 37%;
    cursor: pointer;
  }
  .reset {
    color: #6b7592;
    cursor: pointer;
    margin-right: 28px;
  }
}
.fieldItem /deep/ .ant-form-item {
  margin-bottom: 0px;
}

.delete-img {
  margin: 0px 5px;
}
//.title /deep/ .ant-input {
//  width: 82%;
//}
//.title /deep/ .ant-col {
//  width: 82%;
//}
.title-but {
  color: #1d2129;
}
.length0 {
  color: #6b7592;
  font-size: 12px;
  text-align: center;
  transform: translate(0, 300%);
}
.sift-img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.zhiwei {
  //margin-right: 11px;
}
.zhiwei {
  //width: 10px;
}

.butSift {
  font-size: 14px;
  margin-left: 11px;
}
.ssift {
  width: 148px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  padding: 8px 0px 8px 17px;

  position: absolute;
  top: 24px;
}

.newSift {
  margin-bottom: 16px;
  color: #3d3d3d;

  .sift-img {
    color: #ff7b00;
  }
}
.marB {
  margin-bottom: 0px;
}
.fieldValue /deep/ li.ant-timeline-item.timelineItem {
  padding: 0px !important;
  top: -28px;

}
.fieldValue /deep/ .ant-timeline-item-tail {
  top: 10px;
  height: calc(100% - -4px);
}
.fieldValue /deep/ .ant-timeline-item-content {
  top: 8px;
}

//.addOror /deep/.ant-select:not(.ant-select-customize-input) .ant-select-selector {
//  width: 84px;
//}



</style>
