export default {
  'login.tenant': '企业',
  'login.account.i': '账号',
  'login.account.placeholder': '手机号',
  'login.password': '密码',
  'login.code': '验证码',
  'login.btn': '立即登录',
  'login.forget': '忘记密码',
  'login.copyright':
    'Copyright 2024 深圳易思智科技有限公司 备案号：(粤ICP备13074986号)',
  'login.title': '欢迎使用营销云',
};
