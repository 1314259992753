<template>
  <div>
    <div class="circle-list">
      <a-form-item>
        <a-button @click="openContactAddCircle">新增联系人</a-button>
      </a-form-item>
      <a-form-item label="" name="contactIdList">
        <a-select
          mode="multiple"
          autoClearSearchValue
          v-model:value="contactIdList"
          placeholder="请添加人脉圈成员"
          :filter-option="
            (inputValue, option) => handleFilterOptionT(inputValue, option)
          "
          @change="(value, option) => handleChangeCircle(option, value)"
          allow-clear
        >
          <a-select-option
            v-for="item in selectNameList"
            :key="item"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>
<!--    <contactAdd-->
<!--      :visible="contactAddShow"-->
<!--      :backContact="true"-->
<!--      @fnOk="handleContactAdd"-->
<!--      @update:visible="closeContactAdd"-->
<!--    />-->
    <contactAddCircle
      :init="circleId"
      :visible="contactAddCircleShow"
      :backContact="true"
      @fnOk="handleContactAddCircle"
      @update:visible="closeContactAddCircle"
    />
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  onMounted,
  onActivated, getCurrentInstance
} from 'vue';
import { useStore } from 'vuex';
import {
  Form as AForm,
  Empty as AEmpty,
  Select as ASelect,
  message,
} from 'ant-design-vue';
import { CloseOutlined } from '@ant-design/icons-vue';
import todayApi from '@/api/today';
import timelineApi from '@/api/timeline';
import contactAddCircle from '@/views/contactsCircle/addContactsCircle.vue';
// import contactAdd from '@/views/contacts/contactAall/addPage';

const props = defineProps({
  circleId: String,
  contactId: String,
  selectList: Array,
  selectIdList: Array,
  connectionsIdState: String,
});

const store = useStore();
const { proxy } = getCurrentInstance();
const emits = defineEmits(['changeList']);

const AFormItem = AForm.Item;
const ASelectOption = ASelect.Option;


const contactAddCircleShow = ref(false);

const inputValue = ref();

const dataList = ref([]);
const selectNameList = ref();
const uniqueAry = ref();

const contactIdList = ref([]);
const allMembers = ref([]);

const showxiala = ref(false);
const showEmpty = ref(false);
const selectBox = ref();


const handleChange = value => {
  todayApi
    .queryContactByName('', {
      name: value,
    })
    .then(res => {
      if (res) {
        getData(res);
      }
    });
};

const handleChangeCircle = (option, value) => {
  value.map(item => {
    if (item === 'allMembers') {
      contactIdList.value = allMembers.value;
    }
    if (item === 'allEmpty') {
      contactIdList.value = [];
    }
  });
  uniqueAry.value = contactIdList.value.filter(item => item !== 'allMembers');
  contactIdList.value = [...new Set(uniqueAry.value)];
  rebackData();
};


const selectName = item => {
  if (dataList.value?.length > 0) {
    dataList.value.forEach(i => {
      if (i.id !== item.id) {
        dataList.value.push(item);
      }
    });
    // 数组对象去重的做法
    let unique = {};
    dataList.value.forEach(item => {
      unique[item['id']] = item;
    });
    dataList.value = Object.values(unique);
    rebackData();
  } else {
    dataList.value = [];
    dataList.value.push(item);
    rebackData();
  }

  inputValue.value = '';
  selectNameList.value = [];
  showxiala.value = false;
};

const deleteName = index => {
  dataList.value.splice(index, 1);
  rebackData();
};

const getData = res => {
  let notFoundItems = []; // 存储在list数组中存在但在a数组中不存在的项的新数组
  // 遍历 list 数组
  res.forEach(item => {
    // 使用 JSON.stringify 将对象转换为字符串，然后检查当前项是否在 a 数组中
    if (!dataList.value?.some(aItem => aItem.id === item.id)) {
      // 如果不在，将该项添加到 notFoundItems 数组中
      notFoundItems.push(item);
    }
  });
  // 打印在list数组中存在但在a数组中不存在的项
  if (notFoundItems.length > 0) {
    showxiala.value = true;
    showEmpty.value = false;
    selectNameList.value = notFoundItems;
  } else {
    showxiala.value = false;
    showEmpty.value = true;
  }
};

const handleFilterOptionT = (inputValue, option) => {
  return option.key.name.indexOf(inputValue) >= 0;
};

const cleanList = () => {
  // selectNameList.value = [];
  // allMembers.value = [];
  // uniqueAry.value = [];
  dataList.value = [];
  contactIdList.value = [];
  // document.removeEventListener('click');
};

const rebackData = () => {
  if (props.circleId) {
    emits('changeList', {
      type: 1,
      data: contactIdList.value,
    });
  } else {
    emits('changeList', {
      type: 0,
      data: dataList.value,
    });
  }
};


const openContactAddCircle = () => {
  if(props.circleId) {
    contactAddCircleShow.value = true;
  } else {
    message.info('请先选择人脉圈')
  }

};

const handleContactAddCircle = value => {
  contactAddCircleShow.value = value.visible;

  // //刷新人脉圈成员下拉
  todayApi
    .queryContactByName('', {
      connectionsId: props.circleId,
    })
    .then(res => {
      if (res) {
        // console.log('res66123', res);
        selectNameList.value = res;
        if (selectNameList.value.length > 0) {
          selectNameList.value.unshift({
            id: 'allMembers',
            name: '全部成员',
          });
        }
      }
    });
  //
  // //刷新该脉圈下的全部成员 puah form.contactIdList
  todayApi
    .queryContactByConnections('', {
      connectionsId: props.circleId,
    })
    .then(res => {
      if (res) {
        // console.log('全部成员', res);
        res.forEach(item => {
          allMembers.value.push(item.id);
        });
      }
    });

  // form.contactIdList
  value.ids.forEach(item => {
    contactIdList.value.push(item);
  });

  rebackData();
};
const closeContactAddCircle = () => {
  contactAddCircleShow.value = false;
};

onMounted(() => {
  console.log('list mounted');
  if (props.selectIdList && props.circleId) {
    contactIdList.value = props.selectIdList;
  }
  if (props.selectList && !props.circleId) {
    dataList.value = props.selectList;
  }
  if (!props.circleId && props.contactId) {
    timelineApi
      .getContactMessage('', {
        userId: store.state.account.user.id,
        contactId: props.contactId,
      })
      .then(res => {
        dataList.value.push(res);
      });
    rebackData();
  }
  if (props.circleId) {
    todayApi
      .queryContactByName('', {
        connectionsId: props.circleId,
      })
      .then(res => {
        if (res) {
          // console.log('res66123', res);
          selectNameList.value = res;
          if (selectNameList.value.length > 0) {
            // state.selectNameList.unshift({
            //   id: 'allMembers',
            //   name: '全部成员',
            // });
            selectNameList.value.unshift(
              // {
              //   id: 'allEmpty',
              //   name: '一键清除',
              // },
              {
                id: 'allMembers',
                name: '全部成员',
              }
            );
          }
          // console.log('res66state.selectNameList', state.selectNameList);
        }
      });
    todayApi
      .queryContactByConnections('', {
        connectionsId: props.circleId,
      })
      .then(res => {
        if (res) {
          // console.log('全部成员', res);
          res.forEach(item => {
            allMembers.value.push(item.id);
          });
          // console.log(allMembers.value);
        }
      });
  } else {
    document.addEventListener('click', e => {
      if (selectBox.value) {
        if (!selectBox.value.contains(e.target)) {
          showxiala.value = false;
          showEmpty.value = false;
        }
      }
    });
  }
});

// 监听人脉圈分类变化
watch(
  ()=>props.circleId,
  (value)=>{
    console.log('circleId', value)
    if (value) {
      // 人脉圈分类变化时清空之前已选中的人脉圈成员、清空之前已经选中过的全部成员
      contactIdList.value = [];
      allMembers.value = [];
      //刷新人脉圈成员下拉
      todayApi
        .queryContactByName('', {
          connectionsId: props.circleId,
        })
        .then(res => {
          if (res) {
            // console.log('res66123', res);
            selectNameList.value = res;
            if (selectNameList.value.length > 0) {
              selectNameList.value.unshift({
                id: 'allMembers',
                name: '全部成员',
              });
            }
          }
        });

      //刷新该脉圈下的全部成员 puah form.contactIdList
      todayApi
        .queryContactByConnections('', {
          connectionsId: props.circleId,
        })
        .then(res => {
          if (res) {
            // console.log('全部成员', res);
            res.forEach(item => {
              allMembers.value.push(item.id);
            });
          }
        });

      // form.contactIdList
      // value.ids.forEach(item => {
      //   contactIdList.value.push(item);
      // });

    }

  }
)
watch(()=> props.connectionsIdState,
  (value)=>{
     if (value === '1') {
       console.log('记录人脉圈值是空')
       contactIdList.value = [];
       allMembers.value = [];
     }
  }
)

watch(
  () => props.selectList,
  (newVal, oldVal) => {
    if (newVal.length !== 0) {
      dataList.value = props.selectList;
      document.addEventListener('click', e => {
        if (selectBox.value) {
          if (!selectBox.value.contains(e.target)) {
            showxiala.value = false;
            showEmpty.value = false;
          }
        }
      });
    }
  }
);

watch(
  () => props.selectIdList,
  (newVal, oldVal) => {
    if (newVal.length !== 0) {
      contactIdList.value = props.selectIdList;
      if (props.circleId) {
        todayApi
          .queryContactByName('', {
            connectionsId: props.circleId,
          })
          .then(res => {
            if (res) {
              // console.log('res66123', res);
              selectNameList.value = res;
              if (selectNameList.value.length > 0) {
                // state.selectNameList.unshift({
                //   id: 'allMembers',
                //   name: '全部成员',
                // });
                selectNameList.value.unshift(
                  {
                    id: 'allMembers',
                    name: '全部成员',
                  }
                );
              }
              // console.log('res66state.selectNameList', state.selectNameList);
            }
          });
        todayApi
          .queryContactByConnections('', {
            connectionsId: props.circleId,
          })
          .then(res => {
            if (res) {
              // console.log('全部成员', res);
              res.forEach(item => {
                allMembers.value.push(item.id);
              });
              // console.log(allMembers.value);
            }
          });
      }
    }
  }
);

proxy.$bus.on('getConnectionsIdState', value => {
// 人脉圈分类变化时清空之前已选中的人脉圈成员、清空之前已经选中过的全部成员
  if (value) {
    contactIdList.value = [];
    allMembers.value = [];
  }

});


defineExpose({ cleanList });
</script>

<style lang="less" scoped>
.contact-list {
  .dataListClass {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4px;
    margin-top: 16px;
    max-height: 68px;
    overflow: auto;

    .name {
      // width: 88px;
      height: 24px;
      border-radius: 4px;
      //background: #FFFFFF;
      background: #fffaf6;
      font-size: 12px;
      line-height: 20px;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 8px 8px 0px;
      gap: 4px;
    }
  }

  .formItemClass {
    position: relative;
  }

  .namename {
    max-height: 164px;
    width: 100%;
    overflow: auto;
    position: absolute;
    top: 24px;
    z-index: 10;
    margin-top: 16px;
    border: 1px solid #eeeff5;
    background: #fff;
    border-radius: 4px;

    .selectList {
      .selectName {
        height: 40px;
        background: #f5f6fa;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-left: 16px;

        &:hover {
          background: #fffaf6;
        }
      }
    }
  }

  .empty {
    color: #ff7b00;
    margin-right: 12px;
  }
}

/deep/ .ant-btn {
  font-size: 12px;
}
</style>
