<template>
  <teleport to="body">
    <div class="mask" @click.stop></div>
    <!--
      :style="{
            width: w + 'px',
            height: h + 'px',
            top: top + 'px',
            left: left + 'px',
          }"
    -->
    <div
      class="mask-step3"

    >
      <div class="step3-content1">
        <div class="fs-16 fw-7">确认添加</div>
        <div class="fs-14 mt-10">
          选择完需批量添加的联系人后，点击完成后将已选择联系人添加成功。
        </div>
        <div class="flex mt-10">
          <span>3 / 3</span>
          <div class="flex">
            <div
              class="l-btn-primary mr-10"
              style="
                font-size: 12px;
                padding: 5px 10px;
                line-height: 1.5;
                background: #fff;
                color: #FF7B00;
                border: 1px solid #FF7B00;
              "
              @click="stepChange('prev')"
            >
              上一步
            </div>
            <div
              class="l-btn-primary"
              style="font-size: 12px; padding: 6px 18px; line-height: 1.5"
              @click="stepChange('done')"
            >
              完成
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="step3-content2">-->
      <!--        <img src="@/assets/images/step/step31.jpg" style="width: 100%" alt="" />-->
      <!--      </div>-->
    </div>
  </teleport>
</template>

<script>
import { reactive, onMounted, nextTick, toRefs } from 'vue';
export default {
  name: 'step3',
  emits: ['stepChange'],
  setup(props, context) {
    const maskRect = reactive({
      w: 0,
      h: 0,
      top: 0,
      left: 0,
    });
    onMounted(() => {
      nextTick(() => {
        const step3 = document.getElementById('step3');
        const rect = step3.getBoundingClientRect();
        maskRect.w = rect.width + 10;
        maskRect.h = rect.height + 10;
        maskRect.top = rect.y - 5;
        maskRect.left = rect.x - 5;
      });
    });
    const stepChange = type => {
      context.emit('stepChangeContacts', type, 3);
    };
    return {
      ...toRefs(maskRect),
      stepChange,
    };
  },
};
</script>

<style lang="less" scoped>
.mask-step3 {
  position: fixed;
  //width: 170px;
  height: 190px;
  top: 370px;
  left: 467px;
  z-index: 99999;

  //box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 9999px;
  & > div {
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
  }
}
.step3-content1 {
  width: 362px;
  position: absolute;
  top: -181px;
  left: 486px;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

  //&::before {
  //  content: '';
  //  position: absolute;
  //  bottom: -20px;
  //  left: 50%;
  //  z-index: 1000;
  //  margin-left: -10px; /* 将箭头置于对话框的中央 */
  //  border-width: 10px;
  //  border-style: solid;
  //  border-color: #ffffff transparent transparent transparent; /* 设置箭头颜色 */
  //}
}
.step3-content2 {
  padding: 0px !important;
  width: 350px;
  position: absolute;
  top: 45px;
  left: -125px;
  background-color: transparent !important;
}
</style>
