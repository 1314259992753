<template>
  <div class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      title="新增联系人"
      :mask-closable="false"
      :width="550"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
      :getContainer="() => container"
      zIndex="1001"
    >
      <a-form layout="vertical">
        <a-form-item label="姓名" v-bind="validateInfos.name">
          <a-input v-model:value="form.name" placeholder="请输入联系人姓名" />
        </a-form-item>
        <a-form-item label="电话" v-bind="validateInfos.phone">
          <a-input v-model:value="form.phone" placeholder="请输入联系人电话" />
        </a-form-item>
        <a-form-item
          v-for="(item, index) in phoneList"
          :key="item.key"
          :label="index === 0 ? '' : ''"
          :name="['phoneList', index, 'phone']"
        >
          <a-input
            v-model:value="item.value"
            placeholder="请输入联系人电话"
            style="width: 95%; margin-right: 8px"
            type="number"
          />
          <MinusCircleOutlined
            v-if="phoneList.length > 0"
            class="dynamic-delete-button"
            style="color: #ff7b00 !important"
            @click="removeDomain(item)"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="dashed" @click="addDomain" style="width: 100%">
            <PlusOutlined />
            添加联系人电话
          </a-button>
        </a-form-item>
        <a-form-item label="备注" v-bind="validateInfos.describe">
          <a-textarea
            v-model:value="form.describe"
            placeholder="请输入联系人备注"
          />
        </a-form-item>

        <a-form-item label="群组">
          <!--        <a-select v-model:value="form.groupsId" placeholder="请选择">-->
          <!--          <a-select-option-->
          <!--              v-for="item in groupsList"-->
          <!--              :key="item.value"-->
          <!--              :value="item.value"-->
          <!--              @click="handleSelect(item)"-->
          <!--          >-->
          <!--            {{ item.label }}-->
          <!--          </a-select-option>-->
          <!--        </a-select>-->

          <a-tree-select
            v-model:value="form.groupsQueryId"
            placeholder="请选择群组"
            allow-clear
            :tree-data="groupsList"
            :replace-fields="{ key: 'id', value: 'id', title: 'groupName' }"
          />
        </a-form-item>

        <div class="addMessage" @click="addMessage" v-if="!showMessage">
          <PlusOutlined class="issIcon" />
          添加更多信息
        </div>

        <div v-else>
          <a-form-item label="邮箱" v-bind="validateInfos.email">
            <a-input
              v-model:value="form.email"
              placeholder="请输入联系人邮箱"
            />
          </a-form-item>
          <a-form-item label="公司" v-bind="validateInfos.company">
            <a-textarea
              v-model:value="form.company"
              placeholder="请输入联系人公司"
            />
          </a-form-item>
          <a-form-item label="地址" v-bind="validateInfos.address">
            <a-textarea
              v-model:value="form.address"
              placeholder="请输入联系人地址"
            />
          </a-form-item>
        </div>
      </a-form>

      <template v-slot:footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk" :loading="loading"
          >确认</a-button
        >
      </template>
    </a-modal>
  </div>

  <!--  </div>-->
</template>
<script>
// watch  Select, mobileRegExp
import { reactive, ref, toRaw, toRefs } from 'vue';
import { Form, message, TreeSelect } from 'ant-design-vue';
import { emailRegExp } from '@/utils/validation';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import contactAll from '@/api/contactAll';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { isKeyDuplicate } from '@/utils';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    PlusOutlined,
    // ASelect: Select,
    // ASelectOption: Select.Option,
    ATreeSelect: TreeSelect,
    MinusCircleOutlined,
  },
  props: {
    visible: Boolean,
    init: Object,
    jump: Boolean,
    backContact: Boolean,
  },
  setup(props, context) {
    const router = useRouter();
    const container = ref();
    const store = useStore();
    const userId = store.state.account.user.id;
    // const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      name: '',
      phone: '',
      describe: '',
      groupsQueryId: null,
      email: '',
      company: '',
      address: '',
      userId: userId,
      phoneList: [],
      // emailList: [],

      //  传给后端 手机和邮箱 数组
      // phoneList  手机
      // emailList 邮箱
    });

    const phoneList = reactive([]);

    const addDomain = () => {
      if (phoneList.length >= 4) {
        return message.error('最多只可设置5个手机号');
      } else {
        phoneList.push({
          value: '',
          key: Date.now(),
        });
      }
    };

    const removeDomain = item => {
      const index = phoneList.indexOf(item);
      if (index !== -1) {
        phoneList.splice(index, 1);
      }
    };

    const state = reactive({
      showMessage: false,
      groupsList: [],
      loading: false,
    });

    // watch(
    //     () => props.init,
    //     init => Object.assign(form, init)
    // );

    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      name: [
        { required: true, message: '请输入联系人姓名' },
        {
          max: 24,
          message: '姓名长度不能超过24',
        },
      ],
      phone: [
        { required: true, message: '请输入联系人电话' },
        {
          pattern: /^[0-9]{1,56}$/,
          max: 56,
          message: '电话请输入数字且长度不能超过56',
        },
      ],

      describe: [{ max: 16, message: '备注长度不能超过16' }],
      company: [{ max: 56, message: '公司长度不能超过56' }],
      address: [{ max: 56, message: '地址长度不能超过64' }],
      email: [{ pattern: emailRegExp, message: '请输入正确的邮箱地址' }],
    });

    contactAll.getContactGroupsList('', userId).then(res => {
      state.groupsList = res;
    });

    const handleCancel = () => {
      context.emit('update:visible', false);
      state.showMessage = false;
      // 表单重置
      resetFields();
    };

    return {
      container,
      ...toRefs(state),
      form,
      phoneList,
      addDomain,
      removeDomain,
      validateInfos,
      handleCancel,
      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        validate().then(() => {
          if (phoneList.length > 0) {
            form.phoneList.push(form.phone);
            phoneList.forEach(item => {
              if (item.value !== null && item.value !== '') {
                form.phoneList.push(item.value);
              }
            });
          }

          const isDuplicate = isKeyDuplicate(form.phoneList, 'phone');
          // console.log('isDuplicate', isDuplicate, phoneList)
          if (isDuplicate) {
            return message.error('请勿输入重复手机号');
          }
          state.loading = true;
          contactAll
            .saveUserInfo('', {
              id: form.id,
              name: form.name,
              phone: phoneList.length > 0 ? null : form.phone,
              describe: form.describe,
              groupsQueryId: form.groupsQueryId,
              email: form.email,
              company: form.company,
              address: form.address,
              userId: form.userId,
              phoneList: phoneList.length > 0 ? form.phoneList : null,
            })
            .then(res => {
              console.log('resll', res);
              state.loading = false;
              message.success('操作成功');

              if (props.backContact) {
                context.emit('fnOk', {
                  visible: false,
                  id: res,
                  name: form.name,
                });
              } else {
                context.emit('fnOk', false);
              }

              resetFields();
              phoneList.length = 0;

              if (props.jump) {
                router.push(`/contacts/${res}`);
              }
            });
        });
      },
      addMessage: () => {
        state.showMessage = !state.showMessage;
      },
    };
  },
};
</script>
<style lang="less" scoped>
.modalClass /deep/ .ant-modal-body {
  height: 560px;
  overflow: auto;
  padding: 24px 24px 0px 24px !important;
}
.live-group {
  padding: 0 12px 15px 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
}
.live-group .group-input[data-v-61219ec2] {
  flex: 1;
  padding-bottom: 15px;
}
.flex {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
}
span.anticon.anticon-minus-circle {
  color: #5979f8 !important;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #313d5f;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.addMessage {
  margin: 0px 76px 24px;
  color: #ff7b00;
  cursor: pointer;
}
</style>
