'use strict';

import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';
import Layout from '@/components/layout';
import store from '@/store';
import userApi from '@/api/user.js';
import loginApi from '@/api/login.js';
import personalApi from '@/api/personal';
import { redirect, others } from './config.json';
import 'nprogress/nprogress.css';

const getQueryVariable = (str, variable) => {
  var query = str;
  if (str.indexOf('?') != -1) {
    query = str.slice(str.indexOf('?') + 1);
  }
  var vars = query.split('&');
  if (query.indexOf('&amp;') != -1) {
    vars = query.split('&amp;');
  }
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

let addApps = [];
const whiteList = ['/login', '/bind', '/mRigister', '/index','/share'];
const analysisRoutes = list =>
  list.map(({ children, component, accessWay, meta, ...others }) => {
    if (children) analysisRoutes(children);
    if (accessWay === 'qiankun') {
      addApps.push({ ...others, entry: component });
      router.addRoute('main', {
        path: `${others.path}/:microAppRoute*`,
        name: `microApp-${others.name}`,
        component: () => import('@/views/microApp/qiankun'),
        meta: { ...meta, accessWay },
      });
    } else {
      router.addRoute('main', {
        meta: { ...meta, accessWay },
        ...others,
        component: () =>
          import(
            `@/views/${accessWay === 'iframe' ? 'microApp/iframe' : component}`
          ),
      });
    }
  });
const initData = async ({ userId }) => {
  let replace = false;
  const { account } = store.state;
  if (!account.menus) {
    const menus = await userApi.getMenus();
    store.commit('account/setMenus', menus);
    analysisRoutes(menus.concat(others));
    replace = true;
  }
  if (!account.apps) {
    const apps = await userApi.getApps(userId);
    store.commit('account/setApps', apps);
    apps.forEach(({ id, applicationKey, entryUrl, entry, iframe, status }) => {
      status &&
        iframe === false &&
        addApps.push({
          appId: id,
          name: applicationKey,
          entry: entryUrl,
          path: `/marketing/activity/:type/:id/:eventId/:campaignId/app${entry}`,
        });
    });
    store.commit('common/setApps', addApps);
  }
  // account.permissions ||
  //   store.commit('account/setPermissions', await userApi.getPermissions());
  return replace;
};

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/index.vue'),
      meta: { title: '登录' },
    },
    {
      path: '/agreement/userAgreement',
      name: 'userAgreement',
      component: () => import('@/views/agreement/userAgreement/index.vue'),
      meta: { title: '使用协议' },
    },
    {
      path: '/agreement',
      name: 'privacyAgreement',
      component: () => import('@/views/agreement/privacyAgreement.vue'),
      meta: { title: '隐私保护协议' },
    },
    {
      path: '/index',
      name: 'websitePC',
      component: () => import('@/views/index/website.vue'),
      meta: { title: '人脉宝盒官网' },
    },
    {
      path: '/mRigister',
      name: 'mRigister',
      component: () => import('@/views/mRigister/index.vue'),
      meta: { title: '邀请好友' },
    },
    {
      path: '/share',
      name: 'share',
      component: () => import('@/views/share/index.vue'),
      meta: { title: '人脉宝盒小时刻' },
    },
    {
      path: '/getCode',
      name: 'getCode',
      component: () => import('@/views/login/getCode.vue'),
      meta: { title: '身份验证' },
    },
    {
      path: '/bind',
      name: 'bind',
      component: () => import('@/views/login/qrcodeBind.vue'),
      meta: { title: '手机绑定' },
    },
    {
      path: '/bindGetCode',
      name: 'bindGetCode',
      component: () => import('@/views/setup/components/bindGetCode.vue'),
      meta: { title: '绑定中' },
    },
    {
      path: '/404',
      //component: () => import('@/views/error/404'),
      meta: { title: '404' },
    },
    {
      path: '/',
      name: 'main',
      component: Layout,
      redirect,
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
    },
  ],
});

router.beforeEach(async ({ name, fullPath, path, meta, query }) => {
  NProgress.start();
  name.startsWith('microApp-') ||
    (meta?.title &&
      (document.title = `${meta.title} - ${process.env.VUE_APP_PROJECT_TITLE}`));
  if (whiteList.includes(path)) return true;
  // 判断设备，当前是不是pc，如果不是就跳转到移动端H5链接 邀请好友
  if ((path == '/today' || path == '/inviteLink') && query?.inviterCode) {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      // 移动端邀请页面链接跳转h5注册页
      return `/mRigister?inviterCode=${query?.inviterCode}`;
    } else {
      // pc端邀请页面链接跳转登录页
      return `/login?inviterCode=${query?.inviterCode}`;
    }
  }
  // 通过正则来判断
  // 如果是手机端跳转到 mobile_web端页面
  // 如果是PC端跳转到  PC端页面

  if (path == '/getCode') {
    let code = getQueryVariable(fullPath, 'code');
    if (code) {
      return true;
    } else {
      return '/index';
      // return '/login';
    }
  }
  if (path == '/bindGetCode') {
    let code = getQueryVariable(fullPath, 'code');
    if (code) {
      return true;
    } else {
      return '/index';
      // return '/login';
    }
  }

  let { token, user } = store.state.account;
  if (token) {
    console.log('token if', path)
    // debugger
    if (!user) {
      user = await personalApi.getOwn();
      store.commit('account/setUser', user);
    }
    return (await initData(user)) ? fullPath : true;
  } else {
    // console.log('token else', path)
    // debugger
    // 隐私保护协议
    if (path === '/agreement') {
     return true
    }

    if (path === '/agreement/userAgreement') {
      // 使用协议
      return true
    }

    if (path === '/login') {
      return true
    }

    // 邀请好友页面
    if (path === '/mRigister') {
      return true
    }

      // return '/login';
    // 默认去人脉宝盒官网
      return '/index';

  }
});

router.afterEach(() => NProgress.done());

export default router;
