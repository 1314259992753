<template>
  <a-modal
    :visible="visible"
    :destroyOnClose="true"
    :title="'新增关系组'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      layout="vertical"
      style="width: 100%"
    >
      <a-form-item :label="'关系组名称'" name="groupName">
        <a-input
          v-model:value="formState.groupName"
          :placeholder="'请输入关系组名称'"
        >
          <template #addonBefore>
            <component
              @click="visibleIcon = true"
              class="addonBefore"
              :is="icons[`${formState.icon}`]"
            />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="描述" name="description">
        <a-input
          v-model:value="formState.description"
          placeholder="请输入描述"
        />
      </a-form-item>
      <a-form-item label="选择父级" name="">
        <a-select
          v-model:value="formState.parentId"
          placeholder="请选择父级关系组，未选时默认创建父级关系组"
          class="selClass"
          :allowClear="true"
        >
          <a-select-option
            v-for="item in items"
            :key="item.id"
            :value="item.id"
          >
            {{ item.groupName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!--      <a-form-item-->
      <!--        label="父级"-->
      <!--        name="parentName"-->
      <!--        v-if="-->
      <!--          formState.type === 'add' &&-->
      <!--          formState.show &&-->
      <!--          formState.parentId != '0' &&-->
      <!--          !!formState.parentId-->
      <!--        "-->
      <!--      >-->
      <!--        <a-input v-model:value="formState.parentName" :disabled="true" />-->
      <!--      </a-form-item>-->
    </a-form>
    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>

  <icon-modal
    v-model:visible="visibleIcon"
    :init-value="formState.icon"
    @fnOk="value => (formState.icon = value)"
  />
</template>

<script>
import {
  Tree,
  Dropdown,
  Menu,
  Form,
  message,
  Typography,
  Select,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IconModal from '@/components/iconModal';
import { reactive, ref, toRefs, watch, onMounted } from 'vue';
import menuApi from '@/api/menu';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  components: {
    ADirectoryTree: Tree.DirectoryTree,
    ATreeNode: Tree.TreeNode,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AForm: Form,
    ATypographyParagraph: Typography.Paragraph,
    AFormItem: Form.Item,
    IconModal,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  props: {
    visible: Boolean,
    init: Object,
    jump: Boolean,
  },

  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const formRef = ref(null);
    const state = reactive({
      visible: false,
      visibleIcon: false,
      loading: false,
      openType: '',
      items: [],
      rules: {
        groupName: [
          {
            required: true,
            // formState.parentId === '0' ? '请输入关系组名称' : '请输入子关系组名称',
            message: '请输入关系组名称',
            trigger: 'change',
          },
          {
            max: 16,
            message: '字符长度不能超过16',
            trigger: 'change',
          },
        ],
        description: [
          {
            // required: true,
            message: '请输入描述',
            trigger: 'change',
          },
          {
            max: 32,
            message: '字符长度不能超过32',
            trigger: 'change',
          },
        ],
      },
    });
    const formState = reactive({
      groupName: '',
      description: '',
      parentId: null,
      icon: 'FolderOpenOutlined',
      type: '',
      groupMessage: {},
      id: '',
      show: true,
    });
    // 父级增加子级,parentId 就传父级id
    // 子级增加 , parentId 就传父级0
    // parentId: "0" 表示时父级， 传 parentId: ""
    watch(
      () => props.init,
      init => {
        console.log('init', init);
        // formState.parentId = init.id;
        formState.type = init.type;
        // 需要增加一层判断，当前是父级还是子级
      }
    );
    // console.log('formState', formState.show, formState.type);
    const getList = () => {
      menuApi
        .getList({
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            state.items = res;
          }
        });
    };

    onMounted(() => {
      getList();
    });

    const handleOk = () => {
      formRef.value.validate().then(() => {
        state.loading = true;
        const obj = JSON.parse(JSON.stringify(formState));
        let type = 'add';
        menuApi[type]({
          userId: store.state.account.user.id,
          ...obj,
        })
          .then(res => {
            if (res) {
              message.success('添加成功');
              state.loading = false;
              context.emit('fnOk', false);
              formRef.value.resetFields();
              formState.parentId = null;

              if (props.jump) {
                let path = '/group/groupItem?id=' + res.id;
                router.push(path);
                getMenuList();
              }
            }
          })
          .catch(() => {
            state.loading = false;
            // message.error('添加失败');
          });
      });
    };
    const handleCancel = () => {
      context.emit('fnOk', false);
      formRef.value.resetFields();
      formState.parentId = null;
    };

    const getMenuList = () => {
      menuApi
        .getList({
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            res = res.map(i => {
              const obj = {
                id: i.id,
                parentId: i.parentId,
                path: '/group/groupItem?id=' + i.id,
                name: 'groupItem',
                menuKey: 'groupItem',
                type: 'groupItem',
                component: 'group/groupItem',
                accessWay: 'builtin',
                description: i.description,
                meta: {
                  title: i.groupName,
                  icon: i.icon,
                  breadcrumb: true,
                  size: i.size,
                },
                hidden: false,
                alwaysShow: false,
              };
              if (i.children?.length) {
                i.children = i.children.map(item => {
                  return {
                    id: item.id,
                    parentId: item.parentId,
                    path: '/group/groupItem?id=' + item.id,
                    name: 'groupItem',
                    menuKey: 'groupItem',
                    type: 'groupItem',
                    component: 'group/groupItem',
                    accessWay: 'builtin',
                    description: item.description,
                    meta: {
                      title: item.groupName,
                      icon: item.icon,
                      breadcrumb: true,
                      size: item.size,
                    },
                    hidden: false,
                    alwaysShow: false,
                  };
                });
                return {
                  ...obj,
                  children: i.children,
                };
              } else {
                return obj;
              }
            });
            let target = [
              {
                path: '/groupMenu',
                // path: '',
                name: 'groupMenu',
                menuKey: 'groupMenu',
                component: 'Layout',
                accessWay: 'builtin',
                description: '关系组关系组',
                meta: {
                  title: '关系组',
                  icon: 'custom',
                  breadcrumb: true,
                  select: '@/assets/images/menu/groupS.png',
                  unSelect: '@/assets/images/menu/group.png',
                },
                hidden: false,
                alwaysShow: false,
                children: [],
              },
            ];
            target[0].children = res;
            store.commit('account/setCustomMenus', target);
          }
        });
    };

    return {
      formState,
      ...toRefs(state),
      handleOk,
      icons,
      formRef,
      handleCancel,
      router,
      getMenuList,
    };
  },
};
</script>

<style scoped lang="less">
.addonBefore {
  color: #ff8f1f;
}
</style>
