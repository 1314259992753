'use strict';

import http from '@/utils/http';
import axios from 'axios';

export default {
  pageUrl: '/authority/user/page',
  getPage(code, params) {
    return http.get('/authority/user/page', { params, code });
  },
  add(code, data) {
    return http.post('/authority/user', data, { code });
  },
  update(code, data) {
    return http.put('/authority/user', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/user', { params, code });
  },
  avatar(code, data) {
    return http.put('/authority/user/avatar', data, { code });
  },
  getUserIdByRoleId(code, roleId) {
    return http.get(`/authority/role/user/${roleId}`, { code });
  },
  async getMenus() {
    const { data } = await axios.get(
      `${process.env.VUE_APP_PUBLIC_PATH}` +
        'router.json' +
        `?time=${+new Date()}`
    );
    return data.map(item => {
      if (item.children) {
        return {
          ...item,
          name: item.menuKey,
          children: item.children.map(i => ({
            ...i,
            name: i.menuKey,
          })),
        };
      }
      return { ...item, name: item.menuKey };
    });
  },
  getPermissions(params) {
    return http.get('/authority/resource/find', { params });
  },
  async getApps() {
    // if (process.env.NODE_ENV === 'development') {
    const { data } = await axios.get(
      `${process.env.VUE_APP_PUBLIC_PATH}` +
        'link.json' +
        `?time=${+new Date()}`
    );
    return data;
    // } else {
    //   return http.get(`/authority/applicationClient/visible/${userId}`);
    // }
  },
  verifyEmailOrPhone(code, data) {
    return http.post('/authority/user/judgment/emailOrPhone', data, { code });
  },
  getInviterInfo(data) {
    return http.get(
      `/connections_treasure_box/sys-user/invitationCode/${data.InviterCode}`
    );
  },
  userFeedback(code, data) {
    return http.post( '/connections_treasure_box/user/user-feedback', data, { code });
  },


};
