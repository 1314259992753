<template>
  <a-modal
    :visible="visible"
    title="添加到人脉圈"
    :mask-closable="false"
    :width="600"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
  >
    <div class="tiesGroup">
      <a-button type="primary" @click="handleOpen">新增人脉圈</a-button>
    </div>
    <div class="iss-pulse">
      <!--     -->
      <a-collapse
        v-model:activeKey="activeKey"
        :bordered="false"
        accordion
        :key="activeKey"
        :expand-icon-position="expandIconPosition"
      >
        <a-collapse-panel
          :key="item.id"
          :header="item.connectionsName"
          v-for="item in treeData"
          :class="{ showBack: activeKey === item.id }"
        >
          <!--          {{item}}-->
          <div
            v-for="i in item.children"
            :key="i.key"
            @click="handleItem(i)"
            class="panel"
          >
            <!--         小图标    -->
            <img
              src="@/assets/images/box/frequenct.png"
              alt=""
              class="frequenct-img"
              :class="{ activeClass: i.id === showBut }"
            />
            {{ i.connectionsName }}
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>
  <add-page-circle
    v-model:visible="openTiesGroup"
    :activeItem="activeItem"
    @fnOk="handleAddPage"
  />
</template>
<script>
import { onMounted, reactive, ref, toRaw, toRefs, watch } from 'vue';
import { Collapse, message } from 'ant-design-vue';
import { useStore } from 'vuex';
import contactAll from '@/api/contactAll';
import addPageCircle from '@/views/contactsCircle/addPage';

export default {
  components: {
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel,
    addPageCircle,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const store = useStore();
    const expandIconPosition = ref('right');
    const userId = store.state.account.user.id;
    const form = reactive({
      contactId: [],
      userId: userId,
      connectionsId: '',
      selectAll: null,
      pageDTO: {},
    });

    const state = reactive({
      showBut: '',
      activeKey: '',
      treeData: [],
      loading: false,
      openTiesGroup: false,
    });

    watch(
      () => props.init,
      value => {
        console.log('init人脉圈id', value);
        // form.contactId = value;
        Object.assign(form.contactId, value.id);
        form.selectAll = value.selectAll
        form.pageDTO = value.pageDTO
      }
    );

    const getData = () => {
      contactAll.queryConnectionsTree('', userId).then(res => {
        Object.assign(state.treeData, res);
      });
    };

    onMounted(() => {
      getData();
    });

    const handleCancel = () => {
      context.emit('update:visible', false);
      // 表单重置
    };

    return {
      ...toRefs(state),
      form,
      handleCancel,
      expandIconPosition,
      handleOpen() {
        state.openTiesGroup = true;
        // context.emit('fnOk', false);
      },
      handleOk: () => {
        state.loading = true;
        if (form.connectionsId === '') {
          state.loading = false;
          return message.info('请勾选脉圈子级');
        }
        contactAll
          .saveConnections('', toRaw(form))
          .then(() => {
            state.loading = false;
            message.success('操作成功');
            context.emit('fnOk', false);
            // form.connectionsId = i.id;
            state.activeKey = '';
            state.showBut = '';
          })
          .catch(() => {
            message.error('网络异常，请联系技术人员处理')
            state.loading = false;
          });
      },
      handleAddPage(value) {
        state.openTiesGroup = value;
        getData();
      },
      addMessage: () => {},
      handleItem(i) {
        state.showBut = i.id;
        form.connectionsId = i.id;
      },
    };
  },
};
</script>
<style lang="less" scoped>
.panel {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
  border-bottom: 1px solid #eeeeee;
}

.frequenct-img {
  //margin-right: 12px;
  display: none;
  width: 13px;
  height: 13px;
  margin-right: 6px;
  //position: absolute;
  //left: 42px;
}
.activeClass {
  display: block;
}
.showBack /deep/ .ant-collapse-header {
  background: rgba(255, 123, 0, 0.08);
}
.iss-pulse {
  max-height: calc(100vh - 388px);
  overflow-y: auto;
}
.iss-pulse /deep/ .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #eeeeee;
}
.iss-pulse /deep/ .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #eeeeee;
}
.iss-pulse /deep/ .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px 16px 0px 16px;
}
.iss-pulse /deep/ .ant-collapse-borderless {
  background: #ffffff;
}
.iss-pulse
  /deep/
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: #ff9533 !important;
}
.tiesGroup {
  text-align: right;
  margin-bottom: 16px;
}
</style>
