<template>
  <a-modal
    :visible="visible"
    title="选择图标"
    width="800px"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
  >
    <div>
      <a-input-search
        placeholder="请输入名称"
        v-model:value="search"
        allow-clear
      />
    </div>
    <a-tabs v-model:activeKey="activeKey" @change="handleChange">
      <a-tab-pane key="Outlined" tab="线性类" />
      <a-tab-pane key="Filled" tab="填充类" />
      <a-tab-pane key="TwoTone" tab="双色类" />
    </a-tabs>
    <div class="iss-wrapper">
      <a-row class="iss-icon">
        <a-col
          v-for="item in icons"
          :span="4"
          :key="item.key"
          :class="`iss-icon-item${activeIcon === item.key ? ' selected' : ''}`"
          @click="activeIcon = item.key"
        >
          <component :is="item.icon" />
          <div class="icon-name">{{ item.key }}</div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';
import { Col, Row, Tabs } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'IconModal',
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    ARow: Row,
    ACol: Col,
  },
  props: ['visible', 'initValue'],
  setup(props, context) {
    const getIcons = activeKey => {
      const temp = [];
      for (const key in icons) {
        if (
          key.endsWith(activeKey) &&
          key.toLowerCase().includes(search.value)
        ) {
          temp.push({ key, icon: icons[key] });
        }
      }
      return temp;
    };
    const search = ref('');
    const state = reactive({
      activeIcon: props.initValue,
      activeKey: 'Outlined',
      icons: getIcons('Outlined'),
    });
    const handleChange = key => {
      state.icons = getIcons(key);
    };
    const handleCancel = () => context.emit('update:visible', false);
    watch(search, () => handleChange(state.activeKey));
    return {
      search,
      ...toRefs(state),
      handleChange,
      handleCancel,
      handleOk: () => {
        context.emit('fnOk', state.activeIcon);
        handleCancel();
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-wrapper {
  height: 400px;
  overflow-y: auto;
}
.iss-icon {
  border: 1px solid @border-color-base;
  border-width: 1px 0 0 1px;
  text-align: center;
  .iss-icon-item {
    padding: 10px;
    border: 1px solid @border-color-base;
    border-width: 0 1px 1px 0;
    color: @text-color-secondary;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      color: @primary-5;
      box-shadow: @box-shadow-base;
      .anticon {
        color: @primary-5;
        transform: scale(1.4);
      }
    }
    &.selected {
      color: #fff;
      background-color: @primary-color;
      .anticon {
        color: #fff;
      }
    }
    .anticon {
      margin-bottom: 5px;
      font-size: 30px;
      color: @text-color;
      transition: transform 0.3s ease-in-out;
      will-change: transform;
    }
    .icon-name {
      font-size: @font-size-sm;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
