'use strict';

import moment from 'moment';
import { message } from 'ant-design-vue';

export function dateFormat(value, format) {
  return moment(value).format(format || 'YYYY-MM-DD');
}

export function numberFormat(value, count) {
  const temp = Number(value).toFixed(count ?? 2);
  return temp === 'NaN' ? '0.00' : temp;
}

export function randoms(len, radix) {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('') + new Date().getTime();
}

export function getFormData(data) {
  const formData = new FormData();
  data && Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
}

export function setAvatar(avatar) {
  return avatar
    ? avatar.startsWith('http')
      ? avatar
      : require(`@/assets/avatar/${avatar}`)
    : require(`@/assets/avatar/default.png`);
}

export function filterTree(list, condition) {
  let trees = [];
  let parents = [];
  for (let item of list) {
    let node;
    condition(item) && (node = { ...item, children: [] });
    if (item.children) {
      const temp = filterTree(item.children, condition);
      if (temp.trees.length) {
        node
          ? (node.children = temp.trees)
          : (node = { ...item, children: temp.trees });
        parents.push(node);
        parents = parents.concat(temp.parents);
      }
    }
    node && trees.push(node);
  }
  return { trees, parents };
}

export function getTreeItem(list, condition) {
  let temp;
  for (let item of list) {
    if (condition(item)) {
      temp = item;
      break;
    } else if (item.children) {
      temp = getTreeItem(item.children, condition);
      if (temp) break;
    }
  }
  return temp;
}

export function downloadExcel(name, data) {
  const link = document.createElement('a');
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  link.style.display = 'none';
  link.href = URL.createObjectURL(blob);
  link.download = name; // 下载的文件名
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(link.href); //下载完成
  document.body.removeChild(link);
}

export function desensitization(value) {
  return `${value.substring(0, 3)}****${value.substring(7)}`;
}

export function copyText(value) {
  const inputEl = document.createElement('input');
  inputEl.value = value;
  // inputEl.style.display = 'none';
  document.body.appendChild(inputEl);
  inputEl.select();
  document.execCommand('copy');
  document.body.removeChild(inputEl);
}

export function debounce(func, delay = 1000) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.call(this, ...args);
    }, delay);
  };
}
//生成指定区间的数组
export function generateRangeArray(start, end) {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

// 判断数组内是否有重复值
export function isKeyDuplicate(arr, key) {
  const seen = new Set();
  for (const item of arr) {
    const value = item[key];
    if (seen.has(value)) {
      // 有重复的值
      return true;
    }
    seen.add(value);
  }
  // 没有重复的值
  return false;
}

// 处理全选和删除后分页边界值
// selectAll 是否跨页全选， cancelRowsIdLength 取消勾选Id 数组长度, total 总数， id 勾选id 数组长度
export function isDelBoundary(selectAll, cancelRowsIdLength, total, idLength) {
  // console.log('isEelBoundary', selectAll, cancelRowsIdLength, total, idLength)
  if (selectAll) {
    if ((total - cancelRowsIdLength) === 0 ) {
      return false
    } else {
      return true
    }
  } else {

    if (idLength === 0) {
      return false
    } else {
      return true
    }
  }

}
// 遍历a数组,查看b数组是否有包含a数组元素，如果不包含那么，把该元素push 到b数据里面，封装成一个公共方法
// source 来源数组， array 需要去重数组
export function arrayDeduplication(array, source) {
  let ary = []
  source?.map((item)=>{
    if (!array.includes(item.id)) {
      ary.push(item.id)
    }
  })

  array.map((item)=> {
    ary.push(item.id)
  })

  // 没有重复的值
  return ary
}

// 根据id进行两个对象数组 数据去重
export function mergeAndUnique(arr1, arr2, key) {
  const map = new Map();
  let combined = []
  if (arr2) {
     combined = [...arr1, ...arr2];
  } else {
    combined = [...arr1];
  }

  combined.forEach(item => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });

  return Array.from(map.values());
}



// 对象数组根据id去重
export function uniqueByProperty(arr, prop) {
  const map = new Map();
  arr.forEach(item => {
    if (!map.has(item[prop])) {
      map.set(item[prop], item);
    }
  });
  return Array.from(map.values());
}


