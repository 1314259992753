<template>
  <div class="contactsTodo" ref="container">
    <div class="custom-board" v-show="showCustomBoard">
      <a-input-number
        style="width: 100%; line-height: 1"
        v-model:value="customTimeDay"
        :min="1"
        :max="30"
        placeholder="请输入提前天数"
      />
      <a-time-picker
        style="width: 100%"
        format="HH:mm"
        v-model:value="customTimePick"
      />
      <div class="custom-row">
        <a-button @click="closeBoard">取消</a-button>
        <a-button @click="addEarlyList" type="primary">确认</a-button>
      </div>
    </div>
<!--    <a-modal-->
<!--      :visible="visible"-->
<!--      :title="form.id ? '编辑待办' : '新增待办'"-->
<!--      :mask-closable="false"-->
<!--      :width="564"-->
<!--      @ok="handleOk"-->
<!--      centered-->
<!--      @cancel="handleCancel"-->
<!--      forceRender-->
<!--      :destroyOnClose="true"-->
<!--      :getContainer="() => container"-->
<!--      class="issModal"-->
<!--    >-->
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="form"
        :rules="rules"
      >
<!--      增加tree下拉   -->
        <a-form-item label="" name="connectionsId">
          <a-tree-select
            v-model:value="form.connectionsId"
            placeholder="请选择人脉圈"
            allow-clear
            :tree-data="treeData"
            tree-default-expand-all
            :replace-fields="{ key: 'id', value: 'id' }"
          />
        </a-form-item>

        <a-form-item label="" name="content">
          <a-textarea
            v-model:value="form.content"
            placeholder="请输入待办内容"
            showCount
            :maxlength="200"
            :rows="4"
          />
        </a-form-item>
        <a-form-item>
          <a-button @click="openContactAddCircle">新增联系人</a-button>
        </a-form-item>
        <a-form-item label="" name="contactIdList">
          <a-select
            mode="multiple"
            autoClearSearchValue
            v-model:value="form.contactIdList"
            placeholder="请添加人脉圈成员"
            :filter-option="
              (inputValue, option) => handleFilterOptionT(inputValue, option)
            "
            @change="(value, option) => handleChange(option, value)"
            :allowClear="true"
          >
            <a-select-option
              v-for="item in selectNameList"
              :key="item"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-row>
          <a-col :span="12">
            <a-form-item label="" name="reminderTime">
              <span class="time-tip-text">待办时间:</span>
              <a-date-picker
                v-model:value="form.reminderTime"
                :getCalendarContainer="triggerNode => triggerNode.parentNode"
                value-format="yyyy-MM-DD HH:mm"
                show-time
                @ok="
                  {
                    form.cycleReminderTime = form.reminderTime;
                  }
                "
                :disabled-date="disabledDate"
                :disabled-time="disabledReminderTime"
                @change="(value) => handleChangeReminderTime(value)"
                :allowClear='false'
                placeholder="请选择待办时间"
                style="width: 100%"
                class="phoneCalssRight"
                :inputReadOnly="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="11" class="colCalss">
            <!--    提前提醒时间    -->
            <a-form-item
              label=""
              name="reminderCycle"
              class="dropdownRenderSel"
            >
              <span class="time-tip-text">提醒时间:</span>
              <a-date-picker
                v-model:value="form.cycleReminderTime"
                value-format="yyyy-MM-DD HH:mm"
                show-time
                :disabled="!form.reminderTime"
                :getCalendarContainer="triggerNode => triggerNode.parentNode"
                :disabled-date="disabledCycleDate"
                :disabled-time="disabledCycleTime"
                placeholder="请选择提醒时间"
                style="width: 100%"
                class="phoneCalssRight"
                :inputReadOnly="true"
                @change='(value)=>cycleReminderTimeChange(value)'
                :allowClear='false'
              />
              <!-- @blur="handleBlur('reminderCycle')" -->
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item label="" >
          <div class="blessingText">
            <div class='textLabel'>待办提醒时间</div>
            <div class='tR'>
              <div
                v-for="item in earlyList"
                :key="item.id"
                class="itemsBel cursor-p"
                @click="handleItems(item)"
                :class="{ showBor: form.reminderCycle === item.itemCode }"
              >
                <div class="text">{{ item.itemNameCn }}</div>
              </div>
            </div>
          </div>
        </a-form-item>

        <!--   提醒方式   -->
        <a-form-item label="" name="reminderMethodList">
          <a-select
            mode="multiple"
            autoClearSearchValue
            v-model:value="form.reminderMethodList"
            placeholder="请选择提醒方式"
            :filter-option="
              (inputValue, option) =>
                handleFilterOption(inputValue, option, item)
            "
          >
            <a-select-option
              v-for="item in remindList"
              :key="item"
              :value="item.itemCode"
            >
              {{ item.itemNameCn }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-for="(item, index) in form.subItemsList"
          :key="item.key"
          :label="index === 0 ? '' : ''"
          :name="['subItemsList', index, 'phone']"
          :rules="rulesPhone"
          :class="{
            bt: index === 0 ? true : false,
          }"
          class="subItem"
        >
          <a-input
            v-model:value="item.subContent"
            placeholder="请输入子待办"
            style="width: 55%; margin-right: 8px; height: 32px"
          />
          <!--      :disabled="dynamicValidateForm.domains.length === 1"
                       v-if="dynamicValidateForm.domains.length > 1"
            -->
          <a-button
            style="width: 195px; margin-right: 8px"
            v-if="item.reminderTime == null"
            @click="item.reminderTime = ''"
          >添加时间</a-button
          >
          <a-date-picker
            v-model:value="item.reminderTime"
            value-format="yyyy-MM-DD HH:mm"
            show-time
            :disabled-date="disabledCycleDateOther"
            :disabled-time="disabledCycleTime"
            placeholder="请选择提醒时间"
            class="phoneCalssRight"
            style="margin-right: 8px"
            :inputReadOnly="true"
            v-else
          />
          <MinusCircleFilled
            class="dynamic-delete-button"
            @click="removeDomain(item)"
          />
        </a-form-item>
        <!--          v-bind="formItemLayoutWithOutLabel"-->
        <a-form-item class="subItemBut">
          <a-button type="dashed" style="width: 100%" @click="addDomain">
            <PlusOutlined />
            添加待办事项
          </a-button>
        </a-form-item>
      </a-form>
    <div class='butClass'>
      <a-button @click="handleCancel" class='coleClass'>取消</a-button>
      <a-button type="primary" class='okClass' @click="handleOk" :loading="loading">确定</a-button>
    </div>
<!--    </a-modal>-->
    <add-contacts-circle
      :init="connectionsId"
      :visible="contactAddCircleShow"
      :backContact="true"
      @fnOk="handleContactAddCircle"
      @update:visible="closeContactAddCircle"
    />
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  watch,
  onMounted,
  ref,
  onBeforeUnmount,
  getCurrentInstance,
} from 'vue';
import { useStore } from 'vuex';
import {
  MinusCircleFilled,
  PlusOutlined,
  // CloseOutlined,
} from '@ant-design/icons-vue';
import {
  Form,
  message,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Row,
  Col,
  InputNumber,
  TreeSelect
} from 'ant-design-vue';
import contactAll from '@/api/contactAll';
import todayApi from '@/api/today';
import moment from 'moment';
import addContactsCircle from '@/views/contactsCircle/addContactsCircle';
// import {useRoute} from "vue-router";

export default {
  components: {
    MinusCircleFilled,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADatePicker: DatePicker,
    PlusOutlined,
    ARow: Row,
    ACol: Col,
    // ASpace: Space,
    ATimePicker: TimePicker,
    AInputNumber: InputNumber,
    // VNodes: (_, { attrs }) => attrs.vnodes,
    // CloseOutlined,
    addContactsCircle,
    ATreeSelect: TreeSelect,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const router = useRouter();
    // const route = useRoute();
    const contactAddCircleShow = ref(false);
    const customBtn = ref();
    const { proxy } = getCurrentInstance();
    const container = ref();
    const formRef = ref();
    const store = useStore();
    const userId = store.state.account.user.id;
    const form = reactive({
      content: '',
      reminderTime: null,
      // reminderCycle: null,
      cycleReminderTime: null,
      // 自定义准时
      inAdvanceDays: null,
      time: '',
      // 人员类型 0-个人 1-脉圈
      personnelType: null,
      contactIdList: [],
      connectionsId: null,
      contentName: [],
      // 子待办数组
      subItemsList: [],
      type: '',
      dataType: '',
      // 主待办id
      todoId: '',
      userId: store.state.account.user.id,
      reminderMethodList: [],
      inputValue: '',
      reminderCycle: '0',
    });

    const state = reactive({
      treeData: [],
      earlyList: [],
      remindList: [],
      // 联系人下拉
      contactList: [],
      len: false,
      selectNameList: [],
      dataList: [],
      showxiala: false,
      connectionsId: '',
      // 自定义提示时间处理
      spEarlyList: [],
      showCustomBoard: false,
      earlyListOpen: false,
      boardTop: 0,
      boardLeft: 0,
      time: {},
      customTimeDay: null,
      customTimePick: '',
      allMembers: [],
      loading: false,
      uniqueAry: [],
      comeFrom: '',

    });

    watch(
      () => props.visible,
      () => {
        let init = props.init;
        console.log('init脉圈', init);
        // 在联系人详情或者脉圈详情中添加得待办，需要拿到当下联系人id或脉圈详情id添加到 contactIdList
        if (init.connectionsId) {
          form.personnelType = 1;
        }
        if (init.comeFrom) {
          form.comeFrom = init.comeFrom;
        }
        if (init.id) {
          todayApi.getMatter('', init.id).then(res => {
            if (res) {
              Object.assign(form, res);
              res.personnelVoList.map(item => {
                form.contactIdList.push(item.id);
              });
              // form.contactIdList = res.personnelVoList;
              if (res.reminderMethodList) {
                form.reminderMethodList = res.reminderMethodList;
              } else {
                form.reminderMethodList = ["SMS"];
              }
            }
          });
        }

        // 新增的脉圈id
        state.connectionsId = init.connectionsId;
        //  人脉圈成员下拉
        todayApi
          .queryContactByName('', {
            connectionsId: state.connectionsId,
          })
          .then(res => {
            if (res) {
              // console.log('res66123', res);
              state.selectNameList = res;
              if (state.selectNameList.length > 0) {
                state.selectNameList.unshift(
                  {
                    id: 'allMembers',
                    name: '全部成员',
                  }
                );
              }
              // console.log('res66state.selectNameList', state.selectNameList);
            }
          });

        // todo 打开弹窗如果人脉圈有只，则往里面 state.selectNameList 添加 一键清除

        //   获取该脉圈下的全部成员 puah form.contactIdList
        todayApi
          .queryContactByConnections('', {
            connectionsId: state.connectionsId,
          })
          .then(res => {
            if (res) {
              // console.log('全部成员', res);
              res.forEach(item => {
                state.allMembers.push(item.id);
              });
            }
          });
      },
      { deep: true }
    );

    // 监听是否人脉圈的值是否有变动
    watch(
      ()=>form.connectionsId,
      (value)=>{
        console.log('value人脉圈值', value)
        // 人脉圈有值则调取
        if(value) {
          // 人脉圈变更需要清空人脉圈成员、清空之前已经选中过的全部成员
          form.contactIdList = [];
          state.allMembers = []
      //   获取该脉圈下的全部成员 puah form.contactIdList
          todayApi
            .queryContactByConnections('', {
              connectionsId: value,
            })
            .then(res => {
              if (res) {
                console.log('全部成员', res);
                res.forEach(item => {
                  state.allMembers.push(item.id);
                });
              }
            });

          //  人脉圈成员下拉
          todayApi
            .queryContactByName('', {
              connectionsId: value,
            })
            .then(res => {
              if (res) {
                console.log('res66123', res);
                // 人脉圈成员下拉
                state.selectNameList = res;
                if (state.selectNameList.length > 0) {
                  state.selectNameList.unshift(
                    {
                      id: 'allMembers',
                      name: '全部成员',
                    }
                  );
                }
                // console.log('res66state.selectNameList', state.selectNameList);
              }
            });

        } else {
          console.log('人脉圈值是空')
          form.contactIdList = [];
          state.allMembers = []

        }

      }

    )

    // 监听人脉圈 form.contactIdList 数组，数组长度>0，才显示一键清除，否则不显示
    // watch(
    //   () => form.contactIdList,
    //   value => {
    //     console.log('valljjj', value, state.selectNameList);
    //     if (state.selectNameList.length > 0 && form.contactIdList.length > 0) {
    //       let ids = [];
    //       state.selectNameList.map(item => {
    //         ids.push(item.id);
    //       });
    //       if (!ids.includes('allEmpty')) {
    //         state.selectNameList.unshift({
    //           id: 'allEmpty',
    //           name: '一键清除',
    //         });
    //       }
    //     } else {
    //       let newList = state.selectNameList.filter(
    //         item => item.id !== 'allEmpty'
    //       );
    //       state.selectNameList = newList;
    //     }
    //   }
    // );
    // 提前提醒时间
    contactAll.getTree('', { code: 'REMINDER_CYCLE' }).then(res => {
      if (res) {
        // console.log('res8888', res);
        state.earlyList = res;
        form.reminderMethodList = ["SMS"];
        // form.reminderCycle = state.earlyList[0].itemCode;
        // state.earlyList.forEach(item => {
        //   if (item.itemCode != 'custom') {
        //     state.spEarlyList.push(item);
        //   }
        // });
      }
    });
    // 提醒方式
    contactAll.getTree('', { code: 'REMINDER_METHOD' }).then(res => {
      if (res) {
        state.remindList = res;
      }
    });

    //
    // todayApi
    //     .queryContactByName('', {
    //     })
    //     .then(res => {
    //       console.log('handleFocus获取焦点', res);
    //       // state.showxiala = true
    //
    //       Object.assign(state.selectNameList, res)
    //     });
    // const colseSift = () => {
    // console.log('点击事件', state.showxiala)
    // if (state.showxiala) {
    //   state.showxiala = state.showxiala;
    //   console.log('点击事件56', state.showxiala)
    // }
    // };

    //获取当前时间
    const getCurrentData = () => {
      const now = new Date();
      const after = new Date(now.getTime() + 60 * 60 * 1000);
      const year = now.getFullYear();
      const month =
        after.getMonth() + 1 >= 10
          ? after.getMonth() + 1
          : '0' + (after.getMonth() + 1);
      const day =
        after.getDate() >= 10 ? after.getDate() : '0' + after.getDate();
      const hour =
        after.getHours() >= 10 ? after.getHours() : '0' + after.getHours();
      // const minute =
      //   now.getMinutes() >= 10 ? now.getMinutes() : '0' + now.getMinutes();
      const minute = '00';
      const second = '00';

      form.reminderTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      form.cycleReminderTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };

    // 处理待办提示时间
    const handleTime = (time) => {
      const year = time.getFullYear();
      const month =
        time.getMonth()  + 1 >= 10
          ? time.getMonth() + 1
          : '0' + (time.getMonth() + 1);
      const day =
        time.getDate() >= 10 ? time.getDate() : '0' + time.getDate();
      const hour =
        time.getHours() >= 10 ? time.getHours() : '0' + time.getHours();
      const minute =
        time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes();
      const second =
        time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds();
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    };

    // 人脉圈下拉列表
    const getData = () => {
      contactAll.queryConnectionsTree('', userId).then(res => {
        if (res) {
          res.map((item)=>{
            item.label = item.connectionsName
            item.disabled = true;
            item.children.map((i)=>{
              i.label = i.connectionsName
            })

          })
          Object.assign(state.treeData, res);
        }
      });
    };

    // // 全局创建一个点击事件:bodyCloseMenus
    onMounted(() => {
      getCurrentData();
      getData()

    });

    onBeforeUnmount(() => {

    });

    const rules = {
      content: [
        {
          required: true,
          message: '请输入待办内容',
          trigger: 'change',
        },
        { max: 200, message: '字符长度不能超过48' },
      ],
      reminderTime: [
        {
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
      connectionsId: [
        {
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
      contactIdList: [
        {
          required: true,
          message: '不能为空',
          // trigger: 'change',
        },
      ],
    };
    const rulesPhone = {
      // required: true,
      // message: 'domain can not be null',
      trigger: 'change',
      validator: async (rule, value) => {
        // console.log('rule', rule, value);
        let maxLength50Regex = /^.{1,40}$/;
        if (!maxLength50Regex.test(value)) {
          return Promise.reject('子待办最多只能输入40个字符');
        }
      },
    };

    const removeDomain = item => {
      let index = form.subItemsList.indexOf(item);
      if (index !== -1) {
        form.subItemsList.splice(index, 1);
      }

      // }
    };

    const addDomain = () => {
      if (form.subItemsList.length === 6) {
        return message.error('最多只可设置6个子待办');
      } else {
        form.subItemsList.push({
          subContent: '',
          key: Date.now(),
          // 待办是否完成 0-未完成 1-已完成
          completed: null,
          reminderTime: null,
        });
      }
    };

    const handleCancel = () => {
      context.emit('fnOk', false);
      formRef.value.resetFields();
      // 新增完成需要清空的
      state.dataList = [];
      form.subItemsList = [];
      form.contactId = '';
      form.connectionsId = '';
      //   关闭自定义弹窗
      closeEarlySelect();
    };

    const closeBoard = () => {
      setTimeout(() => {
        state.showCustomBoard = false;
      }, 100);
    };

    const closeEarlySelect = () => {
      setTimeout(() => {
        state.earlyListOpen = false;
        state.showCustomBoard = false;
      }, 100);
    };

    //点击自定义打开时间选择面板
    const chooseTime = e => {
      console.log('e', e);
      let rect = customBtn.value.$el.getBoundingClientRect();
      state.showCustomBoard = true;
      state.boardTop = rect.bottom - rect.height + 'px';
      state.boardLeft = rect.right + 'px';
    };

    //往提醒时间列表中添加新的选项
    const addEarlyList = () => {
      console.log('state.customTimePick', state.customTimePick);
      if (!state.customTimePick._d) {
        return message.error('请选择自定义时间');
      }
      let hour = state.customTimePick._d?.getHours();
      let min = state.customTimePick._d?.getMinutes();

      let arr = {
        itemCode: 'custom',
        itemNameCn: `提前${state.customTimeDay}天(${
          hour >= 10 ? hour : '0' + hour
        }:${min >= 10 ? min : '0' + min}:00)`,
      };

      form.time = `${hour >= 10 ? hour : '0' + hour}:${
        min >= 10 ? min : '0' + min
      }:00`;

      if (!state.customTimeDay) {
        return message.error('请输入提前天数');
      }

      form.inAdvanceDays = parseInt(state.customTimeDay);
      //将之前添加的自定义时间清除
      state.spEarlyList = state.spEarlyList.filter(item => {
        if (item.itemCode !== 'custom') {
          return item;
        }
      });
      state.spEarlyList.push(arr);
      state.customTimeDay = null;
      state.customTimePick = null;
      closeBoard();
    };
    const handleChange = (option, value) => {
      console.log('handleChange ', value)
      // 人脉圈成员为空时，清除人脉圈分类值
      // if (!value) {
      //   form.connectionsId = null
      // }
      value.map(item => {
        if (item === 'allMembers') {
          form.contactIdList = state.allMembers;
        }
        if (item === 'allEmpty') {
          form.contactIdList = [];
        }
      });
      state.uniqueAry = form.contactIdList.filter(
        item => item !== 'allMembers'
      );
      form.contactIdList = [...new Set(state.uniqueAry)];
    };

    const openContactAddCircle = () => {
      if (form.connectionsId) {
        contactAddCircleShow.value = true;
        state.connectionsId = form.connectionsId
      } else {
        message.info('请先选择人脉圈')
      }

    };

    const handleContactAddCircle = value => {
      contactAddCircleShow.value = value.visible;

      //刷新人脉圈成员下拉
      todayApi
        .queryContactByName('', {
          connectionsId: state.connectionsId,
        })
        .then(res => {
          if (res) {
            // console.log('res66123', res);
            state.selectNameList = res;
            if (state.selectNameList.length > 0) {
              state.selectNameList.unshift({
                id: 'allMembers',
                name: '全部成员',
              });
            }
          }
        });

      //刷新该脉圈下的全部成员 puah form.contactIdList
      todayApi
        .queryContactByConnections('', {
          connectionsId: state.connectionsId,
        })
        .then(res => {
          if (res) {
            // console.log('全部成员', res);
            res.forEach(item => {
              state.allMembers.push(item.id);
            });
          }
        });

      // form.contactIdList
      value.ids.forEach(item => {
        form.contactIdList.push(item);
      });
    };
    const closeContactAddCircle = () => {
      contactAddCircleShow.value = false;
    };

    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };

    // 同一天时间不可选交互提醒
    const getTimeNone = (itemCode) => {
      const now = new Date();
      // console.log('now', now, new Date(form.reminderTime))
      if (form.reminderTime && moment(now).isSame(form.reminderTime, 'day')) {
        // 同一天时提前一天和提前三天不可选
        if (itemCode === '1440' || itemCode === '4320') {
          return message.error('提醒时间已发生请重新选择')
        } else {
          // 同一天, 当前时间 start 和 end 结束时间：即待办时间
          // 在当前时间段内可以点击，否则不能点击
          let time = new Date(form.reminderTime);
          // 快捷选择 待办提醒时间
          let addMinute = new Date(time.setMinutes(time.getMinutes() - parseInt(itemCode)));
          let nowTimestamp = now.getTime();
          let timeTimestamp = time.getTime();
          let addMinutestamp = addMinute.getTime();

          // 快捷选择时间大于当前时间，并且少于待办时间
          if (addMinutestamp > nowTimestamp && addMinutestamp <= timeTimestamp ) {
            form.cycleReminderTime = handleTime(addMinute)
            form.reminderCycle = itemCode
          } else {

            // 准时可选
            if (timeTimestamp === addMinutestamp) {
              form.reminderCycle = itemCode
            } else {
              return message.error('提醒时间已发生请重新选择')
            }


          }

        }
      } else {

        let time = new Date(form.reminderTime);
        // 快捷选择 待办提醒时间
        let addMinute = new Date(time.setMinutes(time.getMinutes() - parseInt(itemCode)));
        form.cycleReminderTime = handleTime(addMinute)

        form.reminderCycle = itemCode

      }

    };

    return {
      container,
      ...toRefs(state),
      form,
      rules,
      rulesPhone,
      formRef,
      customBtn,
      handleCancel,
      removeDomain,
      addDomain,
      closeBoard,
      closeEarlySelect,
      chooseTime,
      addEarlyList,
      handleChange,
      contactAddCircleShow,
      openContactAddCircle,
      handleContactAddCircle,
      closeContactAddCircle,
      range,
      disabledDate: current => {
        // moment().subtract(1, 'days').endOf('day');    moment().endOf('day');
        return current && current < moment().subtract(1, 'days').endOf('day');
      },
      disabledCycleDate: current => {
        let reminderTime = moment(form.reminderTime);
        return current < moment() || current > reminderTime;
      },
      disabledCycleDateOther: current => {
        let reminderTime = moment(form.reminderTime);
        return current < moment().subtract(1, 'day') || current > reminderTime;
      },
      // 提醒时间时分秒限制
      disabledCycleTime: () => {
        const now = new Date();
        let h = 23;
        let m = 60;
        let s = 60;
        if (form.reminderTime && moment(now).isSame(form.reminderTime, 'day')) {
          h = moment(form.reminderTime).hour(); //同一天 禁用小时
          if (moment(form.cycleReminderTime).hour() === moment(form.reminderTime).hour()) {
            m = moment(form.reminderTime).minute(); //同一小时 禁用分钟的选择
            if (moment(form.cycleReminderTime).minute() === moment(form.reminderTime).minute()) {
              s = moment(form.reminderTime).second(); //同一分钟 禁用秒的选择
            }
          }
        }

        return {
          disabledHours: () => range(h + 1, 24),
          disabledMinutes: () => range(m + 1, 60),
          disabledSeconds: () => range(s, 60),
        };
      },
      // 待办时间时分秒限制
      disabledReminderTime: () => {
        const now = new Date();
        let h = 23;
        let m = 60;
        let s = 60;
        // 同一天的时间禁用 待办时间跟当前时间对比
        if (form.reminderTime && moment(now).isSame(form.reminderTime, 'day')) {
          h = moment(now).hour(); //同一天 禁用小时

          if (moment(now).hour() === moment(form.reminderTime).hour()) {
            m = moment(now).minute(); //同一小时 禁用分钟的选择
            if (moment(now).minute() === moment(form.reminderTime).minute()) {
              s = moment(now).second(); //同一分钟 禁用秒的选择
            }
            return {
              disabledHours: () => range(0, h),
              disabledMinutes: () => range(0, m),
              disabledSeconds: () => range(s, 60),
            };

          } else {
            // 不同一小时分钟处理
            return {
              disabledHours: () => range(0, h),
              disabledMinutes: () => range(m, 60),
              disabledSeconds: () => range(s, 60),
            };
          }
        }

      },

      handleOk: () => {
        // 保存之前需要把 state.dataList 处理成 数组中只是含 id, 放在 form.contactIdList
        // state.dataList.map(item => {
        //   if (!form.contactIdList.includes(item.id)) {
        //     form.contactIdList.push(item.id);
        //   }
        // });
        if (form.contactIdList.length === 0) {
          return message.error('请选择人脉圈和人脉圈成员');
        }

        form.subItemsList = form.subItemsList.filter(
          item => item.subContent !== ''
        );
        if (form.cycleReminderTime?.split(':').length === 2) {
          form.cycleReminderTime += ':00';
        }
        if (form.reminderTime?.split(':').length === 2) {
          form.reminderTime += ':00';
        }
        form.subItemsList.forEach(item => {
          if (item.reminderTime && item.reminderTime.split(':').length === 2) {
            item.reminderTime += ':00';
          }
        });

        // 保存需要校验提醒时间是否有效
        const now = new Date();
        let time = new Date(form.reminderTime);
        let cycleReminderTime = new Date(form.cycleReminderTime)
        // 快捷选择 待办提醒时间
        let nowTimestamp = now.getTime();
        let timeTimestamp = time.getTime();
        let cycleReminderTimetamp = cycleReminderTime.getTime();

        // 快捷选择时间大于当前时间，并且少于等于待办时间
        if (!(cycleReminderTimetamp > nowTimestamp && cycleReminderTimetamp <= timeTimestamp) ) {
          return message.error('提醒时间已发生请重新选择')
        }

        formRef.value.validateFields().then(() => {
          if (state.loading) return;
          state.loading = true;

          let type = 'addContactTodoMatter';
          form.id && (type = 'updataTodoMatter');
          // 新增待办
          todayApi[type]('', {
            userId: userId,
            ...form,
            personnelType: 1,
            // 脉圈id
            connectionsId: form.connectionsId,
          })
            .then(res => {
              if (res) {
                // console.log('res89', res)
                state.loading = false;
                // console.log('res', res);
                context.emit('fnOk', false);
                context.emit('fnCricleTodo', false);
                formRef.value.resetFields();
                getCurrentData();
                //   关闭自定义弹窗
                closeEarlySelect();
                if (form.id) {
                  message.success('编辑待办成功');
                  //  编辑刷新人脉圈待办列表
                  proxy.$bus.emit('crEditPage', true);
                } else {
                  message.success('新增待办成功');
                  proxy.$bus.emit('crEditPage', true);
                }

                if (form.comeFrom === 'crDetail') {
                  //  刷新人脉圈详情待办列表
                  proxy.$bus.emit('getCReminderPage', true);
                }
                //  刷新today页面、全局页面列表
                proxy.$bus.emit('getAddData', true);
                // 刷新全局待办列表
                proxy.$bus.emit('getAddSchedule', true);
                // 刷新联系人待办列表
                proxy.$bus.emit('getReminderPage', true);

                form.contactIdList = [];
                // 新增完成需要清空的
                state.dataList = [];
                form.subItemsList = [];
                state.uniqueAry = [];
                state.allMembers = [];
              }else {
                state.loading = false;
              }
            })
            .catch(() => {
              state.loading = false;
            });
        });
      },
      deleteName(index) {
        console.log('deleteName', index);
        state.dataList.splice(index, 1);
      },

      handleFilterOption(inputValue, option) {
        return option.key.itemNameCn.indexOf(inputValue) >= 0;
      },
      handleFilterOptionT(inputValue, option) {
        // console.log('dddd', inputValue, option)
        return option.key.name.indexOf(inputValue) >= 0;
      },

      //  选择待办提醒时间
      handleItems(item) {
        if(item.itemCode === 'not_remind') {
          // 不提醒情况提醒方式
          form.reminderMethodList =  []
          form.reminderCycle = item.itemCode
        } else {
          // 这里处理时间不可选的交互提醒
          getTimeNone(item.itemCode)
        }
      },
      handleChangeReminderTime (value) {
        if (value) {
          if (form.reminderCycle && form.reminderCycle !== 'not_remind') {
            let time = new Date(value);
            let addMinute = new Date(time.setMinutes(time.getMinutes() - parseInt(form.reminderCycle)));
            form.cycleReminderTime = handleTime(addMinute)
          } else {
            form.cycleReminderTime = value
          }
        }
      },
      cycleReminderTimeChange(value) {
        if (value) {
          form.reminderCycle = ''
          form.cycleReminderTime = value
        }
      },

    };
  },
};
</script>
<style lang="less" scoped>
.tabsRight {
  height: 550px;
}
.tabsRight /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}
.content {
  //max-height: calc(100vh - 356px);
  //max-height: calc(100vh - 321px);
  //overflow-y: auto;
  height: 475px;
  overflow: auto;
  margin-top: 10px;
}
.listClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 26px;
  border-bottom: 0.5px solid #eeeeee;
  .itemOne {
    // .name {
    // }
    .jinbi {
      margin-top: 8px;
    }
  }
  .itemTwo {
    width: 18%;
    text-align: right;
  }
}

.noneList {
  margin-top: 100px;
  text-align: center;
  color: #bfbfbf;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 0px;
  font-size: 13px;
  //color: #999;
  color: #ff7b00;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #ff7b00;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.subItemBut /deep/ .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 5px;
}

.contactsTodo {
  padding: 0px 24px;
  height: 526px;
  overflow: auto;
  margin-top: 15px;
}
.colCalss {
  margin-left: 20px;
}
.contactsTodo /deep/ .ant-modal-footer {
  padding: 14px 24px 24px 24px;
  //padding: 24px;
}
.dataListClass {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-top: 16px;
  max-height: 68px;
  overflow: auto;
  //.nameList {

  .name {
    // width: 88px;
    height: 24px;
    border-radius: 4px;
    //background: #FFFFFF;
    background: #fffaf6;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 8px 8px 0px;
    gap: 4px;
  }

  //}
}

.nameListNone {
  display: none;
}

.formItemClass {
  position: relative;
}

.namename {
  max-height: 164px;
  width: 515px;
  overflow: auto;
  position: absolute;
  top: 24px;
  z-index: 2;
  margin-top: 16px;
  border: 1px solid #eeeff5;
  background: #fff;
  border-radius: 4px;
  .selectList {
    .selectName {
      height: 40px;
      //border-radius: 4px;
      background: #f5f6fa;
      font-size: 12px;
      line-height: 20px;
      //padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0px 8px 8px 0px;
      //padding: 10px 16px;
      cursor: pointer;
      padding-left: 16px;
      &:hover {
        background: #fffaf6;
      }
    }
  }
}
.custom-btn {
  width: 100%;
  position: relative;
  text-align: left;
}

.custom-row {
  width: 100%;
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.custom-board {
  width: 180px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: v-bind(boardTop);
  left: v-bind(boardLeft);
  z-index: 1001;
  // transform: translate(-100%, -100%);

  .custom-row {
    justify-content: space-between;
  }
}
.dropdownRenderSel /deep/.rc-virtual-list {
  max-height: 164px;
  overflow: auto;
}
.custom-btn /deep/ .ant-btn {
  padding: 4px 90px;
}

/deep/ .ant-btn {
  font-size: 12px;
}

.time-tip-text {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}

.blessingText {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 16px;
  //flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  .textLabel {
    color: #ff7b00;
    width: 30%;
  }
  .tR {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
  }
  .blessing {
    background: #fffaf6;
    border-radius: 4px;
    padding: 4px 8px;
    //margin-right: 16px;
  }
  .itemsBel {
    //background: #fffaf6;
    border-radius: 4px;
    padding: 4px 8px;
    //margin-right: 16px;
    color: #999999;
    border: 0.5px solid #EEEEEE;
    background: rgba(238, 238, 238, 0.1);
  }
}
.showBor {
  border: 0.5px solid #ff7b00;
  color: #ff7b00 !important;
  background: #fffaf6 !important;
}

.contactsTodo /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none;
}
.contactsTodo /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}
.phoneCalssRight /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}

.phoneCalssRight /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none !important;
}
.contactsTodo /deep/ .butClass{
  text-align: right;
  padding-bottom: 20px;
}
.okClass {
  margin-left: 20px;
}
// 全局的
:global(.ant-select-dropdown) {
  max-height: 200px !important;
  overflow: auto !important;
}
//:global(.ant-select-tree-title) {
//  color: #1d2129 !important;
//}

//.contactsTodo /deep/ li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher), li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper, li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
//  color: #1d2129 !important;
//
//}

//:global (li.ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper span) {
//  color: #1d2129 !important;
//
//}

</style>
