'use strict';

import http from '@/utils/http';
import { getFormData } from '@/utils';
import { validMobile, validEmail } from '@/utils/validation';

export default {
  getCaptcha(randomId) {
    return http
      .get(`/authority/anno/captcha?key=${randomId}`, {
        responseType: 'arraybuffer',
      })
      .then(
        data =>
          `data:image/png;base64,${btoa(
            String.fromCharCode(...new Uint8Array(data))
          )}`
      );
  },
  login(data) {
    return http.post('/authority/anno/login', getFormData(data));
  },
  // 退出登录
  logout(code, params) {
    return http.get('/connections_treasure_box/sys-user/logout', {
      params,
      code,
    });
  },
  getTenant(params) {
    return http.get('/authority/anno/getTenant', { params });
  },
  // 获取验证码接口    /connections_treasure_box/sys-user/addSysUser
  getCode(data) {
    if (validMobile(data)) {
      return http.post('/connections_treasure_box/message/sendVerifyCode', {
        phone: data,
        // sendChannel: 'SMS',
        areaCode: '86',
      });
    }
    if (validEmail(data)) {
      return http.post('/issmart-messages/message/sendVerifyCode', {
        email: data,
        // sendChannel: 'EMAIL',
      });
    }
  },
  verifyCode({ account, code }) {
    if (validMobile(account)) {
      return http.post('/issmart-messages/message/checkVerifyCode', {
        phone: account,
        verifyCode: code,
      });
    }
    if (validEmail(account)) {
      return http.post('/issmart-messages/message/checkVerifyCode', {
        email: account,
        verifyCode: code,
      });
    }
  },
  verifyAccount(data) {
    let temp = {};
    if (validMobile(data)) {
      temp.mobile = data;
    } else if (validEmail(data)) {
      temp.email = data;
    }
    return http.post('/authority/user/judgment/emailOrPhone', temp);
  },
  //  注册账号
  addSysUser(code, data) {
    return http.post('/connections_treasure_box/sys-user/addSysUser', data, {
      code,
    });
  },
  //   账号登录
  loginByAccount(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/loginByAccount',
      data,
      { code }
    );
  },
  // 验证码登录
  loginByVerifyCode(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/loginByVerifyCode',
      data,
      { code }
    );
  },
  // 重置密码
  resetPassword(code, data) {
    return http.post('/connections_treasure_box/sys-user/resetPassword', data, {
      code,
    });
  },
  // 修改密码
  updateUserPassword(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/updateUserPassword',
      data,
      {
        code,
      }
    );
  },

  //微信授权后code验证
  qrcodeLogin(code, params) {
    return http.get(
      `/connections_treasure_box/sys-user/wxAppAuthorize/${params.code}/${params.createTime}`
    );
  },

  //微信扫码登录绑定手机号
  qrcodeBindPhone(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/wxAppAuthorizeAndBindPhone',
      data,
      {
        code,
      }
    );
  },

  //微信登录后绑定用户信息
  qrcodeBindMessage(code, data) {
    return http.put(
      '/connections_treasure_box/sys-user/wxScanAuthorizeBindPassword',
      data,
      { code }
    );
  },

  //解绑微信
  UnbindWeChat(code, params) {
    return http.get(
      `/connections_treasure_box/sys-user/UnbindWeChat/${params.userId}`
    );
  },

  //设置绑定微信
  bindWeChatIdentity(code, params) {
    return http.get(
      `/connections_treasure_box/sys-user/bindWeChatIdentity/${params.code}/${params.userId}`
    );
  },
  // 通过邀请码获取邀请人信息
  getInviterInfo(data) {
    return http.get(
      `/connections_treasure_box/sys-user/invitationCode/${data.code}`
    );
  },
//   登录+注册
  addAndRegister(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/addAndRegister',
      data,
      { code }
    );
  },
//  登录+注册 赠送金币
  addUserAndLoginAndSendGold(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/addUserAndLoginAndSendGold',
      data,
      { code }
    );
  },


};
