'use strict';

import http from '@/utils/http';

export default {
  // 获取导入状态
  getContactImportSituation(code, params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getContactImportSituation',
      { params, code }
    );
  },

  // 查询首页展示数据
  getHomeShowData(code, params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getHomeShowData',
      { params, code }
    );
  },
  //   待办列表 /connections_treasure_box/contact/contact/page
  // '/connections_treasure_box/contact/contact-todo-matter/recentlyTodoPage',
  recentlyTodoPage(code, data) {
    return http.post('/connections_treasure_box/contact/contact/page', data, {
      code,
    });
  },

  //   编辑待办
  updateReminder(code, data) {
    return http.put(
      '/connections_treasure_box/contact/contact-todo-matter',
      data,
      { code }
    );
  },

  // 待办编辑-按名字查询联系人 -联系人下拉
  queryContactByName(code, params) {
    return http.get(
      '/connections_treasure_box/contact/contact/queryContactByName',
      { params, code }
    );
  },
  // 待办-查询脉圈所有联系人
  queryContactByConnections(code, params) {
    return http.get(
      '/connections_treasure_box/contact/contact/queryContactByConnections',
      { params, code }
    );
  },

  // 查询指定子待办信息
  queryTodoSubItem(code, params) {
    return http.get(
      `/connections_treasure_box/contact/contact-todo-sub-items/${params.id}`,
      { code }
    );
  },

  //  新增待办
  addContactTodoMatter(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-todo-matter',
      data,
      {
        code,
      }
    );
  },

  //  updata待办
  updataTodoMatter(code, data) {
    return http.put(
      '/connections_treasure_box/contact/contact-todo-matter',
      data,
      {
        code,
      }
    );
  },

  //   分页查询联系人待办事项--脉圈和联系人详情
  contactTodoMatterPage(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact-todo-matter/page?current=${data.current}&size=${data.size}`,
      data,
      {
        code,
      }
    );
  },
  // 最近待办和今天页面的最近待办分页
  todoPage(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact-todo-matter/recentlyTodoPage?current=${data.current}&size=${data.size}`,
      data,
      {
        code,
      }
    );
  },
  // 今天页面查询最近生日联系人（今天）
  getContactByRecentBirthday(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact/getContactByRecentBirthday?current=${data.current}&size=${data.size}`,
      data,
      {
        code,
      }
    );
  },
  // 查询最近联系的联系人（今天页面）
  getContactRecentlyContacted(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact/getContactRecentlyContacted?current=${data.current}&size=${data.size}`,
      data,
      {
        code,
      }
    );
  },

  // 标记子待办事项已完成-未完成
  markTodoSubItemsCompleted(code, data) {
    return http.put(
      '/connections_treasure_box/contact/contact-todo-sub-items/markTodoSubItemsCompleted',
      data,
      { code }
    );
  },

  // 标记主待办事项已完成-未完成
  markTodoCompleted(code, data) {
    return http.put(
      '/connections_treasure_box/contact/contact-todo-matter/markTodoCompleted',
      data,
      { code }
    );
  },
  //   删除主待办
  delteMatter(code, params) {
    return http.delete(
      '/connections_treasure_box/contact/contact-todo-matter',
      { code, params }
    );
  },
  // 查询联系人实体
  getMatter(code, id) {
    return http.get(
      `/connections_treasure_box/contact/contact-todo-matter/${id}`,
      { code }
    );
  },
  //   生日页面的接口
  getContactByAllBirthday(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact/getContactByAllBirthday`,
      data,
      {
        code,
      }
    );
  },

  // 今天页面生日联系人的勾待办事项已完成-未完成
  markTodoCompletedByContact(code, data) {
    return http.put(
      'connections_treasure_box/contact/contact-todo-matter/markTodoCompletedByContact',
      data,
      { code }
    );
  },

  //  today页面 保持联系本周接口
  getContactRecentlyCurrentDate(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact/getContactRecentlyList`,
      data,
      {
        code,
      }
    );
  },

  //  today页面 保持联系下周接口
  getContactRecentlyNextDate(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact/getContactRecentlyList`,
      data,
      {
        code,
      }
    );
  },

  //  today页面 保存联系人生日记录表
  birthdayRecords(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact-birthday-records`,
      data,
      {
        code,
      }
    );
  },
  // 保存联系人频次忽略记录表实体
  contactFrequencyRecords(code, data) {
    return http.post(
      `/connections_treasure_box/contact/contact-frequency-records`,
      data,
      {
        code,
      }
    );
  },
//   获取最新的版本信息
  getLatestVersionInfo(code, params) {
    return http.get(
      '/connections_treasure_box/system/app-version-info/getLatestVersionInfo',
      { params, code }
    );
  },
//   获取用户信息
  getUserAvatarAndName(code, params) {
    return http.get(
      '/connections_treasure_box/sys-user/getUserAvatarAndName',
      { params, code }
    );
  },

  saveWebsiteBatchVisitRecords(code, params) {
    return http.get(
      '/connections_treasure_box/system/website-batch-visit-records/saveWebsiteBatchVisitRecords',
      { params, code }
    );
  },

};
