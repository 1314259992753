<template>
  <div class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      :title="form.addOrEdit !== 'add' ? '编辑待办' : '新增待办'"
      :mask-closable="false"
      :width="564"
      :footer='false'
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
      :getContainer="() => container"
      class="issModal"
    >
      <div class="tabsClass">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" tab="联系人待办">
            <contacts-todo
              :init="form"
              @fnContactsTodo='handleContactsTodo'
            />
          </a-tab-pane>

          <a-tab-pane key="2" tab="人脉圈待办" force-render>
            <cricle-todo
              :init='form'
              @fnCricleTodo='handleCricleTodo'
            />
          </a-tab-pane>
        </a-tabs>
      </div>

    </a-modal>

  </div>
</template>
<script>
import {
  reactive, ref,
  toRefs, watch
} from 'vue';
import {
  Tabs
} from 'ant-design-vue';
import contactsTodo from '@/views/today/homePage/components/contactsTodo';
import cricleTodo from '@/views/today/homePage/components/cricleTodo';
import todayApi from '@/api/today';
import { useStore } from 'vuex';
import { mergeAndUnique } from '@/utils';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    contactsTodo,
    cricleTodo,

  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const container = ref();
    const store = useStore();
    const state = reactive({
      activeKey: '1',

    });
    const form = reactive({
      content: '',
      leadPool: [],
      reminderTime: null,
      lastInteractionTime: '',
      cycleReminderTime: null,
      // 自定义准时
      inAdvanceDays: null,
      time: '',
      // 人员类型 0-个人 1-脉圈
      personnelType: null,
      contactIdList: [],
      connectionsId: '',
      contentName: [],
      // 子待办数组
      subItemsList: [],
      type: '',
      dataType: '',
      // 主待办id
      todoId: '',
      userId: store.state.account.user.id,
      reminderMethodList: [],
      inputValue: '',
      // 联系人详情页面新增需要传
      contactId: '',
      reminderCycle: '0',
      addOrEdit: '',
      comeFrom: '',
    });

    watch(
      () => props.init,
      init => {
        console.log('init新待办555', init)

        form.addOrEdit = init.type;
        // console.log('新增编辑待办', init, state.addOrEdit);
        // 在联系人详情或者脉圈详情中添加得待办，需要拿到当下联系人id或脉圈详情id添加到 contactIdList
        if (init.contactId) {
          // form.personnelType = 0;
          form.contactId = init.contactId[0];
        }
        // 出来来自不同页面的新增联系人详情、待办主页面
        // contact  联系人  schedule 最近待办  backlog 待办主页面
        if (init.comeFrom) {
          form.comeFrom = init.comeFrom;
        }

        if (init.id) {
          // 在今天页面进来的新增和编辑的处理
          if (init.type === 'add') return (state.dataList = [init]);

          todayApi.getMatter('', init.id).then(res => {
            if (res) {
              Object.assign(form, res);
              state.dataList = res.personnelVoList;
              if (res.reminderMethodList) {
                form.reminderMethodList = res.reminderMethodList;
              } else {
                form.reminderMethodList = [];
              }
            }
          });
        }
      },
      { deep: true }
    );

    // 定义好进来的人脉圈待办进来的途径
    // 全局待办 backlog、最近待办 schedule，顶部加号 dingbuTodo、创建待办


    // 联系人待办
    const handleContactsTodo = (value) => {
      context.emit('fnOk', value);

    };

    // 人脉圈待办
    const handleCricleTodo = (value) => {
      context.emit('fnOk', value);

    };

    const handleCancel = () => {
      context.emit('update:visible', false);
    };

    return {
      container,
      form,
      ...toRefs(state),
      handleCancel,
      handleContactsTodo,
      handleCricleTodo,


    };
  },
};
</script>
<style lang="less" scoped>

.modalClass /deep/ .ant-modal-body {
  //height: 580px;
  //height: 526px;
  //overflow: auto;
  padding: 0px;
}

.modalClass /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none;
}
.modalClass /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}
.phoneCalssRight /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}

.phoneCalssRight /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none !important;
}

.tabsClass /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 9px;
  border-bottom: none;
}

</style>
