<template>
  <div class="header-search">
    <!-- <a-input-search
      class="mr-8"
      v-model:value="searchVal"
      placeholder="请输入内容"
      @search="handleSearch"
      allow-clear
    /> -->
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';

export default {
  setup() {
    const state = reactive({
      searchVal: '',
    });
    const handleSearch = () => {
      console.log(state.searchVal);
    };
    return {
      ...toRefs(state),
      handleSearch,
    };
  },
};
</script>

<style lang="less" scoped></style>
