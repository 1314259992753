'use strict';

import http from '@/utils/http';

export default {
  getList(params) {
    return http.get(
      '/connections_treasure_box/contact/groups/getContactGroupsListDetail/' +
        params.userId
    );
  },
  add(data) {
    return http.post('/connections_treasure_box/contact/groups', data);
  },
  edit(data) {
    return http.put('/connections_treasure_box/contact/groups', data);
  },

  delete(data) {
    return http.post(
      '/connections_treasure_box/contact/groups/deleteGroupAndSon',
      data
    );
  },
};
