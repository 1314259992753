<template>
  <div class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      :footer="null"
      :width="900"
      okText="确认"
      :destroyOnClose="true"
      @ok="editPeople"
      :getContainer="container"
      @cancel="handleCancel"
      :bodyStyle="{
        background:
          'linear-gradient(0deg, rgba(253, 246, 236, 0.5) 0%, rgba(253, 246, 236, 0) 63%);',
      }"
    >
      <div class="welcome-modal">
        <div class="welcome-modal-l">
          <div class="welcome-modal-title">
            <ScanOutlined /><span class="pl-8 fs-24">扫码下载APP</span>
          </div>
          <div class="welcome-modal-text">一键同步手机联系人</div>
            <!--  app安装链接    -->
          <div class="qrCode qrCodeT">
            <img
              src="@/assets/images/dashboard/qrcodeBg.png"
              class="qrBiankuang"
            />
            <!-- 这里放置二维码 -->
            <vue-qr
              :text="downloadUrl"
              :margin="0"
              :size="150"
              class="vueQr"
            />

          </div>

        </div>
        <div class="welcome-modal-r">
          <img src="@/assets/images/openApp.png" alt="" class="openApp" />
          <div class="butClass cursor-p" @click="gotoRoute">整理联系人</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, toRefs, reactive } from 'vue';
import { ScanOutlined } from '@ant-design/icons-vue';
import dashboardApi from '@/api/dashboard.js';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import todayApi from '@/api/today';
import VueQr from 'vue-qr/src';

export default {
  components: {
    ScanOutlined,
    VueQr,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const container = ref();
    const store = useStore();
    // const loading = ref(false);
    const router = useRouter();
    // const intervalId = ref(true);
    const state = reactive({
      cloudstate: null,
      downloadUrl: '',
    });

    // const getLoadingStatus = () => {
    //   dashboardApi
    //     .getStatus({
    //       userId: store.state.account.user.id,
    //     })
    //     .then(res => {
    //       if (res) {
    //         if (res === 'start' || res === 'importing') {
    //           loading.value = true;
    //           // state.visible = false;
    //         } else if (res === 'complete') {
    //           // clearInterval(intervalId.value);
    //           // console.log('定时器已清除22');
    //           context.emit('fnOk', false);
    //           router.push({
    //             path: 'dashboard',
    //             query: {
    //               importStatus: 0,
    //             },
    //           });
    //         }
    //       }
    //     });
    // };

    // dashboardApi
    //     .setBehaviorPage({
    //       userId: store.state.account.user.id,
    //       importSituation: 0,
    //       pageName: 'welcome',
    //     })
    //     .then(res => {
    //       if (res) {
    //         console.log('45646465', res)
    //       }
    //     });

    // 获取最新app版本信息
    const getLatestVersionInfo = () => {
      todayApi
        .getLatestVersionInfo('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            state.downloadUrl = res.downloadUrl

          }
        });
    };


    // 查询导入状态
    const getImportSituation = () => {
      todayApi
        .getContactImportSituation('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
          if (res === 'untreated') {
            // router.push('/today');
            getBehaviorPage();
          } else if (res === 'Novice') {
            // router.push('/welcome');
            getBehaviorPage();
          } else if (res === 'incomplete') {
            // 未完成有存储页面就去存储页面,没有存储页面就去
            getBehaviorPage();
          } else if (res === 'complete') {
            // router.push('/today');
            getBehaviorPage();
          }
        });
    };

    const getBehaviorPage = () => {
      //  获取用户导入行为页面信息
      dashboardApi
        .getBehaviorPage({
          userId: store.state.account.user.id,
          importSituation: 0,
        })
        .then(res => {
          // 有数据就去我设置的页面，否去dashboard页面
          if (res) {
            // console.log('res66弹窗打开', res);
            router.push({
              path: res,
              query: {
                importStatus: 0,
              },
            });
          } else {
            router.push({
              path: '/dashboard',
              query: {
                importStatus: 0,
              },
            });
          }
        });
    };

    onMounted(() => {
      // intervalId.value = setInterval(() => {
      //   console.log('定时器正在运行...');
      //   // getLoadingStatus();
      // }, 5000);
      getLatestVersionInfo();

    });
    // 在组件销毁之前清除定时器
    onBeforeUnmount(() => {
      // if (intervalId.value) {
      //   clearInterval(intervalId.value);
      //   console.log('定时器已清除');
      // }
    });

    const handleCancel = () => {
      // clearInterval(intervalId.value);
      context.emit('fnOk', false);
    };
    // const handleInvitLine = line => {
    //   // downloadUrl: "https://ismartek-app-uat.issmart.com.cn/apk/ctb/test/v1.1.1_test.apk"
    //   // let line =
    //   //   process.env.VUE_APP_SERVER_LOGIN +
    //   //   `?inviterCode=${invitationCode}`;
    //   return line;
    // };


    return {
      container,
      ...toRefs(state),
      handleCancel,
      // handleInvitLine,
      // intervalId,
      gotoRoute() {
        context.emit('fnOk', false);
        // if (intervalId.value) {
        //   clearInterval(intervalId.value);
        //   console.log('定时器已清除');
        // }
        // router.push('/dashboard?importStatus=0');
        // 查询导入状态
        getImportSituation();
        // getBehaviorPage()
      },
    };
  },
};
</script>

<style scoped lang="less">
.welcome-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    width: 50%;
  }
  .welcome-modal-l {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -60px;
    .welcome-modal-title {
      font-size: 24px;
      font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
      font-weight: 700;
      color: #60473a;
      line-height: 24px;
    }
    .welcome-modal-text {
      font-size: 18px;
      font-family: Source Han Sans-Regular, Source Han Sans;
      font-weight: 400;
      color: #60473a;
      line-height: 24px;
      letter-spacing: 4px;
      margin: 24px 0 52px;
    }
    .qrcode-bg {
      padding: 10px;
      //background: url('../../../assets/images/dashboard/qrcodeBg.png') no-repeat
      //  center center;
      //background-size: cover;
      img {
        //width: 100%;
        width: 180px;
      }
      .vueQr {

      }
    }
  }

  .welcome-modal-r {
    border-left: 1px solid #eeeeee;
    padding-left: 50px;
    img {
      width: 100%;
    }
    .openApp {
      margin-top: 36px;
      margin-bottom: 20px;
    }
    .butClass {
      padding: 10px 54px;
      background: linear-gradient(270deg, #fdbc25 0%, #ff8e31 37%, #ff7b00 86%);
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      //width: 53%;
      margin-left: 80px;

      position: absolute;
      top: 416px;
      right: 115px;
      width: 188px;
    }
  }.qrCode {
     position: relative;
       .qrBiankuang {
         margin: 32px 0px;
         width: 180px;
       }

       .vueQr {
         width: 150px;
         right: 12px;
         position: absolute;
         top: 48px;
       }

   }
}
</style>
