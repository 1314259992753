<template>
  <div class="modalClass" ref="container">
    <a-modal
      title="新增记录"
      :mask-closable="false"
      :width="564"
      :footer='false'
      centered
      forceRender
      :destroyOnClose="true"
      :getContainer="() => container"
      class="issModal"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="tabsClass">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" tab="联系人记录">
            <add-contact-records
              :initValue="formData"
              @fnContactsTodo='handleContactsTodo'
            />
          </a-tab-pane>

          <a-tab-pane key="2" tab="人脉圈记录" force-render>
            <add-pulse-recording
              :initValue='formData'
              @fnCricleTodo='handleCricleTodo'
            />
          </a-tab-pane>
        </a-tabs>
      </div>

    </a-modal>

  </div>
</template>
<script>
import {
  reactive, ref,
  toRefs, watch
} from 'vue';
import {
  Tabs
} from 'ant-design-vue';
import addContactRecords from '@/views/timeline/components/addContactRecords';
import addPulseRecording from '@/views/timeline/components/addPulseRecording';
// import { useStore } from 'vuex';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    addContactRecords,
    addPulseRecording

  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const container = ref();
    // const store = useStore();
    const state = reactive({
      activeKey: '1',
      initVal: {},

    });
    const formRef = reactive({});
    const formData = reactive({
      title: '',
      content: '',
      contactIdList: [],
      recordDate: '',
      recordType: undefined,
      fileList: [],
      comeFrom: '',
      type: '',

    });
    watch(
      () => props.init,
      init => {
        if (init) {
          console.log('init新记录', init)
          formData.comeFrom = init.comeFrom
          formData.type = init.type

        }

      },
      { deep: true }
    );

    // 定义好进来的人脉圈待办进来的途径
    // 全局待办 backlog、最近待办 schedule，顶部加号 dingbuTodo、创建待办


    // 联系人待办
    const handleContactsTodo = (value) => {
      context.emit('fnOk', value);

    };

    // 人脉圈待办
    const handleCricleTodo = (value) => {
      context.emit('fnOk', value);

    };

    const handleCancel = () => {
      context.emit('fnOk', false);
    };

    return {
      container,
      formRef,
      formData,
      ...toRefs(state),
      handleCancel,
      handleContactsTodo,
      handleCricleTodo,


    };
  },
};
</script>
<style lang="less" scoped>

.modalClass /deep/ .ant-modal-body {
  //height: 580px;
  //height: 526px;
  //overflow: auto;
  padding: 0px;
}

.modalClass /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none;
}
.modalClass /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}
.phoneCalssRight /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}

.phoneCalssRight /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none !important;
}

.tabsClass /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 9px;
  border-bottom: none;
}

</style>
