'use strict';

export default {
  namespaced: true,
  state: {
    enums: null,
    apps: null,
    breadcrumbs: null,
    loading: true,
    isShowSift: null,
    selectedRowsData: [],
  },
  mutations: {
    setEnums(state, value) {
      state.enums = value;
    },
    setApps(state, value) {
      state.apps = value;
    },
    setBreadcrumbs(state, value) {
      state.breadcrumbs = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setSelectedRows(state, value) {
      state.selectedRowsData = value;
    },
    setIsShowSift(state, value) {
      state.isShowSift = value;
    },
  },
};
