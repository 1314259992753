<template>
  <div>
    <div class="contact-list" v-if="!circleId">
      <a-button @click="openContactAdd">创建联系人</a-button>
      <!--     todo 一键添加全部联系人   -->
      <a-button @click="addAllContacts" type="primary" class='ml-10'>批量添加联系人</a-button>

      <!--      下拉选择中才显示在这里    -->
      <div class="dataListClass">
        <div
          v-if="dataList?.length > 0"
          @click="handleEmpty()"
          class="cursor-p empty"
        >
          一键清空
        </div>
        <div v-for="(item, index) in dataList" :key="item.id">
          <div class="name">
            <!--              孙大松 -->
            {{ item.name }}
            <CloseOutlined @click="deleteName(index)" />
          </div>
        </div>
      </div>
      <a-form-item label="" class="formItemClass">
        <!--       输入框输入值，调取接口，获取数据，选择数据是个list,然后遍历list展示   -->
        <a-input
          @click.stop
          v-model:value="inputValue"
          placeholder="请添加联系人"
          @change="handleChange(inputValue)"
          @focus="handleFocus()"
        >
        </a-input>
        <!-- 这里需要展示下拉  :class="{selectListtNone: form.contactIdList.length > 0}"  -->

        <div v-if="showxiala" class="namename" ref="selectBox">
          <div v-for="i in selectNameList" :key="i.id">
            <div class="selectList">
              <div class="selectName" @click="selectName(i)">
                {{ i.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="showEmpty" class="namename" ref="selectBox">
          <a-empty :image="simpleImage" />
        </div>
      </a-form-item>
    </div>
    <div class="circle-list" v-if="circleId">
      <a-form-item>
        <a-button @click="openContactAddCircle">新增联系人</a-button>
      </a-form-item>
      <a-form-item label="" name="contactIdList">
        <a-select
          mode="multiple"
          autoClearSearchValue
          v-model:value="contactIdList"
          placeholder="请添加人脉圈成员"
          :filter-option="
            (inputValue, option) => handleFilterOptionT(inputValue, option)
          "
          @change="(value, option) => handleChangeCircle(option, value)"
          allow-clear
        >
          <a-select-option
            v-for="item in selectNameList"
            :key="item"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>
    <contactAdd
      :visible="contactAddShow"
      :backContact="true"
      @fnOk="handleContactAdd"
      @update:visible="closeContactAdd"
    />
    <contactAddCircle
      :init="circleId"
      :visible="contactAddCircleShow"
      :backContact="true"
      @fnOk="handleContactAddCircle"
      @update:visible="closeContactAddCircle"
    />
    <all-contacts
      :visible="allContactsVisible"
      :init='allContactsVal'
      @fnOk="handleAllContacts"
    />
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch,
  onMounted,
  onActivated,
} from 'vue';
import { useStore } from 'vuex';
import {
  Form as AForm,
  Empty as AEmpty,
  Select as ASelect,
} from 'ant-design-vue';
import { CloseOutlined } from '@ant-design/icons-vue';
import todayApi from '@/api/today';
import timelineApi from '@/api/timeline';
import contactAddCircle from '@/views/contactsCircle/addContactsCircle.vue';
import contactAdd from '@/views/contacts/contactAall/addPage';
import allContacts from '@/components/allContacts';
import { mergeAndUnique } from '@/utils';

const props = defineProps({
  circleId: String,
  contactId: String,
  selectList: Array,
  selectIdList: Array,
});

const store = useStore();

const emits = defineEmits(['changeList']);

const AFormItem = AForm.Item;
const ASelectOption = ASelect.Option;

const simpleImage = AEmpty.PRESENTED_IMAGE_SIMPLE;

const contactAddShow = ref(false);
const contactAddCircleShow = ref(false);
const allContactsVisible = ref(false);
const allContactsVal = ref({});

const inputValue = ref();

const dataList = ref([]);
const selectNameList = ref();
const uniqueAry = ref();

const contactIdList = ref([]);
const allMembers = ref([]);

const showxiala = ref(false);
const showEmpty = ref(false);
const selectBox = ref();

const handleChange = value => {
  todayApi
    .queryContactByName('', {
      name: value,
    })
    .then(res => {
      if (res) {
        getData(res);
      }
    });
};

const handleChangeCircle = (option, value) => {
  value.map(item => {
    if (item === 'allMembers') {
      contactIdList.value = allMembers.value;
    }
    if (item === 'allEmpty') {
      contactIdList.value = [];
    }
  });
  uniqueAry.value = contactIdList.value.filter(item => item !== 'allMembers');
  contactIdList.value = [...new Set(uniqueAry.value)];
  rebackData();
};

// 失焦调取清空输入框内容和下拉内容

const handleFocus = () => {
  todayApi.queryContactByName('', {}).then(res => {
    // console.log('handleFocus获取焦点', res);
    if (res) {
      showxiala.value = true;
      getData(res);
    }
  });
};

const handleEmpty = () => {
  dataList.value = [];
  rebackData();
};

const selectName = item => {
  if (dataList.value?.length > 0) {
    dataList.value.forEach(i => {
      if (i.id !== item.id) {
        dataList.value.push(item);
      }
    });
    // 数组对象去重的做法
    let unique = {};
    dataList.value.forEach(item => {
      unique[item['id']] = item;
    });
    dataList.value = Object.values(unique);
    rebackData();
  } else {
    dataList.value = [];
    dataList.value.push(item);
    rebackData();
  }

  inputValue.value = '';
  selectNameList.value = [];
  showxiala.value = false;
};

const deleteName = index => {
  dataList.value.splice(index, 1);
  rebackData();
};

const getData = res => {
  let notFoundItems = []; // 存储在list数组中存在但在a数组中不存在的项的新数组
  // 遍历 list 数组
  res.forEach(item => {
    // 使用 JSON.stringify 将对象转换为字符串，然后检查当前项是否在 a 数组中
    if (!dataList.value?.some(aItem => aItem.id === item.id)) {
      // 如果不在，将该项添加到 notFoundItems 数组中
      notFoundItems.push(item);
    }
  });
  // 打印在list数组中存在但在a数组中不存在的项
  if (notFoundItems.length > 0) {
    showxiala.value = true;
    showEmpty.value = false;
    selectNameList.value = notFoundItems;
  } else {
    showxiala.value = false;
    showEmpty.value = true;
  }
};

const handleFilterOptionT = (inputValue, option) => {
  return option.key.name.indexOf(inputValue) >= 0;
};

const cleanList = () => {
  // selectNameList.value = [];
  // allMembers.value = [];
  // uniqueAry.value = [];
  dataList.value = [];
  contactIdList.value = [];
  // document.removeEventListener('click');
};

const rebackData = () => {
  if (props.circleId) {
    emits('changeList', {
      type: 1,
      data: contactIdList.value,
    });
  } else {
    emits('changeList', {
      type: 0,
      data: dataList.value,
    });
  }
};

const openContactAdd = () => {
  contactAddShow.value = true;
};

const handleContactAdd = value => {
  // console.log(value);
  contactAddShow.value = value.visible;
  dataList.value.push({
    id: value.id,
    name: value.name,
  });
  rebackData();
};
const closeContactAdd = () => {
  contactAddShow.value = false;
};

const openContactAddCircle = () => {
  contactAddCircleShow.value = true;
};

const handleContactAddCircle = value => {
  contactAddCircleShow.value = value.visible;

  //刷新人脉圈成员下拉
  todayApi
    .queryContactByName('', {
      connectionsId: props.circleId,
    })
    .then(res => {
      if (res) {
        // console.log('res66123', res);
        selectNameList.value = res;
        if (selectNameList.value.length > 0) {
          selectNameList.value.unshift({
            id: 'allMembers',
            name: '全部成员',
          });
        }
      }
    });

  //刷新该脉圈下的全部成员 puah form.contactIdList
  todayApi
    .queryContactByConnections('', {
      connectionsId: props.circleId,
    })
    .then(res => {
      if (res) {
        // console.log('全部成员', res);
        res.forEach(item => {
          allMembers.value.push(item.id);
        });
      }
    });

  // form.contactIdList
  value.ids.forEach(item => {
    contactIdList.value.push(item);
  });
  rebackData();
};
const closeContactAddCircle = () => {
  contactAddCircleShow.value = false;
};

const addAllContacts = () => {
  allContactsVisible.value = true;
  allContactsVal.value = {}
};

const handleAllContacts = (value) =>{
  let newAry = mergeAndUnique(dataList.value, value.list, 'id')
  dataList.value = newAry

  emits('changeList', {
    type: 0,
    data: dataList.value,
  });

  allContactsVisible.value = false;
};


onMounted(() => {
  console.log('list mounted');
  if (props.selectIdList && props.circleId) {
    contactIdList.value = props.selectIdList;
  }
  if (props.selectList && !props.circleId) {
    dataList.value = props.selectList;
  }
  if (!props.circleId && props.contactId) {
    timelineApi
      .getContactMessage('', {
        userId: store.state.account.user.id,
        contactId: props.contactId,
      })
      .then(res => {
        dataList.value.push(res);
      });
    rebackData();
  }
  if (props.circleId) {
    todayApi
      .queryContactByName('', {
        connectionsId: props.circleId,
      })
      .then(res => {
        if (res) {
          // console.log('res66123', res);
          selectNameList.value = res;
          if (selectNameList.value.length > 0) {
            // state.selectNameList.unshift({
            //   id: 'allMembers',
            //   name: '全部成员',
            // });
            selectNameList.value.unshift(
              // {
              //   id: 'allEmpty',
              //   name: '一键清除',
              // },
              {
                id: 'allMembers',
                name: '全部成员',
              }
            );
          }
          // console.log('res66state.selectNameList', state.selectNameList);
        }
      });
    todayApi
      .queryContactByConnections('', {
        connectionsId: props.circleId,
      })
      .then(res => {
        if (res) {
          // console.log('全部成员', res);
          res.forEach(item => {
            allMembers.value.push(item.id);
          });
          // console.log(allMembers.value);
        }
      });
  } else {
    document.addEventListener('click', e => {
      if (selectBox.value) {
        if (!selectBox.value.contains(e.target)) {
          showxiala.value = false;
          showEmpty.value = false;
        }
      }
    });
  }
});

watch(
  () => props.circleId,
  (newVal, oldVal) => {}
);

watch(
  () => props.selectList,
  (newVal, oldVal) => {
    if (newVal.length !== 0) {
      dataList.value = props.selectList;
      document.addEventListener('click', e => {
        if (selectBox.value) {
          if (!selectBox.value.contains(e.target)) {
            showxiala.value = false;
            showEmpty.value = false;
          }
        }
      });
    }
  }
);

watch(
  () => props.selectIdList,
  (newVal, oldVal) => {
    if (newVal.length !== 0) {
      contactIdList.value = props.selectIdList;
      if (props.circleId) {
        todayApi
          .queryContactByName('', {
            connectionsId: props.circleId,
          })
          .then(res => {
            if (res) {
              // console.log('res66123', res);
              selectNameList.value = res;
              if (selectNameList.value.length > 0) {
                // state.selectNameList.unshift({
                //   id: 'allMembers',
                //   name: '全部成员',
                // });
                selectNameList.value.unshift(
                  // {
                  //   id: 'allEmpty',
                  //   name: '一键清除',
                  // },
                  {
                    id: 'allMembers',
                    name: '全部成员',
                  }
                );
              }
              // console.log('res66state.selectNameList', state.selectNameList);
            }
          });
        todayApi
          .queryContactByConnections('', {
            connectionsId: props.circleId,
          })
          .then(res => {
            if (res) {
              // console.log('全部成员', res);
              res.forEach(item => {
                allMembers.value.push(item.id);
              });
              // console.log(allMembers.value);
            }
          });
      }
    }
  }
);

defineExpose({ cleanList });
</script>

<style lang="less" scoped>
.contact-list {
  .dataListClass {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 4px;
    margin-top: 16px;
    max-height: 68px;
    overflow: auto;

    .name {
      // width: 88px;
      height: 24px;
      border-radius: 4px;
      //background: #FFFFFF;
      background: #fffaf6;
      font-size: 12px;
      line-height: 20px;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 8px 8px 0px;
      gap: 4px;
    }
  }

  .formItemClass {
    position: relative;
  }

  .namename {
    max-height: 164px;
    width: 100%;
    overflow: auto;
    position: absolute;
    top: 24px;
    z-index: 10;
    margin-top: 16px;
    border: 1px solid #eeeff5;
    background: #fff;
    border-radius: 4px;

    .selectList {
      .selectName {
        height: 40px;
        background: #f5f6fa;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-left: 16px;

        &:hover {
          background: #fffaf6;
        }
      }
    }
  }

  .empty {
    color: #ff7b00;
    margin-right: 12px;
  }
}

/deep/ .ant-btn {
  font-size: 12px;
}
</style>
