<template>
  <div class="mt-40">
    <contact-grid
      ref="gridRef"
      allow-selection
      :showHeader="true"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :items="items"
      :searchVal="parmas.id"
      :url-params="{ ...parmas }"
      :btn-operation="btnOperation"
      :scroll="{ x: 800, y: gridHeight }"
      @fnGrid="handleGrid"
      @searchVal='getSearch'
    >
      <!--      <template #operation="{ record }">-->
      <!--        <operation :options="options" :record="record" />-->
      <!--      </template>-->
      <template v-slot:avatar="{ record }">
        <a-avatar
          v-if="record.avatar"
          :src="setAvatar(record.avatar)"
          class="headerUrl"
        />
        <div v-else class="headerText">
          <div v-if="record.name">
            {{ record.name[0] }}
          </div>
        </div>
      </template>
      <template v-slot:name="{ record }">
        <div class="cursor-p">
          {{ record.name }}
        </div>
      </template>

    </contact-grid>
  </div>
</template>

<script>
import contactGrid from '@/components/contactGrid';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { createVNode, onMounted, reactive, ref, toRefs, watch } from 'vue';
import * as icons from '@ant-design/icons-vue';
import { isDelBoundary, setAvatar } from '@/utils';
import { message, Modal, Avatar,} from 'ant-design-vue';
// import contactAll from '@/api/contactAll';
// import contactsCircleApi from '@/api/contactsCircle';
import groupApi from '@/api/group';
import menuApi from '@/api/menu';

export default {
  components: {
    contactGrid,
    AAvatar: Avatar,

  },
  props: {
    init: Object,
  },
  setup(props, context) {
    const store = useStore();
    const userId = store.state.account.user.id;
    const gridRef = ref();
    // const router = useRouter();
    const state = reactive({
      parentId: '',
      visibleFrequency: false,
      activeItemFrequency: [],
      visibleAddGroup: false,
      activeItemAddGroup: [],
      visibleEdit: false,
      activeItemEdit: [],
      visibleContactsPage: false,
      activeItemAdd: {},
      visibleAddPage: false,
      visiblePluse: false,
      activeItemPluse: [],
      visibleGroup: false,
      frequenctList: [],
      activeItemGroup: {},
      // isDrag: false,
      visible: false,
      initValue: {},
      groupMessage: {},
      parmas: {
        // todo 换成 groupId
        groupId: '',
        userId: userId,
        conditionDto: {
          sortMap: {
            field: '',
            sort: 'asc',
          },
          fieldList: [
            'company',
            'position',
            'describe',
            'frequency',
            'last_interaction_time',
            'socialize_url',
          ],
          conditionMap: [
            {
              field: '',
              relation: '',
              value: '',
            },
          ],
        },
      },
      name: '',
      treeData: [],

    });

    watch(
      () => props.init,
      (value) =>{
        if (value.activeKey === '2') {
          // 给父组件传值选中的数据和状态
          // console.log('watch', value.activeKey)

          if (!gridRef?.value.getSelectedRowsKeysAll().selectAll && gridRef?.value.getSelectedRowsKeysAll().id.length === 0) {
            return  message.info('请选择联系人')
          }

          let parmas = {
            ... gridRef.value?.getSelectedRowsKeysAll(),
            groupId: state.parmas.groupId,
            spinning: true
          }
          context.emit('getAllGroud', parmas);
          gridRef.value?.refreshGrid();


        }

      }
    );

    const getData = () => {
      // contactAll.queryConnectionsTree('', userId).then(res => {
      //   if (res) {
      //     Object.assign(state.treeData, res);
      //     state.parmas.groupId = state.treeData[0].id
      //
      //   }
      //
      // });

      menuApi
        .getList({ userId: store.state.account.user.id,}).then(res => {
        if (res) {
          // console.log('res关系组', res)
          Object.assign(state.treeData, res);
          // state.parmas.groupId = state.treeData[0].id
        }
      });


    };

    onMounted(() => {
      getData();
    });

    return {
      ...toRefs(state),
      icons,
      setAvatar,
      gridRef,
      items: [
        // todo 这里需要替换成关系组的字段 labelKey
        {
          key: 'id',
          label: '关系组',
          type: 'treeSelect',
          dataset: state.treeData,
          labelKey: 'groupName',
          childrenKey: 'children',
        },
        { key: 'name', label: '搜索姓名、公司、职位', type: 'input' }],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          dataIndex: 'name',
          title: '姓名',
          width: 160,
          ellipsis: true,
          slots: { customRender: 'name' },
        },
        {
          dataIndex: 'company',
          title: '公司',
          width: 300,
          ellipsis: true,
          slots: { customRender: 'company' },
        },
        { dataIndex: 'position',  title: '职位', width: 300, ellipsis: true },

      ],
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sort',
          label: '排序',
          permission: 'resources:sort',
          slots: { customRender: 'sort' },
          fnClick: () => {
            console.log('排序排序', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sift',
          label: '筛选',
          permission: 'resources:sift',
          fnClick: () => {
            console.log('筛选');
          },
        },
      ],
      options: [
        // {
        //   type: 'detail',
        //   label: '详情',
        //   icon: 'EyeTwoTone',
        //   permission: 'distribution:detail',
        //   fnClick: record => {
        //     router.push(`/contacts/${record.id}`);
        //   },
        // },
      ],
      // 人脉圈分页
      // todo 换成关系组的分页
      // url: contactsCircleApi.getPageUrl,
      // 关系组分页
      url: groupApi.pageChildUrl,
      gridHeight: document.body.clientHeight - 470,

      handleGrid: parmas => {
        // grid传来的值
        let { value, type } = parmas;
        // console.log('grid传来的值', value, type);
        if (type === 'sort') {
          if (value === 'last_interaction_time' || value === 'create_time') {
            state.parmas.conditionDto.sortMap.sort = 'desc';
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.sortMap.sort = 'asc';
            gridRef.value.refreshGrid();
          }
          state.parmas.conditionDto.sortMap.field = value;
          gridRef.value.refreshGrid();
        } else if (type === 'field') {
          state.parmas.conditionDto.fieldList = value;
          gridRef.value.refreshGrid();
        } else if (type === 'sift') {
          // console.log('valuefgfd', value);
          if (value) {
            state.parmas.conditionDto.conditionMap = value;
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.conditionMap = [];
            gridRef.value.refreshGrid();
          }
        }

        // gridRef.value.refreshGrid();
      },
      getSearch: parmas => {
        console.log('parmas table筛选', parmas )
        state.name = parmas.name
        state.parmas.groupId = parmas?.id

      },
      handleLine(link) {
        window.open(link, '_blank');
      },

    }

  },
};
</script>

<style scoped>

</style>