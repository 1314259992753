<template>
  <a-dropdown placement="bottomRight">
    <a-button
      type="primary"
      shape="circle"
      @click="handleClick"
      class="header-add-btn"
    >
      <template #icon><PlusOutlined class="header-add-icon" /></template>
    </a-button>
    <template #overlay>
      <a-menu>
        <a-menu-item @click="openContactAdd">
          <img src="@/assets/images/header/tabber-icon_1.png" alt="" />
          <span>联系人</span>
        </a-menu-item>
        <a-menu-item @click="handleGroupAdd">
          <img src="@/assets/images/header/tabber-icon_2.png" alt="" />
          <span>关系组</span>
        </a-menu-item>
        <a-menu-item @click="openCircleAdd">
          <img src="@/assets/images/header/tabber-icon_3.png" alt="" />
          <span>人脉圈</span>
        </a-menu-item>
        <a-menu-item @click="openBacklogAdd">
          <img src="@/assets/images/header/tabber-icon_4.png" alt="" />
          <span>待办</span>
        </a-menu-item>
        <a-menu-item @click="openRecordAdd">
          <img src="@/assets/images/header/tabber-icon_5.png" alt="" />
          <span>记录</span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <contactAdd
    :visible="contactAddShow"
    :jump="true"
    @fnOk="handleContactAdd"
    @update:visible="closeContactAdd"
  />
  <!-- <groupAdd
    v-model:visible="groupAddShow"
    :jump="true"
    @fnOk="handleGroupAdd"
  /> -->
  <groupAdd
    v-model:visible="groupAddShow"
    :init="activeItemAddEdit"
    :jump="true"
    @fnOk="handleGroupAdd"
  />

  <circleAdd :visible="circleAddShow" @fnOk="handleCircleAdd" :jump="true" />
  <!--  这里换成纯新增联系人和人脉圈待办   -->
  <add-menber-page
    :visible="backlogAddShow"
    @update:visible="closeBacklogAdd"
    @fnOk="handleBacklogAdd"
  />

<!--  <recordAdd :visiable="recordAddShow" @closeModal="handleRecordAdd" />-->
  <!--  联系人记录&人脉圈记录     -->
  <add-recording
    :visible="recordAddShow"
    :init="activeItem"
    @closeModal="handleRecordAdd"
    @fnOk="handleAddRecording"
  />

</template>

<script>
import { Dropdown, Menu, message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import contactAdd from '@/views/contacts/contactAall/addPage';
// import groupAdd from '@/views/group/addEditGroup';
import groupAdd from '@/views/contacts/contactAall/addTiesGroup';
import circleAdd from '@/views/contactsCircle/addPage';
// import recordAdd from '@/views/timeline/components/addModal.vue';
import todayApi from '@/api/today';
import dashboardApi from '@/api/dashboard';
import addMenberPage from '@/views/today/homePage/components/addMenberPage';
import AddRecording from '@/views/timeline/components/addRecording';

export default {
  name: 'FullScreen',
  components: {
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    PlusOutlined,
    contactAdd,
    groupAdd,
    circleAdd,
    // recordAdd,
    addMenberPage,
    AddRecording,

  },
  data() {
    const userId = this.$store.state.account.user.id;
    const userData = this.$store.state.account.user;

    const activeItemAddEdit = {};

    // 获取云同步状态
    // const getIsWhetherCloud = (parmas) => {
    //   dashboardApi.getIsWhetherCloud({}).then(data => {
    //     if (data){
    //       this.parmas = true;
    //     } else {
    //       // 未开启云同步
    //       return message.error('请先通过app完成联系人同步。');
    //     }
    //
    //   });
    //
    // };

    return {
      userId,
      userData,
      activeItemAddEdit,
      full: false,
      contactAddShow: false,
      groupAddShow: false,
      circleAddShow: false,
      backlogAddShow: false,
      recordAddShow: false,
      importStatus: false,
      // getIsWhetherCloud,
    };
  },
  mounted() {
    todayApi
      .getContactImportSituation('', {
        userId: this.$store.state.account.user.id,
      })
      .then(res => {
        // console.log('res22', res);
        // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
        // 新手、导入未完成、未开启云存储
        if (res === 'Novice' || res === 'incomplete') {
          this.importStatus = true;
        }
      });
  },
  methods: {
    handleClick() {},
    openContactAdd() {
      dashboardApi.getIsWhetherCloud({}).then(data => {
        if (!data) {
          //  || this.importStatus
          // 未开启云同步
          return message.error('请先通过app完成联系人同步。');
        } else {
          // 没点击一次就查询一下用户状态
          todayApi
            .getContactImportSituation('', {
              userId: this.$store.state.account.user.id,
            })
            .then(res => {
              // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
              // 新手、导入未完成、未开启云存储
              if (res === 'Novice' || res === 'incomplete') {
                return message.error('请先通过app完成联系人同步。');
              }else {
                this.contactAddShow = true;
              }
            });

        }
      });
    },
    handleContactAdd(value) {
      this.contactAddShow = value;
    },
    closeContactAdd() {
      this.contactAddShow = false;
    },
    openGroupAdd() {
      this.groupAddShow = true;
      this.activeItemAddEdit = {
        type: 'add',
        show: false,
        groupMessage: {
          parentId: '0',
          id: '0',
        },
      };
    },
    handleGroupAdd(value) {
      dashboardApi.getIsWhetherCloud({}).then(data => {
        if (!data) {
          //  || this.importStatus
          // 未开启云同步
          return message.error('请先通过app完成联系人同步。');
        } else {

          // 没点击一次就查询一下用户状态
          todayApi
            .getContactImportSituation('', {
              userId: this.$store.state.account.user.id,
            })
            .then(res => {
              // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
              // 新手、导入未完成、未开启云存储
              if (res === 'Novice' || res === 'incomplete') {
                return message.error('请先通过app完成联系人同步。');
              }else {
                this.groupAddShow = value;
              }
            });

        }

      });

    },
    openCircleAdd() {
      dashboardApi.getIsWhetherCloud({}).then(data => {
        if (!data) {
          //  || this.importStatus
          // 未开启云同步
          return message.error('请先通过app完成联系人同步。');
        } else {

          todayApi
            .getContactImportSituation('', {
              userId: this.$store.state.account.user.id,
            })
            .then(res => {
              // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
              // 新手、导入未完成、未开启云存储
              if (res === 'Novice' || res === 'incomplete') {
                return message.error('请先通过app完成联系人同步。');
              }else {
                this.circleAddShow = true;
              }
            });

        }

      });


    },
    handleCircleAdd(value) {
      this.circleAddShow = value;
    },
    openBacklogAdd() {
      dashboardApi.getIsWhetherCloud({}).then(data => {
        if (!data) {
          //  || this.importStatus
          // 未开启云同步
          return message.error('请先通过app完成联系人同步。');
        } else {
          todayApi
            .getContactImportSituation('', {
              userId: this.$store.state.account.user.id,
            })
            .then(res => {
              // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
              // 新手、导入未完成、未开启云存储
              if (res === 'Novice' || res === 'incomplete') {
                return message.error('请先通过app完成联系人同步。');
              }else {
                this.backlogAddShow = true;
              }
            });

        }

      });

    },
    closeBacklogAdd() {
      this.backlogAddShow = false;
    },
    handleBacklogAdd(value) {
      this.backlogAddShow = value;
    },
    handleAddRecording(value) {
      this.recordAddShow = value;
    },
    openRecordAdd() {
      dashboardApi.getIsWhetherCloud({}).then(data => {
        if (!data) {
          //  || this.importStatus
          // 未开启云同步
          return message.error('请先通过app完成联系人同步。');
        } else {
          todayApi
            .getContactImportSituation('', {
              userId: this.$store.state.account.user.id,
            })
            .then(res => {
              // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
              // 新手、导入未完成、未开启云存储
              if (res === 'Novice' || res === 'incomplete') {
                return message.error('请先通过app完成联系人同步。');
              }else {
                this.recordAddShow = true;
              }
            });

        }

      });

    },

    handleRecordAdd() {
      this.recordAddShow = false;
    },

  },
};
</script>
<style lang="less" scoped>
.header-add-btn {
  padding-top: 3px;
}
.header-add-icon {
  font-size: 20px;
}

.ant-dropdown-menu-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }

  span {
    margin-left: 8px;
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
}
</style>
