<template>
  <div  class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      title="反馈意见"
      :width="600"
      centered
      :destroyOnClose="true"
      :getContainer="() => container"
      @cancel="close"
    >

      <div class='activeColor mb-20'>我们非常重视用户的声音，如果您有任何建议或者反馈，请告知我们，我们将会进行改进。</div>
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="form"
        :rules="rules"
      >
        <a-form-item label="" name="content">
          <a-textarea
            v-model:value="form.content"
            placeholder="请输入建议描述"
            showCount
            :maxlength="2000"
            :rows="10"
          />
        </a-form-item>

        <a-form-item
          label=""
          class="formFileRow"
          v-if="form.imgUrl.length > 0"
        >
          <fileList
            :fileList="form.imgUrl"
            @fileListChange="deleteFile"
          ></fileList>
        </a-form-item>

        <a-form-item label="" class="formFileRow">
          <a-upload
            v-model:file-list="form.list"
            :action="actionImgSrc"
            list-type="picture-card"
            :headers="fileUploadHeaders"
            :data="
              file => ({
                fileName: file,
              })
            "
            :before-upload="beforeUpload"
            @change="fileListChange"
          >
            <plus-outlined />
            <div style="margin-top: 8px">上传文件</div>
          </a-upload>
        </a-form-item>

      </a-form>

      <template v-slot:footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary"  @click="handleOk" :loading="loading">提交</a-button>
      </template>
    </a-modal>
  </div>


</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';
import {
  // UploadOutlined,
  PlusOutlined, } from '@ant-design/icons-vue';

import {
  Form,
  message,
  Upload
} from 'ant-design-vue';
import { useStore } from 'vuex';
import fileList from '@/components/fileUploadList';
import userApi from '@/api/user.js';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    // IssImageUpload,
    // UploadOutlined,
    PlusOutlined,
    AUpload: Upload,
    fileList,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const container = ref();
    const formRef = ref();
    const store = useStore();
    const { token, tenant } = store.state.account;
    const fileUploadHeaders = { token, tenant };
    const state = reactive({
      actionImgSrc: `${process.env.VUE_APP_API_SERVER}connections_treasure_box/file/upload?bizType=images&isSingle=true`,
      loading: false,

    })
    const form = reactive({
      content: '',
      imgUrl: [],
      userId: store.state.account.user.id,
      list: [],

    });

    watch(
      () => props.init,
      init => {
        console.log('init123', init);

      },
      { deep: true }
    );
    const rules = {
      content: [
        {
          required: true,
          message: '请输入建议描述',
          trigger: 'change',
        },

      ],

    };

    //文件列表上传回调
    const fileListChange = e => {
      if (e.file.status === 'done') {
        if (e.file.response.code == 0) {
         form.imgUrl.push(e.file.response.data)

        }
      }
      if (e.file.status === 'removed') {

        let fileId = e.file?.response?.data.id;
        form.imgUrl = form.imgUrl.filter(
          item => item.id != fileId
        );

      }
    };
    const beforeUpload = (file)=> {
      const isJpgOrPng = ['image/jpeg', 'image/png',  'video/mp4', 'video/mp3'].includes(file.type);
      if (!isJpgOrPng) {
       message.error('只能上传 JPG/JPEG/PNG 格式的图片和 MP4 格式视频!');
      }
      let size = 50;
      // if (this.limitSize) size = Number(this.limitSize) * size;
      const isOK = file.size / 1024 / 1024 < size;
      if (!isOK) {
        message.error(`图片大小不能超过 ${size}MB!`);
      }
      return isJpgOrPng && isOK;
    };

    //文件列表删除文件回调
    const deleteFile = () => {

    };

    const handleOk = () => {
      let list = []
      form.imgUrl.map((item)=>{
        list.push(item.dataUrlCustom)
      })

      let parmas = {
        content: form.content,
        imgUrl: JSON.stringify(list),
        userId: form.userId

      }
      formRef.value.validate().then(() => {
        state.loading = true;
        userApi.userFeedback('personal:update', parmas).then(res => {
          if (res) {
            message.success('提交成功,感谢您提供宝贵的建议！')
            state.loading = false;
            context.emit('fnOk', false);
            form.content = ''
            form.imgUrl = []
            form.list = []

          }
        });
      });
    };

    return {
      container,
      formRef,
      ...toRefs(state),
      fileUploadHeaders,
      form,
      rules,
      handleOk,
      fileListChange,
      deleteFile,
      beforeUpload,
      close() {
        context.emit('update:visible', false);
      },
      handleCancel() {
        context.emit('update:visible', false);
      },

    }

  }


};
</script>

<style scoped lang='less'>
.modalClass /deep/ .ant-modal-body {
  height: 540px;
  overflow: auto;
  padding: 0px 24px;
  margin-top: 24px;
  font-size: 16px;

}
//.modalClass /deep/ .ant-modal-footer {
//  padding:0px;
//}
.formFileRow /deep/ .ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}

.formFileRow /deep/.ant-upload-list-picture-card-container {
  display: none !important;
}

</style>