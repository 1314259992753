'use strict';

import http from '@/utils/http';

export default {
  // 根据id查询用户信息
  getItemById(code, id) {
    return http.get(`/connections_treasure_box/sys-user/getUserById/${id}`, {
      code,
    });
  },
  // getOwn() {
  //   return http.get('/authority/anno/userInfo');
  // },
  // 更新保存用户信息
  update(code, data) {
    return http.put('/connections_treasure_box/sys-user/updateUser', data, {
      code,
    });
  },
  updatePassword(data, code) {
    return http.put('/authority/user/password', data, { code });
  },

  // 安全设置列表
  getSecurityConfigList(code, id) {
    return http.get(
      `/connections_treasure_box/sys-user/getSecurityConfigList/${id}`,
      {
        code,
      }
    );
  },
  //   根据用户id获取验证码
  sendSmsVerifyCodeByUserId(code, id) {
    return http.get(
      `/connections_treasure_box/sys-user/sendSmsVerifyCodeByUserId/${id}`,
      {
        code,
      }
    );
  },
  //  根据用户id校验验证码是否正确
  verifyCodeByUserId(code, data) {
    return http.post(
      `/connections_treasure_box/sys-user/verifyCodeByUserId/${data.userId}`,
      data,
      {
        code,
      }
    );
  },
  //用户绑定新的手机号
  updateUserMobile(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/updateUserMobile',
      data,
      {
        code,
      }
    );
  },
  // 发送邮箱验证码
  sendEmailVerifyCode(code, data) {
    return http.post(
      '/connections_treasure_box/message/sendEmailVerifyCode',
      data,
      {
        code,
      }
    );
  },

  // 绑定或者更新邮箱账户
  updateOrBindUserEmail(code, data) {
    return http.post(
      '/connections_treasure_box/sys-user/updateOrBindUserEmail',
      data,
      {
        code,
      }
    );
  },
  //   查询当前用户下通知列表
  getUserNotifySettingList(code, id) {
    return http.get(
      `/connections_treasure_box/user/user-setting/getUserNotifySettingList/${id}`,
      {
        code,
      }
    );
  },

  // 根据用户主键修改通知设置
  updateSettingByUserId(code, data) {
    return http.put(
      '/connections_treasure_box/user/user-setting/updateSettingByUserId',
      data,
      {
        code,
      }
    );
  },
  // 注销用户
  removeUser(code, data) {
    return http.post('/connections_treasure_box/sys-user/removeUser', data, {
      code,
    });
  },

  //查询套餐列表
  getPackageInfoList(code, params) {
    return http.get(
      `connections_treasure_box/payment/package-info/getPackageInfoList`,
      {
        params,
        code,
      }
    );
  },

  //获取用户云空间信息
  getUserSpaceDetail(code, params) {
    return http.get(
      `/connections_treasure_box/user/user-cloud-space/getUserCloudSpace`,
      {
        params,
        code,
      }
    );
  },
};
