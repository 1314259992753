'use strict';

import http from '@/utils/http';
// const crypto = require('crypto');

// function generateRadomString(length) {
//   return crypto
//     .randomBytes(Math.ceil(length / 2))
//     .toString('hex')
//     .slice(0, length);
// }

export default {
  //获取联系人列表
  queryContactByName(code, params) {
    return http.get(
      '/connections_treasure_box/contact/contact/queryContactByName',
      { params, code }
    );
  },
  //获取记录列表
  getRecordType(code, params) {
    return http.get(
      '/connections_treasure_box/dictionary-item/code/tree?code=MOMENT_RECORD_TYPE',
      {
        params,
        code,
      }
    );
  },
  //获取用户储存空间信息
  getUserFreeSpace(code, params) {
    return http.get(
      '/connections_treasure_box/contact/todo-moment-files-records/getUserFreeSpace',
      {
        params,
        code,
      }
    );
  },
  //文件上传
  // fileUpload(code, data) {
  //   let boundary = generateRadomString(16);

  //   return http.post(
  //     '/connections_treasure_box/obs/obs-files-records/uploadObsFiles',
  //     data,
  //     {
  //       code,
  //       headers: {
  //         'Content-Type': 'multipart/form-data;' + 'boundary=' + boundary,
  //       },
  //     }
  //   );
  // },

  //新建待办记录
  addRecord(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-todo-moment',
      data,
      { code }
    );
  },

  //修改待办记录
  eidtRecord(code, data) {
    return http.put(
      '/connections_treasure_box/contact/contact-todo-moment',
      data,
      { code }
    );
  },

  //删除记录
  deleteRecord(code, params) {
    return http.delete(
      '/connections_treasure_box/contact/contact-todo-moment',
      { params, code }
    );
  },

  //删除待办
  deleteBacklog(code, params) {
    return http.delete(
      '/connections_treasure_box/contact/contact-todo-matter',
      { params, code }
    );
  },

  //获取记录列表
  getRecordList(code, params) {
    return http.get(
      '/connections_treasure_box/contact/contact-todo-moment/page',
      { params, code }
    );
  },

  //回退记录
  rebackRecordList(code, data) {
    return http.put(
      '/connections_treasure_box/contact/contact-todo-matter/markTodoCompleted',
      data,
      { code }
    );
  },

  //删除指定文件
  deleteFile(code, params) {
    return http.delete(
      '/connections_treasure_box/contact/todo-moment-files-records',
      { params, code }
    );
  },

  //获取指定id联系人信息
  getContactMessage(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact/queryUserContactDetail',
      data,
      { code }
    );
  },
};
