<template>
  <div class="contain">
    <div class="img-list" v-if="fileList.length > 0">
      <a-image-preview-group
        :preview="{
          imageGroupVisiable,
          onVisibleChange: vis => (imageGroupVisiable = vis),
        }"
      >
        <template v-for="(imgItem, imgIndex) in fileList" :key="imgIndex">
          <div
            class="img-item"
            v-if="
              getArr(imgItem.filename).toLowerCase() == 'jpg' ||
              getArr(imgItem.filename).toLowerCase() == 'jpeg' ||
              getArr( imgItem.filename).toLowerCase() == 'png' ||
              getArr(imgItem.filename).toLowerCase() == 'svg' ||
              getArr(imgItem.filename).toLowerCase() == 'gif'
            "
          >
            <div class="loading" v-if="imgItem?.percent < 100">
              <LoadingOutlined />
              <span>{{ imgItem.percent.toFixed(1) + '%' }}</span>
            </div>
            <img
              class="delete-icon"
              src="@/assets/images/timeline/delete-icon.png"
              @click="deleteFile(imgIndex, imgItem.size)"
            />
            <a-image
              class='imageWH'
              :src="imgItem.dataUrlCustom ? imgItem.dataUrlCustom : imgItem.url"
              @click="imageGroupVisiable = true"
            />
          </div>
        </template>
      </a-image-preview-group>
    </div>
    <div class="file-list" v-if="fileList.length > 0">
      <template v-for="(fileItem, fileIndex) in fileList" :key="fileIndex">
        <div
          class="file-item"
          :class="getArr(fileItem.name || fileItem.filename).toLowerCase()"
          v-if="
            getArr(fileItem.filename).toLowerCase() != 'jpg' &&
            getArr(fileItem.filename).toLowerCase() != 'jpeg' &&
            getArr(fileItem.filename).toLowerCase() != 'png' &&
            getArr(fileItem.filename).toLowerCase() != 'svg' &&
            getArr(fileItem.filename).toLowerCase() != 'gif'
          "
        >
<!--          <div>{{fileItem}}</div>-->
          <div class="iss-video-box">
            <video
              class="iss-video"
              poster=""
              :src="fileItem.dataUrlCustom"
              controls
            >
              {{ fileItem.dataUrlCustom }}
            </video>
          </div>
          <img
            class="delete-icon"
            src="@/assets/images/timeline/delete-icon.png"
            @click="deleteFile(fileIndex, fileItem.size)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref,  } from 'vue';
import { Image as AImage } from 'ant-design-vue';
import { LoadingOutlined } from '@ant-design/icons-vue';

const AImagePreviewGroup = AImage.PreviewGroup;

const emits = defineEmits(['fileListChange']);

const props = defineProps({
  fileList: Object,
});


const imageGroupVisiable = ref(false);
const myVideo = ref();

const getArr = str => {
    let arr = str.split('.').pop();
    return arr;
};

const deleteFile = (index, size) => {
  let fileList = props.fileList;
  fileList.splice(index, 1);
  emits('fileListChange', {
    fileList: fileList,
    index: index,
    size: size,
  });
};

// watch(
//   () => props.fileList,
//   (newVal, oldVal) => {
//     console.log(newVal);
//   }
// );
</script>

<style lang="less" scoped>
.contain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.img-list {
  width: 100%;
  margin-top: 13px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  .img-item {
    //width: 100px;
    //height: 100px;
    //height: auto;
    border-radius: 4px;
    // overflow: hidden;
    position: relative;

    /deep/ .ant-image {
      border-radius: 4px;
      overflow: hidden;
    }

    .delete-icon {
      width: 16px;
      height: 16px;
      object-fit: cover;
      position: absolute;
      top: -8px;
      right: -8px;
      //transform: translate(50%, -50%);
      z-index: 3;
      cursor: pointer;
    }

    .loading {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: #fff;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      background: rgba(#333333, 0.5);
      border-radius: 8px;
      z-index: 3;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &:hover {
      .menu-btn {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }

    .menu-btn {
      width: 24px;
      height: 24px;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }

    ::v-deep(.ant-image-img) {
      //width: 100%;
      //height: 100%;
      width: auto;
      max-height: 100px;
      object-fit: cover;
    }

    .imageWH {
      width: auto;
      max-height: 100px;
    }
  }
}

.file-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  //gap: 16px;
  flex-wrap: wrap;

  .file-item {
    width: 48%;
    margin-right: auto;
    //padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    //background: #eaefff;
    position: relative;
    justify-content: flex-start;
    margin-right: 10px;
    margin-bottom: 10px;

    .delete-icon {
      width: 16px;
      height: 16px;
      object-fit: cover;
      position: absolute;
      top: 0;
      right: 20px;
      z-index: 3;
      transform: translate(calc(100% + 10px), -50%);
      cursor: pointer;
    }

    .loading {
      padding: 4px 13px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      color: #fff;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      background: rgba(#333333, 0.5);
      border-radius: 8px;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &.zip,
    &.mp3 {
      //background: #faedff;
    }

    &.xlsx {
      background: #e7fff5;
    }

    &.ppt,
    &.pdf {
      background: #ffefdd;
    }

    &.doc,
    &.mp4 {
      //background: #eaefff;
    }

    .file-icon {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }

    .file-message {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .file-title {
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }

      .file-size {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }

    .menu-btn {
      width: 24px;
      height: 24px;
      margin-left: auto;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
    }

    &:hover {
      .menu-btn {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
}
.iss-video-box {
  //position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 40px);
  width: 100%;
  video {
    height: 100%;
    width: 100%;
  }
}
</style>
