<template>
  <a-layout class="layout-header">
    <a-layout-sider :width="200" theme="light">
      <iss-menu />
    </a-layout-sider>
    <a-layout>
      <a-layout-header>
        <iss-header />
      </a-layout-header>
      <!--      <iss-breadcrumb />-->
      <a-layout-content>
        <router-view v-slot="{ Component }">
          <!--          <transition name="fade-transform">-->
          <component :is="Component" />
          <!--          </transition>-->
        </router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { Layout } from 'ant-design-vue';
import IssHeader from './header';
// import IssBreadcrumb from './breadcrumb';
import IssMenu from './newMenu.vue';

export default {
  name: 'Layout',
  components: {
    ALayout: Layout,
    ALayoutSider: Layout.Sider,
    ALayoutHeader: Layout.Header,
    ALayoutContent: Layout.Content,
    IssHeader,
    // IssBreadcrumb,
    IssMenu,
  },
};
</script>
<style lang="less" scoped>
.layout-header /deep/ .ant-layout-header {
  height: 56px;
  padding: 0px;
}
.layout-header /deep/ .ant-layout-content {
  //background-color: #fff;
  height: calc(100vh - 56px);
  overflow: auto;
}
// .layout-header /deep/ .ant-layout-sider {
//   height: 100vh;
//   overflow: auto;
// }
</style>
