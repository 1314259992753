<template>
  <a-dropdown v-bind="$props">
    <div class="iss-user">
      <div v-if="userHeader.avatar">
        <a-avatar :src="setAvatar(userHeader.avatar)" :size="30" />
      </div>
      <div v-else>
        <a-avatar :src="setAvatar(user.avatar)" :size="30" />
      </div>

      <!--      <a-avatar :size="30" v-show="!userHeader">-->
      <!--        <template #icon><UserOutlined /></template>-->
      <!--      </a-avatar>-->
      <span class="user-name" v-if="userHeader.userName">{{
        userHeader.userName
      }}</span>
      <span class="user-name" v-else>{{ user.userName }}</span>
      <caret-down-outlined />

    </div>
    <template #overlay>
      <a-menu @click="handleClick">
        <a-menu-item key="personal">
          <smile-outlined :style="{ fontSize: '15px' }" />
          <span>个人中心</span>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="setup">
          <Setting-Outlined :style="{ fontSize: '15px' }" />
          <span>设置</span>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="feedback">
          <FormOutlined :style="{ fontSize: '15px' }" />
          <span>意见反馈</span>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item key="logout">
          <logout-outlined :style="{ fontSize: '15px' }" />
          <span>退出</span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <feed-back
    v-model:visible="feedBackVisible"
    @fnOk="handleEditPage"
  />
</template>

<script>
import { Avatar, Dropdown, Menu } from 'ant-design-vue';
import {
  CaretDownOutlined,
  LogoutOutlined,
  SmileOutlined,
  SettingOutlined,
  FormOutlined
  // UserOutlined,
} from '@ant-design/icons-vue';
import { setAvatar } from '@/utils';
import { local, session } from '@/utils/storage';
import loginApi from '@/api/login';
import feedBack from '@/components/layout/header/components/feedBack';

export default {
  name: 'User',
  components: {
    AAvatar: Avatar,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AMenuDivider: Menu.Divider,
    CaretDownOutlined,
    SmileOutlined,
    LogoutOutlined,
    SettingOutlined,
    feedBack,
    FormOutlined
    // UserOutlined,
  },
  computed: {
    user() {
      return this.$store.state.account.user;
    },
    userHeader() {
      return this.$store.state.account.userHeader
        ? this.$store.state.account.userHeader
        : '';
    },
  },
  data() {
    return {
      feedBackVisible: false,

    }
  },
  // watch: {
  //   userHeader() {
  //     console.log('55550', this.$store.state.account.userHeader)
  //     return this.$store.state.account.userHeader;
  //   },
  // },
  methods: {
    setAvatar,
    handleClick({ key }) {
      switch (key) {
        case 'personal':
          this.$router.push({ name: 'personal' });
          break;
        case 'setup':
          this.$router.push({ name: 'setup' });
          break;
        case 'feedback':
          this.feedBackVisible = true;
          break;
        case 'logout':
          loginApi.logout('').then(() => {
            local.clear();
            session.clear();
            location.reload();
          });
          break;
        default:
          break;
      }
    },
    handleEditPage(value) {
      this.feedBackVisible = value;
    },
  },
};
</script>

<style lang="less" scoped>
.iss-user {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #313d5f;
  .user-name {
    margin: 0 5px;
  }
}
</style>
