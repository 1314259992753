'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/connections_treasure_box/contact/contact/pageGroup',
  pageChildUrl: '/connections_treasure_box/contact/contact/groupSonAndDataPage',
  // 关系组详情页
  pageChildUrlItem: '/connections_treasure_box/contact/contact/groupSonAndDataList',


  // 查询分组联系人列表
  contactUnGroupOrAllGroup(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-groups/contactUnGroupOrAllGroup',
      data,
      { code }
    );
  },

  // 查询全部分组带子级
  getContactGroupsList(code, id) {
    return http.get(
      `/connections_treasure_box/contact/groups/getContactGroupsListDetail/${id}`,
      { code }
    );
  },

  //   根据群组id 查询群组信息
  groupsId(code, id) {
    return http.get(`/connections_treasure_box/contact/groups/${id}`, { code });
  },
  // 删除当前分组下的某个用户
  deleteGroupUser(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-groups/deleteGroupUser',
      data,
      { code }
    );
  },
};
