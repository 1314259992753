'use strict';

import { Button, Input, message, Modal } from 'ant-design-vue';
import mitt from 'mitt';
import http from '@/utils/http';

export default {
  install: app => {
    app.use(Button);
    app.use(Input);
    app.use(Modal);

    app.config.globalProperties.$http = http;
    app.config.globalProperties.$message = message;
    app.config.globalProperties.$bus = mitt();
    app.config.globalProperties.$has = function () {
      // const { user, permissions, apps } = this.$store.state.account;
      // console.log('power', power);
      // if (
      //   ['SUPER_ADMIN', 'ADMIN'].includes(user.roleCode) ||
      //   ((!relation || relation === power.split(':')[0]) &&
      //     (permissions?.includes(power) || apps?.find(i => i.id === power)))
      // ) {
      return Promise.resolve(true);
      // }
      // message.warning('您没有该操作权限，请联系管理员授权！');
      // return Promise.reject(false);
    };
  },
};
