'use strict';

import http from '@/utils/http';

export default {
  // 人脉详情页
  pageUrl: '/connections_treasure_box/contact/contact/page',
  // 将联系人添加到人脉圈分页 脉圈id
  addPageAll:
    '/connections_treasure_box/contact/contact/pageExcludeConnections',
  //  将联系人添加到关系组分页 传分组id
  pageExcludeGroup:
    '/connections_treasure_box/contact/contact/pageExcludeGroup',
  //  添加相关联系人分页 传联系人id
  pageContactRelation:
    '/connections_treasure_box/contact/contact/pageContactRelation',

  // tree接口
  getTree(code, params) {
    return http.get('/connections_treasure_box/dictionary-item/code/tree', {
      params,
      code,
    });
  },

  // 批量修改个人信息 联系人公司、职位 以及联系频次
  getPageList(data) {
    return http.post('/connections_treasure_box/contact/contact/page', data);
  },

  // 批量修改个人信息 联系人公司、职位 以及联系频次
  updateUserInfo(code, data) {
    return http.put(
      '/connections_treasure_box/contact/contact/updateUserInfo',
      data,
      { code }
    );
  },

  // 查询全部分组带子级
  getContactGroupsList(code, id) {
    return http.get(
      `/connections_treasure_box/contact/groups/getContactGroupsList/${id}`,
      { code }
    );
  },
  // 批量添加到关系组中
  saveContact2Group(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-groups/saveContact2Group',
      data,
      { code }
    );
  },
  // 询当前用户脉圈分类列表-父子树
  queryConnectionsTree(code, id) {
    return http.get(
      `/connections_treasure_box/contact/connections/removeEmptySonTree/${id}`,
      { code }
    );
  },

  // 批量删除联系人
  deleteContactIds(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact/deleteContactIds',
      data,
      { code }
    );
  },
  // 批量添加用户到脉圈
  saveConnections(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections-query/saveConnections',
      data,
      { code }
    );
  },
  // 新增联系人
  saveUserInfo(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact/saveUserInfo',
      data,
      { code }
    );
  },

  getTag(code, params) {
    return http.get('/account/tag/getTypeNameAndTag', { params, code });
  },
  add(code, data) {
    return http.post('/issmart-user/contact', data, { code });
  },
  update(code, data) {
    return http.put('/issmart-user/contact', data, { code });
  },

  downloadContactTemplate(code) {
    return http.get('/issmart-user/contact/contactTemplate', {
      responseType: 'blob',
      code,
    });
  },
  downloadActionTemplate(code) {
    return http.get('/issmart-user/contactActionInfo/contactActionTemplate', {
      responseType: 'blob',
      code,
    });
  },
  export(code, params) {
    return http.post('/issmart-user/contact/contactExport', params, {
      responseType: 'blob',
      code,
    });
  },
  getdetail(code, id) {
    return http.get(`/issmart-user/contact/contactById/${id}`, { code });
  },
  getbehavior(code, params) {
    return http.get('/issmart-user/contactActionInfo/contactActionInfoPage', {
      params,
      code,
    });
  },
  getcontactTag(code, params) {
    return http.get('/account/contactTag/listByContactId', {
      params,
      code,
    });
  },
  addTag(code, data) {
    return http.post('/account/contactTag/saveValid', data, { code });
  },
  delteTag(code, params) {
    return http.delete('/account/contactTag/deleteByTagCode', { code, params });
  },

  // 联系人头像、最后联系时间、天数、频次信息
  getPerSonalInfo(code, id) {
    return http.get(
      `/connections_treasure_box/contact/contact/getPerSonalInfo/${id}`,
      { code }
    );
  },

  // 批量修改个人信息 联系人公司、职位 以及联系频次\头像
  contact(code, data) {
    return http.put('/connections_treasure_box/contact/contact', data, {
      code,
    });
  },

  // 查询联系人详情信息
  getContactBaseInfo(code, id) {
    return http.get(
      `/connections_treasure_box/contact/contact/getContactBaseInfo/${id}`,
      { code }
    );
  },
  //   查询联系人基础信息
  getContactDetail(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact/getContactDetail',
      data,
      { code }
    );
  },
  //   更新联系人基础信息接口
  contactUpata(code, data) {
    return http.put('/connections_treasure_box/contact/contact', data, {
      code,
    });
  },

  //   新增联系人基础信息手机号,邮箱,地址实体
  getSave(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-details/save',
      data,
      { code }
    );
  },

  // 电话\邮箱\地址删除
  deleteById(code, id) {
    return http.delete(
      `/connections_treasure_box/contact/contact-details/deleteById/${id}`,
      { code }
    );
  },

  // 联系人信息页删除脉圈
  connectionsQuery(code, params) {
    return http.delete('/connections_treasure_box/contact/connections-query', {
      params,
      code,
    });
  },

  // 联系人信息页删除群组
  contactGroups(code, params) {
    return http.delete('/connections_treasure_box/contact/contact-groups', {
      params,
      code,
    });
  },

  //  查询相关联系人列表
  queryContactRelations(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-contact-relation/queryContactRelations',
      data,
      { code }
    );
  },
  // 添加相关联系人
  contactSave(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact-contact-relation/save',
      data,
      { code }
    );
  },

  deleteByRelationId(code, id) {
    return http.delete(
      `/connections_treasure_box/contact/contact-contact-relation/deleteByRelationId/${id}`,
      { code }
    );
  },
};
