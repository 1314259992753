'use strict';

import http from '@/utils/http';

export default {
  // 脉圈详情页分页查询
  getPageUrl:
    '/connections_treasure_box/contact/connections/queryConnectionsGroupDetail',
  // 一键添加脉圈全部联系人
  queryConnectionContactsPage: '/connections_treasure_box/contact/connections-query/queryConnectionContactsPage',

  deleteItem(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections/deleteByConnectionIds',
      data,
      { code }
    );
  },

  // 脉圈分类单条信息信息明细
  getTitleName(code, id) {
    return http.get(`/connections_treasure_box/contact/connections/${id}`, {
      code,
    });
  },

  // 脉圈分类列表
  getList(code, id) {
    return http.get(
      `/connections_treasure_box/contact/connections/queryConnections/${id}`,
      { code }
    );
  },

  // 获取脉圈分类下拉列表
  getCircleTree(code, id) {
    return http.get(
      `/connections_treasure_box/contact/connections/queryConnectionsParentTree/${id}`,
      { code }
    );
  },

  // 新增脉圈
  addCircle(code, data) {
    return http.post('/connections_treasure_box/contact/connections', data, {
      code,
    });
  },
  //  编辑脉圈
  updateCircle(code, data) {
    return http.put('/connections_treasure_box/contact/connections', data, {
      code,
    });
  },

  //  脉圈详情页删除
  deleteByConnectionIds(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections/deleteByConnectionIds',
      data,
      { code }
    );
  },

  //  脉圈单条详情页脉圈成员查询
  queryConnectionContacts(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections-query/queryConnectionContacts',
      data,
      { code }
    );
  },
  // 脉圈单条数据修改接口
  connections(code, data) {
    return http.put('/connections_treasure_box/contact/connections', data, {
      code,
    });
  },
  // 批量添加用户到脉圈
  saveConnections(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections-query/saveConnections',
      data,
      { code }
    );
  },

  // 删除当前脉圈下的某个用户
  deleteConnectionsUser(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections-query/deleteConnectionsUser',
      data,
      { code }
    );
  },
  // 更新脉圈排序列表
  sortConnection(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections/sortConnection',
      data,
      { code }
    );
  },
};
