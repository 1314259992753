<template>
  <a-breadcrumb separator=">" class="iss-breadcrumb">
    <a-breadcrumb-item v-if="first?.meta">
      <component :is="icons[first.meta.icon]" />
      <span class="issCentont">
        <img
          src="../../assets/images/dashboard/today.png"
          alt=""
          v-if="first.meta.title === '今天'"
          class="breadImg"
        />
        {{ first.meta.title }}
      </span>
    </a-breadcrumb-item>

    <a-breadcrumb-item v-for="item in breadcrumbs" :key="item.path">
      <a class="ant-breadcrumb-link" @click="handleClick(item)">
        <component v-if="item.meta?.icon" :is="icons[item.meta.icon]" />
        <span>{{ item.meta?.title }}</span>
      </a>
    </a-breadcrumb-item>

    <a-breadcrumb-item v-if="last.meta">
      <component
        v-if="last.meta.icon"
        :is="icons[last.meta.icon]"
        class="metaIcon"
      />
      <span class="issCentont">
        <img
          src="../../assets/images/dashboard/today.png"
          alt=""
          v-if="last.meta.title === '今天'"
          class="breadImg"
        />
        <img
          src="@/assets/images/menu/contact.png"
          alt=""
          v-if="last.meta.title === '联系人'"
          class="breadImg"
        />
        <!--        <img-->
        <!--            src="@/assets/images/dashboard/daiban.png"-->
        <!--            alt=""-->
        <!--            v-if="last.meta.title === '待办'"-->
        <!--            class="breadImg"-->
        <!--        />-->
        <!--        <img-->
        <!--            src="@/assets/images/dashboard/time.png"-->
        <!--            alt=""-->
        <!--            v-if="last.meta.title === '时间轴'"-->
        <!--            class="breadImg"-->
        <!--        />-->

        {{ last.meta.title }}
      </span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
import {
  getCurrentInstance,
  reactive,
  toRaw,
  toRefs,
  watch,
  computed,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Breadcrumb } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'Breadcrumb',
  components: { ABreadcrumb: Breadcrumb, ABreadcrumbItem: Breadcrumb.Item },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let routes = router.getRoutes();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    let groupItemRes = null;
    const menus = store.state.account.menus;
    const customMenus = computed(() => store.state.account.customMenus);
    const bcs = reactive({ first: {}, breadcrumbs: [], last: {} });
    const analysis = list => {
      let datas = [];
      if (route.path.startsWith('/marketing/activity')) {
        if (list.length === 6 || list.length === 4) {
          const fullPath = route.path.split('/').slice(0, 4).join('/');
          datas = [{ ...routes.find(i => i.path === fullPath), fullPath }];
        }
      } else {
        datas = [
          {
            ...routes.find(i => i.path === list.join('/')),
            fullPath: route.path.split('/').slice(0, list.length).join('/'),
          },
        ];
        const next = list.slice(0, list.length - 1);
        next.length === 2 || (datas = datas.concat(analysis(next)));
      }
      return datas;
    };
    const getBreadcrumb = () => {
      let temp = [];
      const pathArr = route.matched[1].path.split('/');
      if (pathArr.length === 2) {
        bcs.first = {};
      } else {
        bcs.first = menus
          .concat(customMenus.value)
          .find(i => i.path === `/${pathArr[1]}`);
        pathArr.length === 3 ||
          (temp = analysis(pathArr.slice(0, pathArr.length - 1)).reverse());
      }
      bcs.breadcrumbs = temp;
      bcs.last = { ...route };
    };
    const findObjectsByPath = (arr, targetPath) => {
      arr.forEach(item => {
        if (item.path == targetPath) {
          groupItemRes = item;
          return item;
        }
        if (item.children && item.children.length > 0) {
          return findObjectsByPath(item.children, targetPath);
        }
      });
    };
    watch(
      [
        () => route.path,
        () => store.state.common.breadcrumbs,
        () => route.fullPath,
      ],
      ([path, breadcrumbs], [prevPath, prevBreadcrumbs]) => {
        if (path == '/group/groupItem') {
          findObjectsByPath(customMenus.value, route.fullPath);
          route.meta.title = groupItemRes.meta.title;
          routes = routes.map(i => {
            if (i.name === 'groupItem') {
              return {
                ...i,
                meta: { title: groupItemRes.meta.title },
              };
            }
            return {
              ...i,
            };
          });
        }
        if (
          (!['login'].includes(route.name) &&
            !route.name.startsWith('microApp-') &&
            path !== prevPath) ||
          route.fullPath.indexOf('groupItem?id=') != -1
        ) {
          getBreadcrumb();
        } else if (
          breadcrumbs &&
          breadcrumbs.length !== prevBreadcrumbs?.length
        ) {
          // 处理主应用菜单
          const temp = breadcrumbs.concat();
          const pathArr = route.matched[1].path.split('/');
          if (pathArr.length === 3) {
            bcs.first = {};
          } else {
            bcs.first = menus
              .concat(customMenus.value)
              .find(i => i.path === `/${pathArr[1]}`);
          }
          if (route.path.startsWith('/marketing/activity')) {
            const pArr = route.path.split('/');
            temp.unshift(
              {
                ...routes.find(i => i.path === pArr.slice(0, 4).join('/')),
                fullPath: pArr.slice(0, 4).join('/'),
              },
              {
                ...routes.find(i => i.path === pathArr.slice(0, 7).join('/')),
                fullPath: pArr.slice(0, 7).join('/'),
              }
            );
          }
          bcs.last = temp.pop();
          bcs.breadcrumbs = temp;
          document.title = `${bcs.last.meta.title} - ${process.env.VUE_APP_PROJECT_TITLE}`;
        }
      }
    );
    route.name.startsWith('microApp-') || getBreadcrumb();
    return {
      icons,
      ...toRefs(bcs),
      handleClick: item => {
        if (item.iframe) {
          proxy.$bus.emit('ISSIFRAMENAV', toRaw(item));
        } else {
          router.push(item.fullPath);
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-breadcrumb {
  margin-right: 24px;
  padding: 0 16px;
  height: 40px;
  line-height: 56px;
  font-size: 12px;
  //border-radius: 20px;
  //background-color: #fff;
}
.breadImg {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}
// .issCentont {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.metaIcon {
  color: #b2b2b2;
}
</style>
