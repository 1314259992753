<template>
  <div class="mt-40">
    <contact-grid
      ref="gridRef"
      allow-selection
      :showHeader="true"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :items="items"
      :url-params="{ ...parmas, groupId: $route.query.id }"
      :btn-operation="btnOperation"
      :scroll="{ x: 800, y: gridHeight }"
      @fnGrid="handleGrid"
    >
      <!--      <template #operation="{ record }">-->
      <!--        <operation :options="options" :record="record" />-->
      <!--      </template>-->
      <template v-slot:avatar="{ record }">
        <a-avatar
          v-if="record.avatar"
          :src="setAvatar(record.avatar)"
          class="headerUrl"
        />
        <div v-else class="headerText">
          <div v-if="record.name">
            {{ record.name[0] }}
          </div>
        </div>
      </template>
      <template v-slot:name="{ record }">
        <div class="cursor-p">
          {{ record.name }}
        </div>
      </template>

    </contact-grid>
  </div>
</template>

<script>
import contactGrid from '@/components/contactGrid';
import { useRoute, } from 'vue-router';
import { useStore } from 'vuex';
import { reactive, ref, toRefs, watch } from 'vue';
import * as icons from '@ant-design/icons-vue';
import { setAvatar } from '@/utils';
// import { message, Modal } from 'ant-design-vue';

import contactAll from '@/api/contactAll';
import { Avatar, message } from 'ant-design-vue';

export default {
  components: {
    contactGrid,
    AAvatar: Avatar,

  },
  props: {
    init: Object,
  },
  setup(props, context) {
    const route = useRoute();
    const { id } = route.query;
    const store = useStore();
    const userId = store.state.account.user.id;
    const gridRef = ref();
    // const router = useRouter();
    const state = reactive({
      parentId: '',
      visibleFrequency: false,
      activeItemFrequency: [],
      visibleAddGroup: false,
      activeItemAddGroup: [],
      visibleEdit: false,
      activeItemEdit: [],
      visibleContactsPage: false,
      activeItemAdd: {},
      visibleAddPage: false,
      visiblePluse: false,
      activeItemPluse: [],
      visibleGroup: false,
      frequenctList: [],
      activeItemGroup: {},
      // isDrag: false,
      visible: false,
      initValue: {},
      groupMessage: {},
      parmas: {
        groupId: id,
        userId: userId,
        conditionDto: {
          sortMap: {
            field: '',
            sort: 'asc',
          },
          fieldList: [
            'company',
            'position',
            'describe',
            'frequency',
            'last_interaction_time',
            'socialize_url',
          ],
          conditionMap: [
            {
              field: '',
              relation: '',
              value: '',
            },
          ],
        },
      },
      name: '',
    });

    watch(
        () => props.init,
      (value) =>{
          if (value.activeKey === '1') {
          // 给父组件传值选中的数据和状态
          //   console.log('联系人勾选数据和状态', gridRef?.value.getSelectedRowsKeysAll())
            if (!gridRef?.value.getSelectedRowsKeysAll().selectAll && gridRef?.value.getSelectedRowsKeysAll().id.length === 0) {
              return  message.info('请选择联系人')
            }

            let parmas = {
              ...gridRef?.value.getSelectedRowsKeysAll(),
              spinning: true
            }
            context.emit('getAllContacts',  parmas);
            gridRef.value?.refreshGrid();

          }

        }
    );


    return {
      ...toRefs(state),
      icons,
      setAvatar,
      gridRef,
      id,
      items: [{ key: 'name', label: '搜索姓名、公司、职位', type: 'input' }],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        {
          dataIndex: 'name',
          title: '姓名',
          width: 160,
          ellipsis: true,
          slots: { customRender: 'name' },
        },
        {
          dataIndex: 'company',
          title: '公司',
          width: 300,
          ellipsis: true,
          slots: { customRender: 'company' },
        },
        { dataIndex: 'position',  title: '职位', width: 300, ellipsis: true },
        // {
        //   key: 'id',
        //   title: '操作',
        //   fixed: 'right',
        //   width: 60,
        //   slots: { customRender: 'operation' },
        // },
      ],
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sort',
          label: '排序',
          permission: 'resources:sort',
          slots: { customRender: 'sort' },
          fnClick: () => {
            console.log('排序排序', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sift',
          label: '筛选',
          permission: 'resources:sift',
          fnClick: () => {
            console.log('筛选');
          },
        },
      ],
      options: [
        // {
        //   type: 'detail',
        //   label: '详情',
        //   icon: 'EyeTwoTone',
        //   permission: 'distribution:detail',
        //   fnClick: record => {
        //     router.push(`/contacts/${record.id}`);
        //   },
        // },
      ],
      // 关系组分页
      url: contactAll.pageUrl,
      gridHeight: document.body.clientHeight - 470,

      handleGrid: parmas => {
        // grid传来的值
        let { value, type } = parmas;
        // console.log('grid传来的值', value, type);
        if (type === 'sort') {
          if (value === 'last_interaction_time' || value === 'create_time') {
            state.parmas.conditionDto.sortMap.sort = 'desc';
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.sortMap.sort = 'asc';
            gridRef.value.refreshGrid();
          }
          state.parmas.conditionDto.sortMap.field = value;
          gridRef.value.refreshGrid();
        } else if (type === 'field') {
          state.parmas.conditionDto.fieldList = value;
          gridRef.value.refreshGrid();
        } else if (type === 'sift') {
          // console.log('valuefgfd', value);
          if (value) {
            state.parmas.conditionDto.conditionMap = value;
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.conditionMap = [];
            gridRef.value.refreshGrid();
          }
        }

        // gridRef.value.refreshGrid();
      },
      getSearch: parmas => {
        console.log('parmas筛选', parmas )
        state.name = parmas.name

      },
      handleLine(link) {
        window.open(link, '_blank');
      },


    }

  },
};
</script>

<style scoped>

</style>