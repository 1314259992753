<template>
  <div>
    <div
      class="modalClass"
      ref="container"

    >
      <a-modal
        :visible="visible"
        :title="'批量添加联系人'"
        :mask-closable="false"
        :width="1200"
        :footer='false'
        centered
        @cancel="handleCancel"
        forceRender
        :destroyOnClose="true"
        :getContainer="() => container"
        class="issModal"
      >
        <div class='allContact'
        >
          <a-row >
            <a-col :span="15">
              <div
                class="tabsClass"
                :class="{pointerNone: step === 1 || step === 2 || step === 3}"
              >
                <a-tabs v-model:activeKey="activeKey">
                  <a-tab-pane key="1" tab="全部联系人">
                    <div  class="issGrid">
                      <iss-contacts
                        :init='allContactsObj'
                        @getAllContacts='allContactsVal'
                      />
                    </div>

                  </a-tab-pane>

                  <a-tab-pane key="2" tab="关系组" force-render>
                    <div  class="issGrid">
                      <all-groud
                        :init='allGroudObj'
                        @getAllGroud='allGroudVal'
                      />
                    </div>

                  </a-tab-pane>
                  <a-tab-pane key="3" tab="人脉圈" force-render>
                    <div  class="issGrid">
                      <all-circle
                        :init='allCircleObj'
                        @getAllCircle='allCircleVal'
                      />
                    </div>
                  </a-tab-pane>
                </a-tabs>

                <div class='isStep'>
                  <!--  调取指引状态接口，未完成则给step 赋值为1        -->

                </div>

              </div>
            </a-col>

            <a-col :span="1">
              <!--       确认选择     -->
              <div
                class='tubiao'
               @click='getTableList' :spinning='spinning'
                :class="{pointerNone: step === 1 || step === 2 || step === 3}"
              >
                <RightOutlined class='RightOutlined'/>
              </div>
            </a-col>

            <a-col :span="8">
              <div
                class='allRight'
                :class="{pointerNone: step === 1 || step === 2 || step === 3}"
              >
                <div class='selectContacts'>
                  <div class='one'>已选择联系人</div>
                  <div class='oneRight'>
                    <div class='two cursor-p' @click='emptyTableList'>清空选择</div>
                    <a-button type="primary" class='three' @click="handleFinish" :loading="loading">完成</a-button>
                  </div>

                </div>
                <!--           需要有个空状态     -->
                <div class='selectList'>
                  <a-spin :spinning="spinning">
                    <div v-if='form.tableList.length > 0'>
<!--                      <div-->
<!--                        v-for="item in form.tableList"-->
<!--                        :key="item"-->
<!--                      >-->
<!--                        <div class="isContent">-->
<!--                          <div class="header">-->
<!--                            &lt;!&ndash;                   @click="handleGoToContact(item.id)"    &ndash;&gt;-->
<!--                            <a-avatar-->
<!--                              v-if="item.avatar"-->
<!--                              :src="setAvatar(item.avatar)"-->
<!--                              :size="24"-->
<!--                              class="avatarClass"-->
<!--                            />-->
<!--                            <span-->
<!--                              v-else-->
<!--                              class="headerText avatarClass"-->
<!--                            >-->
<!--                          <span v-if="item.name">-->
<!--                            {{ item.name[0] }}-->
<!--                          </span>-->
<!--                       </span>-->
<!--                          </div>-->

<!--                          &lt;!&ndash;     {{ item.name }}  &ndash;&gt;-->
<!--                          <div class="nameText maxwidth">{{item.name}}</div>-->
<!--                          <div class="nameTextT maxwidth">{{item.company}}</div>-->
<!--                          <div class="nameTextTh maxwidth">{{item.position}}</div>-->

<!--                          <div class="nameTextF cursor-p" @click='deleteItem(item)'>-->
<!--                            <MinusCircleOutlined class='minusCircleOutlined'/>-->
<!--                          </div>-->

<!--                        </div>-->
<!--                      </div>-->

                      <!--              虚拟列表   -->
                      <div ref="boxRef" class="vl-box" :style="{ height: height + 'px' }">
                        <div :style="{ height: boxHeight + 'px' }">
                          <div
                            class="vl-box-item"
                            v-for="(item, index) in form.tableList"
                            :key="item.id"
                            :style="{
                            height: rowHeight + 'px',
                            lineHeight: rowHeight + 'px',
                            top: (index + offsetIndex) * rowHeight + 'px'

                        }"
                          >
<!--                           {{item}}-->
                            <div class="isContent">
                              <div class="header">
                                <!--                   @click="handleGoToContact(item.id)"    -->
                                <a-avatar
                                  v-if="item.avatar"
                                  :src="setAvatar(item.avatar)"
                                  :size="24"
                                  class="avatarClass"
                                />
                                <span
                                  v-else
                                  class="headerText avatarClass"
                                >
                                <span v-if="item.name">
                                  {{ item.name[0] }}
                                </span>
                             </span>
                              </div>

                              <!--     {{ item.name }}  -->
                              <div class="nameText maxwidth">{{item.name}}</div>
                              <div class="nameTextT maxwidth">{{item.company}}</div>
                              <div class="nameTextTh maxwidth">{{item.position}}</div>

                              <div class="nameTextF cursor-p" @click='deleteItem(item)'>
                                <MinusCircleOutlined class='minusCircleOutlined'/>
                              </div>

                            </div>


                          </div>
                        </div>
                      </div>

                    </div>
                    <div v-else class="isContent"
                         :class='{borderNone: form.tableList.length === 0}'

                    >
                      <a-empty
                        :image="simpleImage"
                        description="暂无联系人，请从左侧列表选择添加联系人"
                        class='empty'
                      />
                    </div>
                  </a-spin>
                </div>



              </div>
            </a-col>
          </a-row>

          <div class='isStep1'>
            <setp1-contacts  class='step1' v-if="step === 1"   @stepChangeContacts="stepChange"/>
          </div>
          <setp2-contacts  class='step2' v-if="step === 2" @stepChangeContacts="stepChange"/>
          <setp3-contacts  class='step3' v-if="step === 3" @stepChangeContacts="stepChange"/>


        </div>



      </a-modal>
  </div>




  </div>



</template>
<script>
import {
  computed,
  onMounted,
  reactive, ref,
  toRefs, watch
} from 'vue';
import {
  message,
  Tabs,
  Row, Col,
  Empty,
  Spin,
  Avatar

} from 'ant-design-vue';
import allGroud from '@/components/allGroud';
import allCircle from '@/components/allCircle';
import issContacts from '@/components/contacts';
import { useStore } from 'vuex';
import {RightOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import { mergeAndUnique, setAvatar } from '@/utils';
import setp1Contacts from '@/components/setp1Contacts';
import setp2Contacts from '@/components/setp2Contacts';
import setp3Contacts from '@/components/setp3Contacts';
import dashboardApi from '@/api/dashboard';
import { uniqueByProperty } from '@/utils';


export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    allGroud,
    allCircle,
    issContacts,
    ARow: Row,
    ACol: Col,
    RightOutlined,
    AEmpty: Empty,
    MinusCircleOutlined,
    ASpin: Spin,
    setp1Contacts,
    setp2Contacts,
    setp3Contacts,
    AAvatar: Avatar,

  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const step = ref(null);
    const container = ref();
    const boxRef = ref();
    const store = useStore();
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    const state = reactive({
      activeKey: '1',
      selectList: [],
      allContactsObj: {},
      allGroudObj: {},
      allCircleObj: {},
      spinning: false,

      height: 800,
      rowHeight: 40,
      offset: 0,
      offsetIndex: 0,

      xunieList: [],

    });
    const form = reactive({
       tableList: [],
      selectAll: null,
    });

    watch(
      () => props.visible,
      visible => {
        // console.log('批量添加全部联系人', visible)
      //   step.value = 1
        // 查询是否完成新手筛选状态 1-完成 0-未完成(默认)
        if (visible) {
          dashboardApi
            .queryGuideStatus('', '')
            .then(res => {
              if (res) {
                if (res.status === 0) {
                  step.value = 1
                } else {
                  step.value = null
                }
              }
            });
        }

      },
      { deep: true }
    );

    const handleCancel = () => {
      // console.log('批量添加联系人取消')
      context.emit('fnOk', false);
    };
    const isOffsetData = () => {
      let count = Math.ceil(state.height / state.rowHeight);
      let index = Math.floor(state.offset / state.rowHeight);
      state.offsetIndex = index;
      return form.tableList.slice(index, count + index);

    };

    const stepChange = (type, temp) => {
      if (type === 'next') {
        step.value = temp + 1;
      } else if (type === 'prev') {
        step.value = temp - 1;
      } else if (type === 'done') {

        // todo 更新用户新手筛选状态 true-更新成功 false-更新失败
        dashboardApi
          .updateGuideStatus('', {
            id: store.state.account.user.id,
            queryGuide: 1,
          })
          .then(res => {
            if (res) {
              // console.log('完成指引', res)
              message.success('恭喜你完成指引');
              step.value = null;
            }
          })

      }
    };

    // 处理批量添加联系人数据去重
    const getData = (res) =>{
      if (form.tableList.length === 0) {
        form.tableList = res;
      } else {
        // 两个数据根据id 去重
        form.tableList = mergeAndUnique(res, form.tableList, 'id')

      }
      message.success('添加成功')
      // console.log('form.tableList', form.tableList, )
      // 根据id进行数组对象去重
      // form.tableList = uniqueByProperty(form.tableList, 'id')

      state.spinning = false

    };
    const boxHeight = computed(() =>  form.tableList.length * state.rowHeight);
    const offsetData = computed(()=> isOffsetData);

    // gridRef.valu
    onMounted(()=>{
      console.log('boxRef.value', boxRef?.value)
      // boxRef.value.addEventListener('scroll', () => {
      //   state.offset = boxRef.value.scrollTop;
      //   console.log(state.offset )
      // },false)

    })



    return {
      container,
      boxRef,
      boxHeight,
      offsetData,
      form,
      step,
      ...toRefs(state),
      simpleImage,
      handleCancel,
      setAvatar,
      stepChange,
      // 联系人
      allContactsVal(value) {
        // console.log('联系人组件选择的值',  value, form.tableList)
        state.spinning = value.spinning
        form.selectAll = value.selectAll
        let parmas = {
          userId: store.state.account.user.id,
          name: '',
          conditionDto: {},
          contactIdList: value.id,
          selectAll: value.selectAll,
        }

        dashboardApi
          .selectAllContactList('', parmas)
          .then(res => {
            if (res) {
              console.log('res联系人接口', res)
              if (res.length > 0) {

                getData(res)
              }
              // else {
              //   message.info('请选择联系人')
              // }

            }
          });

      },
      allGroudVal(value) {
        // console.log('关系组',  value)
        state.spinning = value.spinning
        let parmas = {
          userId: store.state.account.user.id,
          conditionDto: {},
          contactIdList: value.id,
          selectAll: value.selectAll,
          groupId: value.groupId,
        }

        dashboardApi
          .selectAllGroupContactList('', parmas)
          .then(res => {
            if (res) {
              console.log('res关系组接口', res)
              if (res.length > 0) {
                getData(res)
              }
              // else {
              //   message.info('请选择联系人')
              // }

            }
          });

      },

      allCircleVal(value) {
        // console.log('人脉圈组件选择的值',  value)
        state.spinning = value.spinning
        let parmas = {
          userId: store.state.account.user.id,
          conditionDto: {},
          contactIdList: value.id,
          selectAll: value.selectAll,
          connectionsId: value.connectionsId,
        }
        dashboardApi
          .selectAllConnectionContacts('', parmas)
          .then(res => {
            if (res) {
              // console.log('res人脉圈接口', res)
              if (res.length > 0) {
                // state.spinning = value.spinning
                getData(res)
              }
              // else {
              //   message.info('请选择联系人')
              // }
            }
          });


      },
      // 获取表格数据
      getTableList () {
        // state.spinning = true
        if (state.activeKey === '1') {
          state.allContactsObj = { activeKey: state.activeKey }
        }
        if (state.activeKey === '2') {
          state.allGroudObj = { activeKey: state.activeKey }

        }
        if (state.activeKey === '3') {
          state.allCircleObj = { activeKey: state.activeKey }
        }

      },
      emptyTableList() {
        form.tableList = []
      },
      deleteItem(item) {
       let list = []
        list = form.tableList.filter((i)=> i.id !== item.id)
        // console.log('list', list)
        message.success('删除成功')
        form.tableList = list

      },

      handleFinish() {
        if (form.tableList.length === 0) return message.error('请先选择联系人')

      //  拿到已经选好的列表数据 form.tableList
      //   传值给父组件
        let toFather = {
          visible: false,
          list: form.tableList,
        }
        // console.log('toFather', toFather)
        context.emit('fnOk', toFather);
        form.tableList = []


      },


    };
  },
};
</script>
<style lang="less" scoped>

.modalClass /deep/ .ant-modal-body {
  height: auto !important;
  //height: 526px;
  //overflow: auto;
  padding: 24px !important;
}

// 禁止点击 /deep/ .ant-modal-body
.pointerNone {
  pointer-events: none !important;
}

.modalClass /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none;
}
.modalClass /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}
.phoneCalssRight /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}

.phoneCalssRight /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none !important;
}

.tabsClass /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 9px;
  border-bottom: none;
}
.tabsClass {
  box-shadow: 0px 3px 10px 0px #FFF5EB;
  width: 718px;
  height: 610px;
}
.issGrid {
  margin-top: 16px;
  margin-bottom: 24px;
  min-height: 498px;
}
.tubiao {
  margin: 290px auto;
  background: #FF7B00;
  border-radius: 3px;
  border: 1px solid #FF7B00;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  .RightOutlined {

   color: #FFFFFF;
  }
}

.allRight {
  box-shadow: 0px 3px 10px 0px #FFF5EB;
  padding: 12px;
  height: 610px;

  .selectContacts {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .oneRight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .two {
        color: #FF7B00;
      }
      .three {
        margin-left: 18px;
      }


    }



  }
  .selectList {
    display: grid;
    // max-height: calc(100vh - 315px);
    max-height: 544px;
    overflow-y: auto;
    margin: 10px 0px;

    .isContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      border: 0.5px solid #eeeeee;
      //padding: 2px 0px;
      border-right: none;
      border-left: none;
      border-top: none;
      .header {
        display: flex;
        justify-content: center;
        align-items: center;

        .avatarClass {
          margin: 0px 8px;
        }

        .headerText {
          display: inline-block;
          line-height: 24px;
          //text-align: center;
        }

      }
      .maxwidth {
        width: 90px;
        margin-right: 8px;
        white-space: nowrap; /*超出的空白区域不换行*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*文本超出显示省略号*/
      }
      .nameText {

      }
      .nameTextT {

      }
      .nameTextTh {

      }
      .nameTextF {

        .minusCircleOutlined {
          color: #FA5151;
        }

      }
      .empty {
        margin: 200px auto;

      }

    }
    .borderNone {
      border: none;

    }


  }

}
.modalClass {
  position: relative;

}
.allContact {
   position: relative;

}
.step1 {
  //position: absolute;
  //top: -165px;
  //left: -159px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
}
.isStep {
  position: absolute;
  top: -200px;
  left: -159px;
}
.isStep1 {
  position: absolute;
  top: 88px;
  left: 122px;
}
.step2 {
  position: absolute;
  top: 340px;
  right: 232px;
}
.step3 {
  position: absolute;
  top: 100px;
  right: -200px;
}
// 虚拟列表
.vl-box {
  //border: 2px solid #0094ff;
  //overflow: auto;
}
.vl-box > div {
  overflow: hidden;
  position: relative;
}
.vl-box > div > div {
  position: absolute;
  width: 100%;
}
.vl-box-item {
  //box-sizing: border-box;
  //border-bottom: 1px dotted red;

  border-right: none;
  border-left: none;
}

//.isContent {
//  display: flex;
//  justify-content: flex-start;
//  align-items: center;
//
//  border: 0.5px solid #eeeeee;
//  padding: 2px 0px;
//  //padding: 8px 0px;
//  border-right: none;
//  border-left: none;
//  .header {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//
//    .avatarClass {
//      margin: 0px 8px;
//    }
//
//    .headerText {
//      display: inline-block;
//      //text-align: center;
//    }
//
//  }
//  .maxwidth {
//    width: 90px;
//    margin-right: 8px;
//    white-space: nowrap; /*超出的空白区域不换行*/
//    overflow: hidden; /*超出隐藏*/
//    text-overflow: ellipsis; /*文本超出显示省略号*/
//  }
//  .nameText {
//
//  }
//  .nameTextT {
//
//  }
//  .nameTextTh {
//
//  }
//  .nameTextF {
//
//    .minusCircleOutlined {
//      color: #FA5151;
//    }
//
//  }
//  .empty {
//    margin: 200px auto;
//
//  }
//
//}

</style>
