<template>
<!--  <teleport to="body">-->
<!--    <div class="mask" @click.stop></div>-->
    <!--
      :style="{
            width: w + 'px',
            height: h + 'px',
            top: top + 'px',
            left: left + 'px',
          }"
    -->
    <div
      class="mask-step2"

    >
      <div class="step2-content1">
        <div class="fs-16 fw-7">确认选择</div>
        <div class="fs-14 mt-10">
          选中联系人后，点击按钮所勾选的联系人进入已选择联系人列表。
        </div>
        <div class="flex mt-10">
          <span>2 / 3</span>
          <div class="flex">
            <div
              class="l-btn-primary mr-10"
              style="
                font-size: 12px;
                padding: 5px 10px;
                line-height: 1.5;
                background: #fff;
                color: #FF7B00;
                border: 1px solid #FF7B00;
              "
              @click="stepChange('prev')"
            >
              上一步
            </div>
            <div
              class="l-btn-primary"
              style="font-size: 12px; padding: 5px 10px; line-height: 1.5"
              @click="stepChange('next')"
            >
              下一步
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="step2-content2">-->
      <!--        <img src="@/assets/images/step/step21.jpg" style="width: 100%" alt="" />-->
      <!--      </div>-->
    </div>
<!--  </teleport>-->
</template>

<script>
import { reactive, onMounted, nextTick, toRefs } from 'vue';
export default {
  name: 'step2',
  emits: ['stepChange'],
  setup(props, context) {
    const maskRect = reactive({
      w: 0,
      h: 0,
      top: 0,
      left: 0,
    });
    onMounted(() => {
      nextTick(() => {
        const step2 = document.getElementById('step2');
        const rect = step2.getBoundingClientRect();
        maskRect.w = rect.width + 10;
        maskRect.h = rect.height + 10;
        maskRect.top = rect.y - 5;
        maskRect.left = rect.x - 5;
      });
    });
    const stepChange = type => {
      context.emit('stepChangeContacts', type, 2);
    };
    return {
      ...toRefs(maskRect),
      stepChange,
    };
  },
};
</script>

<style lang="less" scoped>
.mask-step2 {
  //position: fixed;
  //width: 170px;
  //height: 190px;
  //top: 370px;
  //left: 467px;
  z-index: 99999;
  //box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 9999px;
  & > div {
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
  }
}
.step2-content1 {
  width: 352px;
  //position: absolute;
  //top: 91px;
  //left: 268px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  //&::before {
  //  content: '';
  //  position: absolute;
  //  bottom: -20px;
  //  left: 50%;
  //  z-index: 1000;
  //  margin-left: -10px; /* 将箭头置于对话框的中央 */
  //  border-width: 10px;
  //  border-style: solid;
  //  border-color: #ffffff transparent transparent transparent; /* 设置箭头颜色 */
  //}
}
.step2-content2 {
  padding: 0px !important;
  width: 350px;
  position: absolute;
  top: 45px;
  left: -125px;
  background-color: transparent !important;
}
</style>
