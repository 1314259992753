<template>
  <div class="iss-header">
    <div>
      <iss-breadcrumb />
    </div>
    <div class="header-right">
      <iss-search class="right-item" />
      <div class="right-item"><iss-add /></div>
      <div class="right-item"><iss-user /></div>
    </div>
  </div>
</template>

<script>
import IssSearch from './search';
import IssUser from './user';
import IssBreadcrumb from '../breadcrumb';
import IssAdd from './add';

export default {
  components: {
    IssSearch,
    IssUser,
    IssAdd,
    IssBreadcrumb,
  },
};
</script>

<style lang="less" scoped>
.iss-header {
  display: flex;
  justify-content: space-between;
  .header-right {
    display: flex;
    font-size: 20px;
    color: rgba(49, 61, 95, 0.7);
    line-height: 56px;
    padding-right: 16px;
    .right-item {
      padding: 0 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #eceef1;
      }
    }
  }
}
</style>
