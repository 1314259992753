<template>
  <div class="iss-menu-wrapper" ref="">
    <div class="menu-logo" @click="goHome">
      <img :src="logoUrl" alt="首页" style="width: 100%" />
    </div>
    <div class="contentClass">
      <a-directory-tree
        class="mt-16 directoryTree"
        multiple
        v-model:expandedKeys="expandedKeys"
        v-model:selectedKeys="selectedKeys"
        @select="handleClick"
      >
        <template v-for="item in menuList" :key="item.path">
          <template v-if="item.children">
            <a-tree-node :key="item.path" :item="item">
              <template #icon v-if="item.meta.icon != 'custom'">
                <component :is="icons[item.meta.icon]" />
              </template>
              <template
                #icon="{ selected }"
                v-else-if="item.meta.icon == 'custom'"
              >
                <template v-if="item.menuKey == 'today'">
                  <img
                    src="@/assets/images/menu/todayS.png"
                    width="14"
                    class="pb-3"
                    v-if="selected"
                  />
                  <img
                    class="pb-3"
                    src="@/assets/images/menu/today.png"
                    width="14"
                    v-else
                  />
                </template>
                <template v-if="item.menuKey == 'contacts'">
                  <img
                    src="@/assets/images/menu/contactS.png"
                    width="14"
                    class="pb-3"
                    v-if="selected"
                  />
                  <img
                    class="pb-3"
                    src="@/assets/images/menu/contact.png"
                    width="14"
                    v-else
                  />
                </template>
                <template v-if="item.menuKey == 'contactsCircle'">
                  <img
                    src="@/assets/images/menu/circleS.png"
                    width="14"
                    class="pb-3"
                    v-if="selected"
                  />
                  <img
                    class="pb-3"
                    src="@/assets/images/menu/circle.png"
                    width="14"
                    v-else
                  />
                </template>
                <template v-if="item.menuKey == 'group'">
                  <img
                    src="@/assets/images/menu/circleS.png"
                    width="14"
                    class="pb-3"
                    v-if="selected"
                  />
                  <img
                    class="pb-3"
                    src="@/assets/images/menu/circle.png"
                    width="14"
                    v-else
                  />
                </template>
              </template>
              <template #title>
                <div class="show1Row" style="padding-top: 6px">
                  {{ item.meta.title }}
                </div>
              </template>
              <a-tree-node
                v-for="child in item.children"
                :key="child.path"
                :item="child"
                is-leaf
              >
                <template #icon>
                  <component :is="icons[child.meta.icon]" />
                </template>
                <template #title>
                  <div class="show1Row" style="padding-top: 6px">
                    {{ child.meta.title }}
                  </div>
                </template>
              </a-tree-node>
            </a-tree-node>
          </template>
          <template v-else>
            <a-tree-node :key="item.path" :item="item">
              <template #icon v-if="item.meta.icon != 'custom'">
                <component :is="icons[item.meta.icon]" />
              </template>
              <template
                #icon="{ selected }"
                v-else-if="item.meta.icon == 'custom'"
              >
                <template v-if="item.menuKey == 'contactsCircle'">
                  <img
                    src="@/assets/images/menu/groupS.png"
                    width="14"
                    class="pb-3"
                    v-if="selected"
                  />
                  <img
                    class="pb-3"
                    src="@/assets/images/menu/group.png"
                    width="14"
                    v-else
                  />
                </template>
              </template>
              <template #title>
                <div class="show1Row" style="padding-top: 6px">
                  {{ item.meta.title }}
                </div>
              </template>
            </a-tree-node>
          </template>
        </template>
        <template v-for="item in customMenus" :key="item.path">
          <template v-if="item.children">
            <a-tree-node
              :key="item.path"
              :item="item"
              :class="item.menuKey === 'group' ? 'step33' : null"
            >
              <template #icon v-if="item.meta.icon != 'custom'">
                <component :is="icons[item.meta.icon]" />
              </template>
              <!-- v-else-if="item.meta.icon == 'custom'" -->
              <template #icon="{ selected }" v-else>
                <template v-if="item.menuKey == 'groupMenu'">
                  <img
                    src="@/assets/images/menu/circleS.png"
                    width="14"
                    class="pb-3"
                    v-if="selected"
                  />
                  <img
                    class="pb-3"
                    src="@/assets/images/menu/circle.png"
                    width="14"
                    v-else
                  />
                </template>
              </template>
              <template #title>
                <!-- v-if="item.name === 'group'" -->
                <div class="group flex">
                  <div class="show1Row">
                    {{ item.meta.title }}
                  </div>
                  <component
                    class="group-icon"
                    :is="icons['PlusOutlined']"
                    @click.stop="addGroupItem"
                  />
                </div>
                <!-- <div v-else class="show1Row" style="padding-top: 6px">
                  {{ item.meta.title }}
                </div> -->
              </template>
              <a-tree-node
                v-for="child in item.children"
                :key="child.path"
                :item="child"
              >
                <template #icon>
                  <component
                    :is="
                      icons[
                        child.meta.icon ? child.meta.icon : 'FolderOpenOutlined'
                      ]
                    "
                  />
                </template>
                <template #title>
                  <div v-if="child.type === 'groupItem'" class="groupItem">
                    <div class="show1Row">
                      {{ child.meta.title }}
                    </div>
                    <!--              增加父关系组 数量    -->
                    <div class="showMunO">
                      {{ child.meta.size ? child.meta.size : 0 }}
                    </div>
                    <a-dropdown placement="bottomCenter" :trigger="['click']">
                      <div @click.stop>
                        <component
                          class="groupItem-icon"
                          :is="icons['EllipsisOutlined']"
                        />
                      </div>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item @click="clickMenu(child, 'add')">
                            <template #icon>
                              <component :is="icons['PlusOutlined']" />
                            </template>
                            添加子关系组
                          </a-menu-item>
                          <a-menu-item @click="clickMenu(child, 'edit')">
                            <template #icon>
                              <component :is="icons['EditOutlined']" />
                            </template>
                            编辑关系组
                          </a-menu-item>

                          <a-menu-item>
                            <template #icon>
                              <component :is="icons['DeleteOutlined']" />
                            </template>
                            <a-popconfirm
                              placement="right"
                              ok-text="确认"
                              :overlayStyle="{ 'z-index': 1070 }"
                              cancel-text="取消"
                              @confirm="clickMenu(child, 'delete')"
                            >
                              <template #title>
                                <p>
                                  您删除关系组后，该关系组下的联系人或子关系组将被一起删除！
                                </p>
                              </template>
                              <div @click.stop class="menu-del"></div>
                            </a-popconfirm>

                            <span class="pl-4">删除关系组</span>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </div>
                  <div v-else class="show1Row">{{ child.meta.title }}</div>
                </template>
                <a-tree-node
                  v-for="childItem in child.children"
                  :key="childItem.path"
                  :item="childItem"
                  is-leaf
                >
                  <template #icon>
                    <component :is="icons[childItem.meta.icon]" />
                  </template>
                  <template #title>
                    <div
                      v-if="childItem.type === 'groupItem'"
                      class="groupItem"
                    >
                      <div class="show1Row">{{ childItem.meta.title }}</div>
                      <!--             增加子关系组 数量    -->
                      <div class="showMun">
                        {{ childItem.meta.size ? childItem.meta.size : 0 }}
                      </div>
                      <a-dropdown placement="bottomCenter" :trigger="['click']">
                        <div @click.stop>
                          <component
                            class="groupItem-icon"
                            :is="icons['EllipsisOutlined']"
                          />
                        </div>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item
                              @click.stop="clickMenu(childItem, 'edit')"
                            >
                              <template #icon>
                                <component :is="icons['EditOutlined']" />
                              </template>
                              编辑子关系组
                            </a-menu-item>
                            <a-menu-item>
                              <template #icon>
                                <component :is="icons['DeleteOutlined']" />
                              </template>
                              <a-popconfirm
                                placement="right"
                                :overlayStyle="{ 'z-index': 1070 }"
                                ok-text="确认"
                                cancel-text="取消"
                                @confirm="clickMenu(childItem, 'delete')"
                              >
                                <template #title>
                                  <p>
                                    您删除关系组后，该关系组下的联系人或子关系组将被一起删除！
                                  </p>
                                </template>
                                <div @click.stop class="menu-del"></div>
                              </a-popconfirm>

                              <span class="pl-4">删除子关系组</span>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </div>
                    <div v-else class="show1Row">
                      {{ childItem.meta.title }}
                    </div>
                  </template>
                </a-tree-node>
              </a-tree-node>
            </a-tree-node>
          </template>
          <template v-else>
            <a-tree-node :key="item.path" :item="item">
              <template #icon>
                <component :is="icons[item.meta.icon]" />
              </template>
              <template #title>
                <div class="show1Row">{{ item.meta.title }}</div>
              </template>
            </a-tree-node>
          </template>
        </template>
      </a-directory-tree>
      <!--   todo  -->
      <div @click="onloadApp" class="cursor-p downLoad">
        <img
          src="@/assets/images/menu/download.png"
          style="width: 14px; margin-right: 8px"
          alt=""
        />
        下载人脉宝盒APP
      </div>
    </div>
  </div>
  <a-modal
    v-model:visible="visibleAdd"
    :destroyOnClose="true"
    :title="
      formState.id
        ? formState.parentId == '0'
          ? '编辑关系组'
          : '编辑子关系组'
        : formState.parentId == '0'
        ? '新增关系组'
        : '新增子关系组'
    "
    @ok="handleOk"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      layout="vertical"
      style="width: 100%"
    >
      <a-form-item
        :label="formState.parentId == '0' ? '关系组名称' : '子关系组名称'"
        name="groupName"
      >
        <a-input
          v-model:value="formState.groupName"
          :placeholder="
            formState.parentId == '0'
              ? '请输入关系组名称'
              : '请输入子关系组名称'
          "
        >
          <template #addonBefore>
            <component
              @click="visible = true"
              class="addonBefore"
              :is="icons[`${formState.icon}`]"
            />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="描述" name="description">
        <a-input
          v-model:value="formState.description"
          placeholder="请输入描述"
        />
      </a-form-item>
      <a-form-item
        label="父级"
        name="parentName"
        v-if="!formState.id && formState.parentId != '0'"
      >
        <a-input v-model:value="formState.parentName" :disabled="true" />
      </a-form-item>
    </a-form>
  </a-modal>
  <groupAdd
    v-model:visible="visibleAddNew"
    :init="activeItemAddEdit"
    :jump="true"
    @fnOk="handleOkNew"
  />
  <icon-modal
    v-model:visible="visible"
    :init-value="formState.icon"
    @fnOk="value => (formState.icon = value)"
  />
  <open-app
    v-model:visible="visibleApp"
    :init="initValue"
    @fnOk="handleOpenApp"
  />
</template>

<script>
import { computed, ref, reactive, toRefs, watchEffect, watch, onMounted, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import openApp from '@/views/dashboard/components/openApp';
import groupAdd from '@/views/contacts/contactAall/addTiesGroup';

import {
  Tree,
  Dropdown,
  Menu,
  Form,
  message,
  Typography,
  Popconfirm,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IconModal from '@/components/iconModal';
import menuApi from '@/api/menu.js';
import todayApi from '@/api/today';
import dashboardApi from '@/api/dashboard';
import personalApi from '@/api/personal';

export default {
  components: {
    ADirectoryTree: Tree.DirectoryTree,
    ATreeNode: Tree.TreeNode,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AForm: Form,
    ATypographyParagraph: Typography.Paragraph,
    AFormItem: Form.Item,
    IconModal,
    APopconfirm: Popconfirm,
    openApp,
    groupAdd,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const userId = store.state.account.user.id;
    const userData = store.state.account.user;
    console.log('userData', userData);
    const menuContent = ref();
    const formState = reactive({
      groupName: '',
      description: '',
      parentId: '0',
      parentName: '',
      icon: 'FolderOpenOutlined',
      id: '',
    });
    const states = reactive({
      visible: false,
      visibleAdd: false,
      visibleAddNew: false,
      activeItemAddEdit: {},
      visibleApp: false,
      isNovice: null,
      openType: '',
      rules: {
        groupName: [
          {
            required: true,
            message: '请输入关系组名称',
            trigger: 'change',
          },
          {
            max: 16,
            message: '名称过长',
            trigger: 'change',
          },
        ],
        description: [
          {
            message: '请输入描述',
            trigger: 'change',
          },
          {
            max: 32,
            message: '名称过长',
            trigger: 'change',
          },
        ],
      },
    });
    const pathArr = route.path.split('/');
    const formRef = ref(null);
    const expandedKeys = ref([`/${pathArr[1]}`]);
    const selectedKeys = ref([
      pathArr.length > 4 ? pathArr.slice(0, 4).join('/') : route.path,
    ]);
    const keyValue = ref(null);
    const menuList = computed(() => store.state.account.menus);
    const customMenus = computed(() => store.state.account.customMenus);
    const findObjectsByPath = (arr, targetPath) => {
      arr.forEach(item => {
        if (item.path == targetPath) {
          return (keyValue.value = item);
        }
        if (item.children && item.children.length > 0) {
          return findObjectsByPath(item.children, targetPath);
        }
      });
    };

    const getImportSituation = () => {
      todayApi
        .getContactImportSituation('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          // console.log('res22', res);
          // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成

          // if (parmas === '/today') {

          if (res === 'Novice') {
            return router.push('/welcome');
          } else if (res === 'incomplete') {
            return router.push({
              path: '/dashboard',
              query: {
                importStatus: 0,
              },
            });
          } else {
            return router.push('/today');
          }

          // } else {
          //   return message.error('请先通过app完成联系人同步。')
          // }
        });
    };

    const addGroupItem = () => {
      // 新手时、导入未完成时、不开启云同步\不可点击
      todayApi
        .getContactImportSituation('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          // console.log('res22', res);
          // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
          // 本地存储也不可以点击左侧菜单栏

          // 查询云同步
          dashboardApi.getIsWhetherCloud({}).then(data => {
            if (data || res === 'untreated' || res === 'complete') {
              if (!data) {
                return message.error('请先通过app完成联系人同步。');
              } else {
                states.visibleAddNew = true;
                states.activeItemAddEdit = {
                  type: 'add',
                  show: false,
                  groupMessage: {
                    parentId: '0',
                    id: '0',
                  },
                };
              }

              // states.openType = 'add';
              // states.rules.groupName[0].message = '请输入关系组名称';
            } else if (!data || res === 'Novice' || res === 'incomplete') {
              return message.error('请先通过app完成联系人同步。');
            }
          });
        });
    };
    const clickMenu = (cItem, key) => {
      states.openType = key;
      if (states.openType === 'add') {
        formState.parentId = cItem.id || '';
        formState.parentName =
          customMenus.value[0].children?.filter(i => i.id == cItem.id)[0]?.meta
            .title || '';
        formState.id = null;
        states.visibleAdd = true;
      } else if (states.openType === 'edit') {
        formState.id = cItem.id;
        formState.parentId = cItem.parentId;
        formState.groupName = cItem.meta.title || '';
        formState.description = cItem.description || '';
        formState.icon = cItem.meta.icon || '';
        states.visibleAdd = true;
      } else if (states.openType === 'delete') {
        menuApi[states.openType]({
          userId: store.state.account.user.id,
          id: cItem.id,
        })
          .then(res => {
            if (res) {
              message.success('删除成功');
              selectedKeys.value = ['/group'];
              router.push({
                path: '/group',
              });
              getList();
            }
          })
          .catch(() => {
            message.error('删除失败');
          });
      }
      states.rules.groupName[0].message =
        formState.parentId == '0' ? '请输入关系组名称' : '请输入子关系组名称';
    };
    const handleOkNew = value => {
      states.visibleAddNew = value;
    };
    const handleOk = value => {
      formRef.value.validateFields().then(() => {
        const obj = JSON.parse(JSON.stringify(formState));
        delete obj.parentName;
        if (states.openType === 'add') {
          delete obj.id;
        }
        menuApi[states.openType]({
          userId: store.state.account.user.id,
          ...obj,
        })
          .then(res => {
            if (res) {
              message.success(
                states.openType === 'add' ? '添加成功' : '编辑成功'
              );
              getList();
              states.visibleAdd = false;
              if (states.openType === 'add') {
                // 跳转到相应的关系组详情页面
                let path = '/group/groupItem?id=' + res.id;
                router.push(path);
              }
            }
          })
          .catch(() => {
            // message.error(states.openType === 'add' ? '添加失败' : '编辑失败');
          });
      });
    };
    const getList = () => {
      menuApi
        .getList({
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            res = res.map(i => {
              const obj = {
                id: i.id,
                parentId: i.parentId,
                path: '/group/groupItem?id=' + i.id,
                name: 'groupItem',
                menuKey: 'groupItem',
                type: 'groupItem',
                component: 'group/groupItem',
                accessWay: 'builtin',
                description: i.description,
                meta: {
                  title: i.groupName,
                  icon: i.icon || 'FolderOpenOutlined',
                  breadcrumb: true,
                  size: i.size,
                },
                hidden: false,
                alwaysShow: false,
              };
              if (i.children?.length) {
                i.children = i.children.map(item => {
                  return {
                    id: item.id,
                    parentId: item.parentId,
                    path: '/group/groupItem?id=' + item.id,
                    name: 'groupItem',
                    menuKey: 'groupItem',
                    type: 'groupItem',
                    component: 'group/groupItem',
                    accessWay: 'builtin',
                    description: item.description,
                    meta: {
                      title: item.groupName,
                      icon: i.icon || 'FolderOpenOutlined',
                      breadcrumb: true,
                      size: item.size,
                    },
                    hidden: false,
                    alwaysShow: false,
                  };
                });
                return {
                  ...obj,
                  children: i.children,
                };
              } else {
                return obj;
              }
            });
            let target = [
              {
                path: '/groupMenu',
                // path: '',
                name: 'groupMenu',
                menuKey: 'groupMenu',
                component: 'Layout',
                accessWay: 'builtin',
                description: '关系组关系组',
                meta: {
                  title: '关系组',
                  icon: 'custom',
                  breadcrumb: true,
                  select: '@/assets/images/menu/groupS.png',
                  unSelect: '@/assets/images/menu/group.png',
                },
                hidden: false,
                alwaysShow: false,
                children: [],
              },
            ];
            target[0].children = res;
            store.commit('account/setCustomMenus', target);
          }
        });
    };

    watchEffect(() => {
      const addClose = !states.visibleAdd;
      if (addClose) {
        Object.assign(formState, {
          groupName: '',
          description: '',
          parentId: '0',
          parentName: '',
          icon: 'FolderOpenOutlined',
          id: '',
        });
      }
    });
    const goHome = () => {
      getContactImportSituation('goHome');
      // if (states.isNovice) {
      //   return message.error('请先通过app完成联系人同步。')
      // } else {
      //   selectedKeys.value = ['/today'];
      //   router.push({
      //     path: '/today',
      //   });
      // }
    };
    getList();
    watch(
      () => route.fullPath,
      val => {
        if (route.name === 'groupItem') {
          selectedKeys.value = [val];
        } else {
          selectedKeys.value = [route.path];
        }
      }
    );
    const getContactImportSituation = parmas => {
      // console.log('parmas', parmas);
      todayApi
        .getContactImportSituation('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
          // 本地存储也不可以点击左侧菜单栏
          // console.log('res22', res);

          // 查询云同步
          dashboardApi.getIsWhetherCloud({}).then(data => {
            if (data || res === 'untreated' || res === 'complete') {
              if (!data) {
                return message.error('请先通过app完成联系人同步。');
              } else {
                // console.log('云同步', res);
                selectedKeys.value = ['/today'];
                router.push({
                  path: '/today',
                });
              }
            } else if (!data || res === 'Novice' || res === 'incomplete') {
              return message.error('请先通过app完成联系人同步。');

            }
          });
        });
    };


    // const getUserAvatarAndName = () =>{
    //   todayApi
    //     .getUserAvatarAndName('', {}).then((res)=>{
    //       if (res) {
    //         console.log('res信息', res)
    //       //  用户信息存起来
    //       }
    //   })
    // };

    // 获取用户最新个人信息
    const getUserInfo = () => {
      personalApi.getItemById('', userId).then(data => {
        if (data) {
          // 存储用户信息
          store.commit('account/setUserHeader', data);
        }

      });
    };

    onMounted(()=>{
      // getUserAvatarAndName()
      getUserInfo()

      proxy.$bus.on('shuaxinMenu', (event)=>{
        if (event) {
          getList();
        }

      });

      }
    );



    return {
      logoUrl: process.env.VUE_APP_PROJECT_LOGO,
      menuContent,
      icons,
      menuList,
      goHome,
      expandedKeys,
      selectedKeys,
      customMenus,
      formRef,
      formState,
      handleOk,
      handleOkNew,
      ...toRefs(states),
      addGroupItem,
      clickMenu,
      userData,
      onloadApp() {
        states.visibleApp = true;
      },

      handleClick(selectedKeys) {
        // 如果用户当前是新手状态，那么不支持点击左侧菜单栏、点击时提示
        todayApi
          .getContactImportSituation('', {
            userId: store.state.account.user.id,
          })
          .then(res => {
            // console.log('res22', res);

            dashboardApi.getIsWhetherCloud({}).then(data => {
              if (data || res === 'untreated' || res === 'complete') {
                console.log('data 云同步', data);
                 // 是本地存储，但是用户状态未处理或者完成
                 if (!data) {
                   return message.error('请先通过app完成联系人同步。');
                 } else {

                   if (selectedKeys[0] === '/today') {
                     getImportSituation();
                   }
                   findObjectsByPath(
                     menuList.value.concat(...customMenus.value),
                     selectedKeys[0]
                   );
                   switch (keyValue.value.accessWay) {
                     case 'link':
                       window.open(keyValue.value.link);
                       break;
                     case 'iframe':
                       router.push(
                         `${selectedKeys[0]}?path=${encodeURIComponent(
                           keyValue.value.link
                         )}`
                       );
                       break;
                     default:
                       if (keyValue.value.component === 'Layout') {
                         return;
                       } else if (selectedKeys[0] === '/today') {
                         getImportSituation();
                       } else {
                         router.push(selectedKeys[0]);
                       }
                       break;
                   }

                 }

              } else if (!data || res === 'Novice' || res === 'incomplete') {
                  return message.error('请先通过app完成联系人同步。');
              }
            });

            // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
            // 本地存储也不可以点击左侧菜单栏
            // if (
            //   res === 'Novice' ||
            //   res === 'incomplete' ||
            //   userData.whetherCloud === '0'
            // ) {
            //
            // } else {
            //
            // }
          });
      },
      handleOpenApp(value) {
        states.visibleApp = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-menu-wrapper {
  padding-top: 16px;
  background-color: @layout-header-background;
  .menu-logo {
    display: block;
    margin: 0 20px 0px 20px;
    cursor: pointer;
  }
}
:deep(.ant-tree li span.ant-tree-switcher) {
  display: none;
}
:deep(
    .ant-tree.ant-tree-directory
      > li
      span.ant-tree-node-content-wrapper::before,
    .ant-tree.ant-tree-directory
      .ant-tree-child-tree
      > li
      span.ant-tree-node-content-wrapper::before
  ),
:deep(
    .ant-tree.ant-tree-directory
      > li
      span.ant-tree-node-content-wrapper.ant-tree-node-selected,
    .ant-tree.ant-tree-directory
      .ant-tree-child-tree
      > li
      span.ant-tree-node-content-wrapper.ant-tree-node-selected
  ),
:deep(.ant-tree li .ant-tree-node-content-wrapper) {
  color: #4b4e55;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
}
:deep(span.ant-tree-iconEle) {
  color: #c1c1c1;
}
:deep(
    .ant-tree.ant-tree-directory
      > li.ant-tree-treenode-selected
      > span.ant-tree-node-content-wrapper::before,

  ),
:deep(
    .ant-tree.ant-tree-directory
      .ant-tree-child-tree
      > li.ant-tree-treenode-selected
      > span.ant-tree-node-content-wrapper::before
  ) {
  background: #fff1e6;
}
:deep(.ant-tree-treenode-selected .ant-tree-node-selected .ant-tree-iconEle),
:deep(.ant-tree-treenode-selected .ant-tree-node-selected .ant-tree-title),
:deep(.ant-tree-node-selected .ant-tree-iconEle),
:deep(.ant-tree-node-selected .ant-tree-title) {
  color: @primary-color;
}
:deep(.ant-tree-treenode-selected),
:deep(.ant-tree-node-selected) {
  color: @primary-color;
  transition: all 0.3s;
}
:deep(.ant-tree-title) {
  width: 100%;
}
:deep(.ant-tree li span.ant-tree-switcher),
:deep(.ant-tree li span.ant-tree-iconEle) {
  width: auto;
  height: auto;
  padding-right: 8px;
}
:deep(.ant-dropdown) {
  &:hover {
    .groupItem-icon {
      display: block;
    }
  }
}
.group {
  padding-right: 8px;
  &:hover {
    .group-icon {
      display: block;
    }
  }
}
.group-icon {
  display: none;
  padding: 4px;
  &:hover {
    background-color: #fff8f2;
  }
}
.groupItem {
  // 子分组icon边距去掉
  //padding-right: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .groupItem-icon {
    padding: 4px;
    display: none;
    &:hover {
      background-color: #fff8f2;
    }
  }
}
.ant-tree-node-selected .groupItem-icon {
  display: block;
}
.addonBefore {
  cursor: pointer;
  color: #ff7b00;
}
.menu-del {
  position: absolute;
  width: 130px;
  height: 32px;
  transform: translate(-8%, -83%);
}
.contentClass {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  height: calc(100vh - 60px);
  //height: calc(100vh - 160px);

  overflow: auto;
}
// .directoryTree {
//   height: 698px;
// }
.downLoad {
  text-align: center;
  color: #ff7b00;
  border: 1px solid #ff8f1f;
  border-radius: 8px;
  padding: 10px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  margin-left: 16px;

  margin-top: 90px;
  margin-bottom: 106px;
}
.step33 /deep/ ul.ant-tree-child-tree.ant-tree-child-tree-open {
  padding: 0 0 0 8px;
}
.show1Row {
  // min-width: 67px;
  // max-width: 67px;
  width: 56px;
  //width: calc(100vw - 160px);
  margin-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.showMun {
  margin-right: 6px;
  min-width: 30px;
  max-width: 30px;
  text-overflow: ellipsis;
  text-align: right;
}
.showMunO {
  margin-right: 6px;
  min-width: 30px;
  max-width: 30px;
  text-overflow: ellipsis;
  text-align: right;
}
</style>
