'use strict';

import { createVNode } from 'vue';
import axios from 'axios';
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import router from '@/router';
import store from '@/store';
import errorMsg from 'ismartek-error-message';
const http = axios.create({
  baseURL: process.env.VUE_APP_API_SERVER,
});
//过期提醒
let reminderExpirationTimer = null;
const reminderExpiration = () => {
  console.log('会员已过期请充值');
  let url = window.location.href;
  let path = url.substring(url.lastIndexOf('/') + 1);
  if (path != 'personal') {
    Modal.confirm({
      title: `您的使用时长已到期，请到个人中心进行订阅或通过金币兑换使用时长。`,
      icon: createVNode(ExclamationCircleOutlined),
      closable: false,
      okText: '去订阅/兑换',
      cancelButtonProps: null,
      onOk() {
        router.push('/personal');
        Modal.destroyAll();
      },
      onCancel() {
        router.push('/personal');
        Modal.destroyAll();
      },
    });
  }
};
//  + process.env.VUE_APP_API_BASE_URL

// 添加请求拦截器
http.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    const { tenant, token } = store.state.account;
    const userId = store.state?.account?.user?.id;
    tenant && (config.headers.tenant = tenant);
    token && (config.headers.token = token);
    userId && (config.headers.userId = userId);
    config.code && (config.headers.code = config.code);
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    // console.log(response);
    const { data, headers, request } = response;
    if (request.responseType === 'blob') {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(data, 'utf-8');
        reader.onload = ({ target }) => {
          if (target.result.includes('"isError":true')) {
            const result = JSON.parse(target.result);
            // message.error(result.msg);
            if (data.code !== 2001) {
              errorMsg[result.code]
                ? message.error(errorMsg[result.code])
                : message.error(result.msg);
            }
            if (data.code === 10002) {
              console.log('异常码');
              errorMsg[result.code]
                ? message.error(errorMsg[result.code])
                : message.error(result.msg);
            }
            [2001, 2002, 2003, 2004, 2005].includes(result.code) &&
              router.push('/login');
            // result.code === 2001 && router.push('/login');
            return reject(response);
          }
          return resolve({
            name: decodeURI(
              headers['content-disposition'].split(';')[1].split('fileName=')[1]
            ),
            data,
          });
        };
      });
    } else {
      if (data.isError) {
        if ([401].includes(data.code)) {
          router.push('/login');
          message.error({
            content: '会话超时，请重新登陆！',
            key: 'error',
          });
        } else {
          if (data.code === 10012) {
            if (reminderExpirationTimer !== null) {
              clearTimeout(reminderExpirationTimer);
            }
            reminderExpirationTimer = setTimeout(() => {
              reminderExpiration();
            }, 1000);
          } else {
            message.error({
              content: data.msg + '',
              key: 'error',
            });
          }
        }
        return null;
      }
      return Object.prototype.hasOwnProperty.call(data, 'data')
        ? data.data
        : data;
    }
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default http;
