<template>
  <a-modal
    :visible="visible"
    title="将联系人添加到人脉圈"
    :mask-closable="false"
    :width="800"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    forceRender
    :destroyOnClose="true"
    zIndex="1001"
  >
    <div class="issGrid">
      <contact-grid
        ref="gridRef"
        allow-selection
        :showHeader="true"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :items="items"
        :url-params="parmas"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
        @fnGrid="handleGrid"
      >
        <template v-slot:avatar="{ record }">
          <a-avatar
            v-if="record.avatar"
            :src="setAvatar(record.avatar)"
            @click="handleGoToContact(record)"
            class="headerUrl"
          />
          <div v-else @click="handleGoToContact(record)" class="headerText">
            <div v-if="record.name">
              {{ record.name[0] }}
            </div>
          </div>
        </template>

        <template #frequency="{ record }">
          <div v-for="item in frequenctList" :key="item.id">
            <div v-if="item.itemCode === record.frequency">
              <img
                src="@/assets/images/box/woshou.png"
                alt=""
                class="frequencyImage"
              />
              {{ item.itemNameEn }}
            </div>
          </div>
        </template>

        <template #infoList="{ record }">
          <div class="recordContent">
            <div v-if="record.douYinUrl">
              <img
                src="@/assets/images/box/dou.png"
                alt=""
                class="dou-img"
                @click="handleLine(record.douYinUrl)"
              />
            </div>
            <div v-if="record.weiboUrl">
              <img
                src="@/assets/images/box/wei.png"
                alt=""
                class="wei-img"
                @click="handleLine(record.weiboUrl)"
              />
            </div>
            <div v-if="record.zhiHuUrl">
              <img
                src="@/assets/images/box/zhi.png"
                alt=""
                class="zhi-img"
                @click="handleLine(record.zhiHuUrl)"
              />
            </div>

            <div
              v-if="
                record.infoList.every(
                  item => item.email === '' || item.email === null
                )
              "
            >
              <img
                src="@/assets/images/box/youhui.png"
                alt=""
                class="youhui-img marginRight10"
              />
            </div>
            <a-dropdown placement="bottomCenter" v-else>
              <img
                src="@/assets/images/box/you.png"
                alt=""
                class="activeColor email"
              />

              <template #overlay>
                <!--              cricleList  v-if="item.email === null || item.email === ''" -->
                <a-menu
                  :class="{
                    dropdownCalss: record.infoList.some(
                      item => item.email === null || item.email === ''
                    ),
                  }"
                >
                  <a-menu-item
                    v-for="item in record.infoList"
                    :key="item.id"
                    class="menuClass"
                    :class="{
                      nonePhone: item.email === '' || item.email === null,
                    }"
                  >
                    {{ item.email }}
                    <div
                      class="menuText activeColor"
                      v-if="item.email"
                      @click="getEmail(item)"
                    >
                      复制
                    </div>
                  </a-menu-item>

                  <div
                    v-if="
                      record.infoList.length === 0 ||
                      record.infoList.some(
                        item => item.email !== null || item.email === ''
                      )
                    "
                    @click="getAllEmail(record.infoList)"
                    class="allValue activeColor"
                  >
                    复制全部
                  </div>
                </a-menu>
              </template>
            </a-dropdown>

            <div
              v-if="
                record.infoList.every(
                  item => item.phone === '' || item.phone === null
                )
              "
            >
              <img
                src="@/assets/images/box/dianhui.png"
                alt=""
                class="dianhui-img marginRight10"
              />
            </div>
            <!--          电话   -->
            <a-dropdown placement="bottomCenter" v-else>
              <!--                <component :is="icons['PhoneOutlined']" class="activeColor"/>-->
              <img
                src="@/assets/images/box/dian.png"
                alt=""
                class="activeColor cursor-p"
              />

              <template #overlay>
                <!--              cricleList   -->
                <a-menu
                  @click="handleClickByMenu"
                  :class="{
                    dropdownCalss: record.infoList.some(
                      item => item.phone === null || item.phone === ''
                    ),
                  }"
                >
                  <a-menu-item
                    v-for="item in record.infoList"
                    :key="item.id"
                    class="menuClass"
                    :class="{
                      nonePhone: item.phone === '' || item.phone === null,
                    }"
                  >
                    {{ item.phone }}
                    <div
                      class="menuText activeColor"
                      v-if="item.phone"
                      @click="getValue(item)"
                    >
                      复制
                    </div>
                  </a-menu-item>
                  <div
                    @click="getAllValue(record.infoList)"
                    class="allValue activeColor"
                    v-if="
                      record.infoList.length === 0 ||
                      record.infoList.some(
                        item => item.phone !== null || item.phone === ''
                      )
                    "
                  >
                    复制全部
                  </div>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
        <template v-slot:lastInteractionTime="{ record }">
          <div>
            {{ handleTime(record) }}
          </div>
        </template>
      </contact-grid>
    </div>

    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>

  <!--  </div>-->
</template>
<script>
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import contactGrid from '@/components/contactGrid';
import { useStore } from 'vuex';
import { setAvatar } from '@/utils';
import contactAll from '@/api/contactAll';
import contactsCircleApi from '@/api/contactsCircle';
import { message, Dropdown, Menu, Avatar } from 'ant-design-vue';
import { copyText } from '@/utils';
// import * as icons from "@ant-design/icons-vue";

export default {
  components: {
    contactGrid,
    // Icon: icons,
    ADropdown: Dropdown,
    AMenu: Menu,
    AAvatar: Avatar,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const gridRef = ref();
    const store = useStore();
    const userId = store.state.account.user.id;
    const form = reactive({
      connectionsId: '',
      userId: store.state.account.user.id,
      contactId: [],
    });

    const state = reactive({
      showMessage: false,
      loading: false,
      groupsIdList: [],
      frequenctList: [],
      parmas: {
        userId: userId,
        connectionsId: '',
        conditionDto: {
          sortMap: {
            field: '',
            sort: 'asc',
          },
          fieldList: [
            'company',
            'position',
            'describe',
            'frequency',
            'last_interaction_time',
            'socialize_url',
          ],
          conditionMap: [
            {
              field: '',
              relation: '',
              value: '',
            },
          ],
        },
      },
      name: '',
    });

    watch(
      () => props.init,
      init => {
        form.connectionsId = init;
        state.parmas.connectionsId = init;
      }
    );

    watch(
      () => props.visible,
      visible => {
        if (visible) {
          console.log(props.init);
          form.connectionsId = props.init;
          state.parmas.connectionsId = props.init;
        }
      }
    );

    const handleCancel = () => {
      // 关闭弹窗清空筛选条件
      state.parmas.conditionDto.conditionMap = [];
      context.emit('update:visible', false);
    };

    contactAll.getTree('', { code: 'FREQUENCY' }).then(res => {
      if (res) {
        Object.assign(state.frequenctList, res);
      }
    });

    return {
      ...toRefs(state),
      // icons,
      setAvatar,
      form,
      gridRef,
      items: [{ key: 'name', label: '搜索姓名、公司、职位', type: 'input' }],
      columns: [
        {
          dataIndex: 'avatar',
          title: '头像',
          width: 60,
          ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        { dataIndex: 'name', title: '姓名', width: 120, ellipsis: true },
        {
          dataIndex: 'company',
          title: '公司',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'company' },
        },
        { dataIndex: 'position', title: '职位', width: 120, ellipsis: true },
        { dataIndex: 'describe', title: '备注', width: 120, ellipsis: true },

        {
          dataIndex: 'frequency',
          title: '联系频次',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'frequency' },
        },
        // {
        //   dataIndex: 'last_interaction_time',
        //   title: '',
        //   width: 100,
        //   ellipsis: true,
        //   slots: { customRender: 'last_interaction_time' },
        // },
        //   号码
        {
          dataIndex: 'infoList ',
          title: '联系方式',
          width: 160,
          ellipsis: true,
          slots: { customRender: 'infoList' },
        },
        //   最后互动时间 last_interaction_time
        {
          dataIndex: 'lastInteractionTime',
          title: '最后互动时间',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'lastInteractionTime' },
        },
      ],
      btnOperation: [
        {
          type: 'selectAll',
          label: '跨页全选',
          permission: 'resources:selectAll',
          fnClick: () => {
            // console.log('全选', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sort',
          label: '排序',
          permission: 'resources:sort',
          slots: { customRender: 'sort' },
          fnClick: () => {
            // console.log('排序排序', gridRef.value.getSelectedRowsKeys());
          },
        },
        {
          type: 'sift',
          label: '筛选',
          permission: 'resources:sift',
          fnClick: () => {
            // console.log('筛选');
          },
        },
      ],
      url: contactAll.addPageAll,
      gridHeight: document.body.clientHeight - 386,
      handleGrid: parmas => {
        // grid传来的值
        let { value, type } = parmas;
        // console.log('grid传来的值', value, type);
        if (type === 'sort') {
          if (value === 'last_interaction_time' || value === 'create_time') {
            state.parmas.conditionDto.sortMap.sort = 'desc';
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.sortMap.sort = 'asc';
            gridRef.value.refreshGrid();
          }

          state.parmas.conditionDto.sortMap.field = value;
          gridRef.value.refreshGrid();
        } else if (type === 'field') {
          state.parmas.conditionDto.fieldList = value;
          gridRef.value.refreshGrid();
        } else if (type === 'sift') {
          // console.log('valuefgfd', value);
          if (value) {
            state.parmas.conditionDto.conditionMap = value;
            gridRef.value.refreshGrid();
          } else {
            state.parmas.conditionDto.conditionMap = [];
            gridRef.value.refreshGrid();
          }
        } else if (type === 'extend') {
          if (value.contactId.length === 0) {
            return message.error('请先选择联系人');
          } else {
            contactAll.deleteContactIds('', value).then(res => {
              if (res) {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              }
            });
          }
        }

        // gridRef.value.refreshGrid();
      },
      handleCancel,
      handleLine(link) {
        window.open(link, '_blank');
      },
      getAllEmail(record) {
        // 过滤包含非空email字段的对象
        const emails = record
          .filter(
            item =>
              item.email !== null &&
              item.email !== undefined &&
              item.email !== ''
          )
          .map(item => item.email);
        const result = emails.length > 0 ? emails.join(',') : '';
        copyText(result);
        message.success('复制成功！');
      },

      getValue(item) {
        copyText(item.phone);
        message.success('复制成功！');
      },
      getAllValue(record) {
        const emails = record
          .filter(
            item =>
              item.phone !== null &&
              item.phone !== undefined &&
              item.phone !== ''
          )
          .map(item => item.phone);
        const result = emails.length > 0 ? emails.join(',') : '';
        copyText(result);
        message.success('复制成功！');
      },

      // 分类列表保存方法调用接口的方法和事件
      handleOk: () => {
        state.loading = true;

        form.contactId = gridRef.value.getSelectedRowsKeysAll().id;
        console.log('form.contactId', form.contactId)

        if (gridRef.value.getSelectedRowsKeysAll().selectAll) {
          let total = gridRef.value.isTotal()
          let idLeng = gridRef.value.getSelectedRowsKeysAll().id.length
          if ((total - idLeng) === 0 ) {
            state.loading = false;
            return message.error('请选择联系人');
          }

        } else {
          if (gridRef.value.getSelectedRowsKeysAll().id.length === 0 ) {
            state.loading = false;
            return message.error('请选择联系人');
          }
        }



        let parmas = {
          userId: userId,
          contactId: gridRef.value.getSelectedRowsKeysAll().id,
          selectAll: gridRef.value.getSelectedRowsKeysAll().selectAll,
          pageDTO: {
            conditionDto: state.parmas.conditionDto,
            name: state.name
          },
          connectionsId: form.connectionsId,
        }

        contactsCircleApi.saveConnections('', parmas).then(res => {
          if (res) {
            state.loading = false;
            message.success('操作成功');
            context.emit('fnOk', {
              visible: false,
              ids: form.contactId,
            });
          }
        }).catch(()=>{
          state.loading = false;

        });
      },
      handleTime(record) {
        if (record.lastInteractionTime) {
          let [datePart] = record.lastInteractionTime?.split(' ');
          return datePart?.replace(/-/g, '/');
        } else {
          return '';
        }
      },
      getSearch: parmas => {
        state.name = parmas.name
      },

    };
  },
};
</script>
<style lang="less" scoped>
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.email {
  margin-right: 12px;
  cursor: pointer;
}
.menuClass {
  display: flex;
  justify-content: space-between;
  margin: 12px;

  .menuText {
    margin-left: 24px;
    cursor: pointer;
  }
}
.allValue {
  text-align: center;
  cursor: pointer;
  margin-bottom: 8px;
  border-top: 0.5px solid #eeeeee;
}
.recordContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.wei-img,
.zhi-img,
.dou-img {
  margin-right: 12px;
  cursor: pointer;
}
.dropdownCalss {
  min-width: 203px;
  min-height: 78px;
}

.email {
  margin-right: 12px;
  cursor: pointer;
}

.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.nonePhone {
  display: none;
}
// .issGrid {
//   height: 550px;
// }
.issGrid /deep/ .ant-table-placeholder {
  border-bottom: none;
}
.frequencyImage {
  width: 18px;
  height: 18px;
}
</style>
