<template>
  <div class="contain">
    <div class="img-list" v-if="fileList.length > 0">
      <a-image-preview-group
        :preview="{
          imageGroupVisiable,
          onVisibleChange: vis => (imageGroupVisiable = vis),
        }"
      >
        <template v-for="(imgItem, imgIndex) in fileList" :key="imgIndex">
          <div
            class="img-item"
            v-if="
              getArr(imgItem.name).toLowerCase() == 'jpg' ||
              getArr(imgItem.name).toLowerCase() == 'jpeg' ||
              getArr(imgItem.name).toLowerCase() == 'png' ||
              getArr(imgItem.name).toLowerCase() == 'svg' ||
              getArr(imgItem.name).toLowerCase() == 'gif' &&
              getArr(imgItem.name).toLowerCase() != 'webp'
            "
          >
            <div class="loading" v-if="imgItem?.percent < 100">
              <LoadingOutlined />
              <span>{{ imgItem.percent.toFixed(1) + '%' }}</span>
            </div>
            <img
              class="delete-icon"
              src="@/assets/images/timeline/delete-icon.png"
              @click="deleteFile(imgIndex, imgItem.size)"
            />
            <a-image
              :width="184"
              :height="104"
              :src="imgItem.thumbUrl ? imgItem.thumbUrl : imgItem.url"
              @click="imageGroupVisiable = true"
            />
          </div>
        </template>
      </a-image-preview-group>
    </div>
    <div class="file-list" v-if="fileList.length > 0">
      <template v-for="(fileItem, fileIndex) in fileList" :key="fileIndex">
        <div
          class="file-item"
          :class="getArr(fileItem.name).toLowerCase()"
          v-if="
            getArr(fileItem.name).toLowerCase() != 'jpg' &&
            getArr(fileItem.name).toLowerCase() != 'jpeg' &&
            getArr(fileItem.name).toLowerCase() != 'png' &&
            getArr(fileItem.name).toLowerCase() != 'svg' &&
            getArr(fileItem.name).toLowerCase() != 'gif' &&
            getArr(fileItem.name).toLowerCase() != 'webp'

          "
        >
          <img
            class="file-icon"
            src="@/assets/images/timeline/zip-icon.png"
            alt=""
            v-if="getArr(fileItem.name).toLowerCase() == 'zip'"
          />
          <img
            class="file-icon"
            src="@/assets/images/timeline/doc-icon.png"
            alt=""
            v-else-if="
              getArr(fileItem.name).toLowerCase() == 'doc' ||
              getArr(fileItem.name).toLowerCase() == 'docx'
            "
          />
          <img
            class="file-icon"
            src="@/assets/images/timeline/mp3-icon.png"
            alt=""
            v-else-if="getArr(fileItem.name).toLowerCase() == 'mp3'"
          />
          <img
            class="file-icon"
            src="@/assets/images/timeline/mp4-icon.png"
            alt=""
            v-else-if="getArr(fileItem.name).toLowerCase() == 'mp4'"
          />
          <img
            class="file-icon"
            src="@/assets/images/timeline/pdf-icon.png"
            alt=""
            v-else-if="getArr(fileItem.name).toLowerCase() == 'pdf'"
          />
          <img
            class="file-icon"
            src="@/assets/images/timeline/ppt-icon.png"
            alt=""
            v-else-if="
              getArr(fileItem.name).toLowerCase() == 'ppt' ||
              getArr(fileItem.name).toLowerCase() == 'pptx'
            "
          />
          <img
            class="file-icon"
            src="@/assets/images/timeline/xlsx-icon.png"
            alt=""
            v-else-if="
              getArr(fileItem.name).toLowerCase() == 'xls' ||
              getArr(fileItem.name).toLowerCase() == 'xlsx'
            "
          />
          <img
            class="file-icon"
            src="@/assets/images/timeline/file-icon.png"
            alt=""
            v-else
          />
          <div class="file-message">
            <span class="file-title">{{ fileItem.name }}</span>
            <span class="file-size">{{
              (fileItem.size / 1024 / 1024).toFixed(2) + 'MB'
            }}</span>
          </div>
          <div class="loading" v-if="fileItem?.percent < 100">
            <LoadingOutlined />
            <span>{{ fileItem.percent.toFixed(1) + '%' }}</span>
          </div>
          <img
            class="delete-icon"
            src="@/assets/images/timeline/delete-icon.png"
            @click="deleteFile(fileIndex, fileItem.size)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';
import { Image as AImage } from 'ant-design-vue';
import { LoadingOutlined } from '@ant-design/icons-vue';

const AImagePreviewGroup = AImage.PreviewGroup;

const emits = defineEmits(['fileListChange']);

const props = defineProps({
  fileList: Object,
});

const imageGroupVisiable = ref(false);

const getArr = str => {
  console.log('str4646456', str)
  let arr = str.split('.').pop();
  return arr;
};

const deleteFile = (index, size) => {
  let fileList = props.fileList;
  fileList.splice(index, 1);
  emits('fileListChange', {
    fileList: fileList,
    index: index,
    size: size,
  });
};

// watch(
//   () => props.fileList,
//   (newVal, oldVal) => {
//     console.log(newVal);
//   }
// );
</script>

<style lang="less" scoped>
.contain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.img-list {
  width: 100%;
  margin-top: 13px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  .img-item {
    //width: 184px;
    height: 104px;
    border-radius: 4px;
    // overflow: hidden;
    position: relative;

    /deep/ .ant-image {
      border-radius: 4px;
      overflow: hidden;
    }

    .delete-icon {
      width: 16px;
      height: 16px;
      object-fit: cover;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      z-index: 3;
      cursor: pointer;
    }

    .loading {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      color: #fff;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      background: rgba(#333333, 0.5);
      border-radius: 8px;
      z-index: 3;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &:hover {
      .menu-btn {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }

    .menu-btn {
      width: 24px;
      height: 24px;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
    }

    ::v-deep(.ant-image-img) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.file-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .file-item {
    width: 98%;
    margin-right: auto;
    padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    background: #eaefff;
    position: relative;

    .delete-icon {
      width: 16px;
      height: 16px;
      object-fit: cover;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 3;
      transform: translate(calc(100% + 10px), -50%);
      cursor: pointer;
    }

    .loading {
      padding: 4px 13px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      color: #fff;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      background: rgba(#333333, 0.5);
      border-radius: 8px;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &.zip,
    &.mp3 {
      background: #faedff;
    }

    &.xlsx {
      background: #e7fff5;
    }

    &.ppt,
    &.pdf {
      background: #ffefdd;
    }

    &.doc,
    &.mp4 {
      background: #eaefff;
    }

    .file-icon {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }

    .file-message {
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .file-title {
        color: #333333;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }

      .file-size {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }

    .menu-btn {
      width: 24px;
      height: 24px;
      margin-left: auto;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.3s;
      cursor: pointer;
    }

    &:hover {
      .menu-btn {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
}
</style>
