<template>
  <a-modal
    :visible="visible"
    :title="'新增人脉圈'"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      layout="vertical"
      style="width: 100%"
    >
      <a-form-item label="人脉圈名称" name="connectionsName">
        <a-input
          v-model:value="formState.connectionsName"
          placeholder="请输入人脉圈名称"
        >
          <template #addonBefore>
            <component
              @click="visibleIcon = true"
              class="addonBefore activeColor"
              :is="icons[`${formState.icon}`]"
            />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item label="描述" name="description">
        <a-input
          v-model:value="formState.description"
          placeholder="请输入描述"
        />
      </a-form-item>
      <a-form-item label="人脉圈分类" name="parentId">
        <a-select
          placeholder="请选择人脉圈分类"
          v-model:value="formState.parentId"
        >
          <a-select-option
            v-for="item in circleList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.connectionsName }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <template v-slot:footer>
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleOk" :loading="loading"
        >确认</a-button
      >
    </template>
  </a-modal>

  <icon-modal
    v-model:visible="visibleIcon"
    :init-value="formState.icon"
    @fnOk="value => (formState.icon = value)"
  />
</template>

<script>
import {
  Tree,
  Dropdown,
  Menu,
  Form,
  message,
  Select,
  Typography,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IconModal from '@/components/iconModal';
import { onMounted, reactive, ref, toRefs } from 'vue';
import contactsCircleApi from '@/api/contactsCircle';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    ADirectoryTree: Tree.DirectoryTree,
    ATreeNode: Tree.TreeNode,
    ADropdown: Dropdown,
    AMenu: Menu,
    ASelect: Select,
    ASelectOption: Select.Option,
    AMenuItem: Menu.Item,
    AForm: Form,
    ATypographyParagraph: Typography.Paragraph,
    AFormItem: Form.Item,
    IconModal,
  },
  props: {
    visible: Boolean,
    initValue: Object,
    jump: Boolean,
  },

  setup(props, context) {
    const store = useStore();
    const formRef = ref(null);
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      visible: false,
      loading: false,
      visibleIcon: false,
      openType: '',
      circleList: [],

      rules: {
        connectionsName: [
          {
            required: true,
            message: '请输入人脉圈名称',
            trigger: 'change',
          },
          {
            max: 16,
            message: '名称过长',
            trigger: 'change',
          },
        ],
        description: [
          {
            // required: true,
            message: '请输入描述',
            trigger: 'change',
          },
          {
            max: 32,
            message: '名称过长',
            trigger: 'change',
          },
        ],
        parentId: [
          {
            required: true,
            message: '请选择人脉圈分类,如没有可前往人脉圈页面创建分类',
            trigger: 'change',
          },
        ],
      },
    });
    const formState = reactive({
      connectionsName: '',
      description: '',
      icon: 'UserOutlined',
      parentId: route.params.id,
      userId: store.state.account.user.id,
    });

    // 人脉圈分类下拉
    onMounted(() => {
      contactsCircleApi.getCircleTree('', formState.userId).then(res => {
        if (res) {
          state.circleList = res;
        }
      });
    });

    const handleOk = () => {
      formRef.value.validate().then(() => {
        state.loading = true;
        const obj = JSON.parse(JSON.stringify(formState));
        contactsCircleApi
          .addCircle('', {
            ...obj,
          })
          .then(res => {
            if (res) {
              message.success('添加成功');
              context.emit('fnOk', false);
              formRef.value.resetFields();

              // if (props.jump) {
              if (res.parentId) {
                router.push(`/contactsCircle/${res.parentId}/${res.id}`);
              } else {
                router.push(`/contactsCircle/${res.id}`);
              }
              // }
            }
            state.loading = false;
          })
          .catch(() => {
            // message.error('添加失败');
          });
      });
    };
    const handleCancel = () => {
      context.emit('fnOk', false);
    };

    return {
      formState,
      ...toRefs(state),
      handleOk,
      icons,
      formRef,
      handleCancel,
    };
  },
};
</script>

<style scoped lang="less"></style>
