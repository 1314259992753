'use strict';

import http from '@/utils/http';

export default {
  getRepeatPhone(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getEffectiveRepeatDataList',
      {
        params,
      }
    );
  },
  // 获取联系人状态
  getUserStatus(params) {
    return http.get(
      '/connections_treasure_box/sys-user/queryUserStatus/' + params.id
    );
  },
  // 更新联系人状态
  updateUserStatus(data) {
    return http.put(
      '/connections_treasure_box/sys-user/updateUserStatus',
      data
    );
  },
  getValidContact(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getValidContactCompanyOrPosition',
      {
        params,
      }
    );
  },
  getImportData(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getImportDataNum',
      {
        params,
      }
    );
  },
  //完成导入
  accomplish(params) {
    return http.get(
      '/connections_treasure_box/contact/contact/contactCompleteImport',
      {
        params,
      }
    );
  },
  // 一键清除
  clearInvalid(params) {
    return http.delete(
      '/connections_treasure_box/contact/import-contact/clickRemoval',
      {
        params,
      }
    );
  },
  // 根据ID删除联系人
  deletePeople(params) {
    return http.delete(
      '/connections_treasure_box/contact/import-contact/removeImportContact',
      {
        params,
      }
    );
  },
  // 新的根据ID删除联系人
  removeByContactId(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/removeByContactId',
      data
    );
  },

  // 查询导入状态
  getStatus(params) {
    return http.get('/connections_treasure_box/sys-user/getImportStatus', {
      params,
    });
  },
  mergeRepeatPhone(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/mergeToContact',
      data
    );
  },

  updatePeople(data) {
    return http.put('/connections_treasure_box/contact/import-contact', data);
  },
  // 查询是否和自身有重复
  putEffect1(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/getContactSelfIsRepeat',
      data
    );
  },
  // 查询是否和有效联系人有重复
  putEffect2(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/getContactEffectiveIsRepeat',
      data
    );
  },
  // 转为有效联系人
  putEffect3(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/convertToValid',
      data
    );
  },
  // 合并公司职位字段
  mergeField(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/updateContactCompanyOrPosition',
      data
    );
  },
  // 合并联系人
  mergePeople(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/mergeDuplicateContacts',
      data
    );
  },
  // 修改单个导入联系人命名识别
  updateRecognition(data) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/updateImportContactMarks',
      data
    );
  },
  // 修改多个导入联系人命名识别
  updateRecognitions( data ) {
    return http.post(
      '/connections_treasure_box/contact/import-contact/updateImportContactMarksList',
      data
    );
  },

  // 获取用户导入行为页面信息
  getBehaviorPage(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getBehaviorPage',
      {
        params,
      }
    );
  },
  // 设置用户导入行为页面信息
  setBehaviorPage(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/setBehaviorPage',
      {
        params,
      }
    );
  },
  // 查询是否云端存储
  getIsWhetherCloud(params) {
    return http.get('/connections_treasure_box/sys-user/getIsWhetherCloud', {
      params,
    });
  },
  //   设置联系人信息是否自动识别
  setIdentifyAutomatic(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/setIdentifyAutomatic',
      {
        params,
      }
    );
  },

  //   查询联系人信息是否自动识别
  getIdentifyAutomatic(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getIdentifyAutomatic',
      {
        params,
      }
    );
  },

  //  有效联系人信息识别
  queryImportContactInfoIdentify(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/queryImportContactInfoIdentify',
      {
        params,
      }
    );
  },

  // 查询识别状态
  getImportContactInfoIdentify(params) {
    return http.get(
      '/connections_treasure_box/contact/import-contact/getImportContactInfoIdentify',
      {
        params,
      }
    );
  },
  //  根据userId查询联系人信息-跨页全选
  selectAllContactList(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact/selectAllContactList',
      data,
      { code }
    );
  },

  //  根据userId和脉圈ID查询脉圈下联系人信息-跨页全选
  selectAllConnectionContacts(code, data) {
    return http.post(
      '/connections_treasure_box/contact/connections-query/selectAllConnectionContacts',
      data,
      { code }
    );
  },

  //  根据分组ID查询分组下联系人信息-跨页全选
  selectAllGroupContactList(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact/selectAllGroupContactList',
      data,
      { code }
    );
  },
//   查询是否完成新手筛选状态 1-完成 0-未完成(默认)
  queryGuideStatus(code, params) {
    return http.get(
      '/connections_treasure_box/sys-user/queryGuideStatus',
      {
        code,
        params,
      }
    );
  },

  // 更新用户新手筛选状态 true-更新成功 false-更新失败
  updateGuideStatus(code, data) {
    return http.put(
      '/connections_treasure_box/sys-user/updateGuideStatus',
      data,
      { code }
    );
  },

};
