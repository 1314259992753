<template>
<!--   to="body" -->
<!--  <template>-->
<!--    <div class="mask" @click.stop></div>-->
    <!--
      :style="{
            width: w + 'px',
            height: h + 'px',
            top: top + 'px',
            left: left + 'px',
          }"
    -->
    <div
      class="mask-step1"
    >
      <div class="step1-content1">
        <div class="fs-16 fw-7">勾选联系人</div>
        <div class="fs-14 mt-10">
          从全部联系人、关系组、人脉圈联系人列表中勾选联系人。（可切换列表进行选择）
        </div>
        <div class="flex mt-10">
          <span>1 / 3</span>
          <div
            class="l-btn-primary"
            style="font-size: 12px; padding: 5px 10px; line-height: 1.5"
            @click="stepChange('next')"
          >
            下一步
          </div>
        </div>
      </div>
      <!--      <div class="step1-content2">-->
      <!--        <img src="@/assets/images/step/step11.jpg" style="width: 100%" alt="" />-->
      <!--      </div>-->
    </div>
  </template>

<!--  <teleport >-->

<!--  </teleport>-->
<!--</template>-->

<script>
import { reactive, onMounted, nextTick, toRefs } from 'vue';
export default {
  name: 'step1',
  emits: ['stepChange'],
  setup(props, context) {
    const maskRect = reactive({
      w: 0,
      h: 0,
      top: 0,
      left: 0,
    });
    onMounted(() => {
      nextTick(() => {
        const step1 = document.getElementById('step1');
        const rect = step1.getBoundingClientRect();
        maskRect.w = rect.width + 10;
        maskRect.h = rect.height + 10;
        maskRect.top = rect.y - 5;
        maskRect.left = rect.x - 5;
      });
    });
    const stepChange = type => {
      context.emit('stepChangeContacts', type, 1);
    };
    return {
      ...toRefs(maskRect),
      stepChange,
    };
  },
};
</script>

<style lang="less" scoped>
.mask-step1 {
  //position: fixed;
  //width: 170px;
  //height: 190px;
  //top: 370px;
  //left: 467px;
  z-index: 99999;
  //box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 9999px;
  & > div {
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
  }
}
.step1-content1 {
  width: 352px;
  //position: absolute;
  //top: -165px;
  //left: -159px;

  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  &::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    z-index: 1000;
    margin-left: -10px; /* 将箭头置于对话框的中央 */
    border-width: 10px;
    border-style: solid;
    border-color: #ffffff transparent transparent transparent; /* 设置箭头颜色 */
  }
}
.step1-content2 {
  padding: 0px !important;
  width: 350px;
  position: absolute;
  top: 45px;
  left: -125px;
  background-color: transparent !important;
}
</style>
